import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Logo from 'icons/Logo';
import { useFormState } from 'utils/customHooks';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as EyeIcon } from 'assets/svg/Eye.svg';

import styles from '../login.module.scss';

const INITIAL_STATE = {
  email: '',
  password: '',
};

type TState = {
  from?: string;
};

interface Props extends RouteComponentProps<{}, any, TState> {
  requestLogin: Function;
}

const LoginForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const [error, setError] = useState<string>('');
  const [isPasswordShown, setShowPassword] = useState<boolean>(false);

  const togglePasswordShow = (): void => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleError = (err: any): void => {
    const message = err && err.message ? err.message : 'Something went wrong';
    setError(message);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const { email, password } = formState;
    const { history, location, requestLogin } = props;

    if (email.length && password.length) {
      try {
        const loggedUser = await requestLogin({
          email,
          password,
        });
        if (loggedUser) {
          const from =
            (location && location.state && location.state.from) ||
            '/business/create';

          history.push(from);
        }
      } catch (rej) {
        handleError(rej);
      }
    }
  };

  return (
    <section className="form_background">
      <div className={styles.logo_box}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <form className="form_wrapper" onSubmit={handleSubmit} autoComplete="on">
        <div className={styles.text_center}>
          <h1 className="sub_title">Welcome back!</h1>
          <div className={`${styles.row} ${styles.small_text}`}>
            Don’t have account?{' '}
            <Link to="/auth/signup" className="link">
              Sign up
            </Link>
          </div>
        </div>

        <label htmlFor="email" className={styles.row}>
          <div className="label">Your email</div>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            value={formState.email}
            onChange={handleInputChange}
            autoComplete="email"
          />
        </label>

        <label htmlFor="password" className={formStyles.row}>
          <div className="label_row">
            <div>Password</div>
          </div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              autoComplete="current-password"
              name="password"
              id="password"
              className={`input_w_icon ${
                isPasswordShown ? '' : 'secret_input'
              }`}
              value={formState.password}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className={`input_icn_btn ${
                isPasswordShown ? '' : 'open_eye_icon'
              }`}
              onClick={togglePasswordShow}
            >
              <EyeIcon className="input_icon" />
            </button>
          </div>
        </label>
        <div className={styles.forgot_password_link}>
          <Link to="/auth/forgot_password" className={styles.link}>
            Forgot password?
          </Link>
        </div>

        {error && <div className={styles.error_message}>{error}</div>}

        <div className={styles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Sign in
          </button>
        </div>

        <div
          className={`${styles.row} ${styles.small_text} ${styles.text_center}`}
        >
          By signing in you agree with <br />
          <Link to="/terms" className="link">
            Terms
          </Link>{' '}
          and{' '}
          <Link to="/privacy" className="link">
            Privacy Policy
          </Link>
          ?
        </div>
      </form>
    </section>
  );
};

export default LoginForm;
