import React, { Component } from 'react';
import { EmailCreds } from '_types';
import EmailCredsForm from './EmailCredsForm';
import styles from '../appPage.module.scss';
import EmailsTemplateList from '../EmailTemplates/EmailTemplatesListContainer';

interface State {
  credentials: EmailCreds | null;
  error: string;
}

interface Props {
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  credentials: null,
  error: '',
};

const INITIAL_CRED = {
  clientId: '',
  clientToken: '',
  iterableApiKey: '',
  iterableApiKeySandbox: '',
};

export default class BillingPlansList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/sendio/credential?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: this.getCredsSuccess,
      onFailure: this.getPlansFailed,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { requestApi, currentAppId } = this.props;
    const { currentAppId: prevAppId } = prevProps;

    if (prevAppId !== currentAppId) {
      requestApi({
        url: `/v1/sendio/credential?app_id=${currentAppId}`,
        method: 'GET',
        onSuccess: this.getCredsSuccess,
        onFailure: this.getPlansFailed,
      });
    }
  }

  getCredsSuccess = (data: EmailCreds): void => {
    if (!data) return;

    const {
      id,
      clientId,
      clientToken,
      iterableApiKey,
      iterableApiKeySandbox,
    } = data;

    this.setState({
      credentials: {
        id,
        clientId,
        clientToken,
        iterableApiKey,
        iterableApiKeySandbox,
      },
    });
  };

  getPlansFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  saveCredSuccess = (newCred: EmailCreds): void => {
    this.setState({
      credentials: newCred,
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSaveCred = (cred: EmailCreds): void => {
    const { currentAppId, requestApi } = this.props;
    const {
      id,
      clientId,
      clientToken,
      iterableApiKey,
      iterableApiKeySandbox,
    } = cred;

    const body = {
      app_id: currentAppId,
      client_id: clientId,
      client_token: clientToken,
      iterable_api_key: iterableApiKey,
      iterable_api_key_sandbox: iterableApiKeySandbox,
    };

    if (id) {
      requestApi({
        url: `/v1/sendio/credential/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveCredSuccess(cred),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/sendio/credential`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveCredSuccess({ ...cred, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deleteCredSuccess = (id: string): void => {
    this.setState((prevState) => {
      if (prevState.credentials && prevState.credentials.id === id) {
        return INITIAL_STATE;
      }

      return prevState;
    });
  };

  handleDeleteCred = ({ id }: { id?: string }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/sendio/credential/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deleteCredSuccess(id),
        onFailure: this.handleError,
      });
    }
  };

  render(): JSX.Element {
    const { credentials, error } = this.state;

    // eslint-disable-next-line
    // @ts-ignore
    const sendioCredId = credentials && credentials.id;

    return (
      <div>
        {error && <div className={styles.error}>{error}</div>}
        <h2 className="bold label">Email credentials</h2>
        <EmailCredsForm
          cred={credentials || INITIAL_CRED}
          handleSaveCred={this.handleSaveCred}
          handleDelete={this.handleDeleteCred}
        />
        <EmailsTemplateList sendioCredId={sendioCredId || ''} />
      </div>
    );
  }
}
