import React, { Component } from 'react';
import { CredentialsState, PaymentCredentials } from '_types';
import PaymentCredentialsForm from './PaymentCredentialsForm';
import styles from '../appPage.module.scss';

interface State {
  credentials: Array<CredentialsState | null>;
  error: string;
}

interface Props {
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  credentials: [],
  error: '',
};

const INITIAL_CREDENTIALS = {
  paymentGateway: { value: 'solid', label: 'solid' },
  wallet: '',
  merchant: '',
  secretKey: '',
  solidGateKey: '',
  solidGateMerchant: '',
  apiBaseUrl: '',
  clientId: '',
  // sendbox values
  merchantSandbox: '',
  secretKeySandbox: '',
  solidGateKeySandbox: '',
  solidGateMerchantSandbox: '',
};

export default class CredentialsList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/payment/credential?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: this.getCredsSuccess,
      onFailure: this.getCredsFailed,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { requestApi, currentAppId } = this.props;
    const { currentAppId: prevAppId } = prevProps;

    if (prevAppId !== currentAppId) {
      requestApi({
        url: `/v1/payment/credential?app_id=${currentAppId}`,
        method: 'GET',
        onSuccess: this.getCredsSuccess,
        onFailure: this.getCredsFailed,
      });
    }
  }

  getCredsSuccess = (data: PaymentCredentials[]): void => {
    const credsToState = data.map((creds) => {
      const { paymentGateway, wallet, id, solidConfig, payPalConfig } = creds;

      if (paymentGateway === 'solid' && solidConfig) {
        return {
          ...INITIAL_CREDENTIALS,
          paymentGateway: { value: 'solid', label: 'solid' },
          id,
          wallet,
          merchant: solidConfig.merchant,
          secretKey: solidConfig.secretKey,
          solidGateKey: solidConfig.solidGateKey,
          solidGateMerchant: solidConfig.solidGateMerchant,
          // sendbox values
          merchantSandbox: solidConfig.merchantSandbox,
          secretKeySandbox: solidConfig.secretKeySandbox,
          solidGateKeySandbox: solidConfig.solidGateKeySandbox,
          solidGateMerchantSandbox: solidConfig.solidGateMerchantSandbox,
        };
      }

      if (paymentGateway === 'paypal' && payPalConfig) {
        return {
          ...INITIAL_CREDENTIALS,
          paymentGateway: { value: 'paypal', label: 'paypal' },
          id,
          wallet,
          secretKey: payPalConfig.secretKey,
          apiBaseUrl: payPalConfig.apiBaseUrl,
          clientId: payPalConfig.clientId,
        };
      }

      return null;
    });

    this.setState({ credentials: credsToState });
  };

  getCredsFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  handleOpenCredentialsForm = (): void => {
    this.setState((prevState) => ({
      credentials: [
        { ...INITIAL_CREDENTIALS, tempId: new Date().getTime() },
        ...prevState.credentials,
      ],
    }));
  };

  saveCredentialsSuccess = (cred: CredentialsState): void => {
    this.setState((prevState) => {
      const newCreds = prevState.credentials.map((credential) => {
        if (
          credential &&
          (credential.id === cred.id || credential.tempId === cred.tempId)
        ) {
          return cred;
        }

        return credential;
      });

      return { credentials: newCreds };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSaveCredentials = (cred: CredentialsState): void => {
    const { currentAppId, requestApi } = this.props;
    const {
      paymentGateway,
      wallet,
      merchant,
      secretKey,
      solidGateKey,
      solidGateMerchant,
      apiBaseUrl,
      clientId,
      id,
      // sendbox values
      merchantSandbox,
      secretKeySandbox,
      solidGateKeySandbox,
      solidGateMerchantSandbox,
      tempId,
    } = cred;

    const paymentGate = paymentGateway && paymentGateway.value;
    let body;

    switch (paymentGate) {
      case 'solid': {
        body = {
          app_id: currentAppId,
          wallet,
          payment_gateway: 'solid',
          solid_config: {
            merchant,
            secret_key: secretKey,
            solid_gate_key: solidGateKey,
            solid_gate_merchant: solidGateMerchant,
            merchant_sandbox: merchantSandbox,
            secret_key_sandbox: secretKeySandbox,
            solid_gate_key_sandbox: solidGateKeySandbox,
            solid_gate_merchant_sandbox: solidGateMerchantSandbox,
          },
        };
        break;
      }

      case 'paypal': {
        body = {
          app_id: currentAppId,
          wallet,
          payment_gateway: 'paypal',
          pay_pal_config: {
            secret_key: secretKey,
            api_base_url: apiBaseUrl,
            client_id: clientId,
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    if (!body) {
      this.setState({
        error: 'wrong payment gateway',
      });
    }

    if (id) {
      requestApi({
        url: `/v1/payment/credential/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveCredentialsSuccess(cred),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/payment/credential`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveCredentialsSuccess({ ...cred, tempId, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deleteCredentialsSuccess = (id: string): void => {
    this.setState((prevState) => ({
      credentials: prevState.credentials.filter(
        (cred) => cred && cred.id !== id
      ),
    }));
  };

  handleDeleteCred = (id: string): void => {
    const { requestApi } = this.props;

    requestApi({
      url: `/v1/payment/credential/${id}`,
      method: 'DELETE',
      onSuccess: () => this.deleteCredentialsSuccess(id),
      onFailure: this.handleError,
    });
  };

  render(): JSX.Element {
    const { credentials, error } = this.state;

    return (
      <div>
        <div className="title_row">
          <button
            type="button"
            className="btn_create"
            onClick={this.handleOpenCredentialsForm}
          >
            Add credentials
          </button>
        </div>

        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.forms_container}>
          {credentials.map(
            (credential: CredentialsState) =>
              credential && (
                <div
                  className={styles.form_wrapper}
                  key={credential.id || credential.tempId}
                >
                  <PaymentCredentialsForm
                    credentials={credential}
                    handleSaveCredentials={this.handleSaveCredentials}
                    handleDelete={this.handleDeleteCred}
                  />
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}
