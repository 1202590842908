import React, { useState } from 'react';
import { ReactComponent as IphoneX } from 'assets/svg/Iphone_X.svg';
import { ReactComponent as Iphone5 } from 'assets/svg/Iphone_5.svg';
import { ReactComponent as Ipad } from 'assets/svg/Ipad.svg';
import styles from '../screens.module.scss';

enum VIEW {
  IPHONE_5 = 'IPHONE_5',
  IPHONE_X = 'IPHONE_X',
  IPAD = 'IPAD',
}

interface Props {
  screenHtml: string;
}

const EditPreview: React.FC<Props> = (props: Props) => {
  const [view, setView] = useState<VIEW>(VIEW.IPHONE_X);

  const { screenHtml } = props;

  if (!screenHtml) return null;

  const changeView = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { layout } = e.currentTarget && e.currentTarget.dataset;

    setView(layout as VIEW);
  };

  let wrapperStyle = styles.preview;

  if (view === VIEW.IPHONE_X) {
    wrapperStyle = styles.iphone_x_wrapper;
  } else if (view === VIEW.IPAD) {
    wrapperStyle = styles.ipad_wrapper;
  }

  return (
    <div>
      <div className={styles.view_toggler}>
        <button
          type="button"
          data-layout={VIEW.IPHONE_5}
          onClick={changeView}
          className={`${styles.view_btn} ${
            view === VIEW.IPHONE_5 ? styles.active_btn : ''
          }`}
        >
          <Iphone5
            className={`${styles.iphone_icon} ${
              view === VIEW.IPHONE_5 ? styles.active_icon : ''
            }`}
          />
        </button>

        <button
          type="button"
          data-layout={VIEW.IPHONE_X}
          onClick={changeView}
          className={`${styles.view_btn} ${
            view === VIEW.IPHONE_X ? styles.active_btn : ''
          }`}
        >
          <IphoneX
            className={`${styles.iphone_icon} ${
              view === VIEW.IPHONE_X ? styles.active_icon : ''
            }`}
          />
        </button>

        <button
          type="button"
          data-layout={VIEW.IPAD}
          onClick={changeView}
          className={`${styles.view_btn} ${
            view === VIEW.IPAD ? styles.active_btn : ''
          }`}
        >
          <Ipad
            className={`${styles.ipad_icon} ${
              view === VIEW.IPAD ? styles.active_icon : ''
            }`}
          />
        </button>
      </div>
      <div className={wrapperStyle}>
        <iframe
          className={styles.preview_iframe}
          scrolling="no"
          title="screen previw"
          src={`data:text/html;charset=utf-8,${encodeURIComponent(screenHtml)}`}
        />
      </div>
    </div>
  );
};

export default EditPreview;
