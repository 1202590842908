import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Member } from '_types';
import MemberRow from './MemberRow';

interface Props {
  team: Member[];
  getAppTeam: Function;
  resetAppTeam: Function;
  removeMember: Function;
  currentAppId: string;
}

const TeamPage: React.FC<Props> = (props: Props) => {
  const { team, getAppTeam, removeMember, resetAppTeam, currentAppId } = props;

  useEffect(() => {
    getAppTeam();
    return () => {
      resetAppTeam();
    };
  }, [getAppTeam, resetAppTeam, currentAppId]);

  return (
    <div>
      <div className="title_row">
        <Link to="/team/invite" className="btn_create">
          Invite new user
        </Link>
      </div>

      <div className="table_wrapper">
        <div className="table_row table_head">
          <div className="table_cell_60 bold">Name</div>
          <div className="table_cell_40 bold">Action</div>
        </div>
        <div className="table_content">
          {Array.isArray(team) && team.length > 0
            ? team.map((member: Member) => {
                return (
                  <MemberRow
                    key={member.id}
                    member={member}
                    removeMember={removeMember}
                  />
                );
              })
            : 'Your team members will be here'}
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
