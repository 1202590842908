import React, { useState, useRef } from 'react';
import { useClickOutside } from 'utils/customHooks';

interface Props {
  children: Function;
  defaultOpen?: boolean;
  closeOnClickOutside?: boolean;
}

const DropDown: React.FC<Props> = (props: Props) => {
  const { children, defaultOpen, closeOnClickOutside } = props;
  const [open, setOpen] = useState(defaultOpen || false);
  const menuRef = useRef(null);

  const toggleMenu = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (): void => {
    if (open) setOpen(false);
  };

  const clickOutside = (): void => {
    if (closeOnClickOutside && open) setOpen(false);
  };

  const openMenu = (): void => {
    if (open) setOpen(false);
  };

  useClickOutside(open, menuRef, clickOutside);

  return (
    <div ref={menuRef}>{children(open, toggleMenu, openMenu, closeMenu)}</div>
  );
};

export default DropDown;
