import React from 'react';
import styles from './filter.module.scss';

interface Props {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  value: string | number;
}

const InputFilter: React.FC<Props> = (props: Props) => {
  const { onChange, value, label, name } = props;

  return (
    <div className={styles.input_filter}>
      <div className={styles.input_label}>{label}</div>
      <input
        type="text"
        className="input"
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  );
};

export default InputFilter;
