import React from 'react';
import Modal from 'react-modal';
import { Action } from '_types';
import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  closeModal: () => Action;
}

const CancelPaymentModal: React.FC<Props> = (props: Props) => {
  const { isOpen, closeModal } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal_content"
      overlayClassName="modal_overlay"
    >
      <div className={styles.close_btn_row}>
        <button type="button" className="close_btn" onClick={closeModal}>
          <div className="close_icon" />
        </button>
      </div>

      <h4 className="title">Payment canceled</h4>

      <p className={styles.paragraph}>Payment was canceled. Retry later.</p>
    </Modal>
  );
};

export default CancelPaymentModal;
