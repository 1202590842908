import ACTION_TYPES from '_redux/actionTypes';
import { Action, Business } from '_types';

const businessesReducer = (
  state: Business[] = [],
  action: Action
): Business[] => {
  switch (action.type) {
    case ACTION_TYPES.SET_USER_BUSINESSES:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export default businessesReducer;
