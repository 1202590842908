import React from 'react';

type Props = {
  className?: string;
};

const Upload: React.FC<Props> = ({ className }: Props) => (
  <svg
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.82365 12.7647H10.1766V8.41174H13.4119L9.00012 2L4.58838 8.41174H7.82365V12.7647Z" />
    <path d="M15.4119 14.8828H2.58838V17.0005H15.4119V14.8828Z" />
  </svg>
);

export default Upload;
