import React, { useState, useRef } from 'react';
import { useClickOutside } from 'utils/customHooks';
import styles from './appsMenu.module.scss';
import AppMenuItem from './AppMenuItem';

interface Props {
  apps: Array<any>;
  currentAppId: string;
  setCurrentApp: Function;
  closeModal: Function;
}

const AppsMenu: React.FC<Props> = (props: Props) => {
  const { apps, currentAppId, setCurrentApp, closeModal } = props;

  const [open, setOpen] = useState<boolean>(false);
  const menuRef = useRef(null);

  const toggleMenu = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (): void => {
    if (open) setOpen(false);
  };

  const handleAppClick = (AppId: string): void => {
    setCurrentApp(AppId);
    closeMenu();
    closeModal();
  };

  useClickOutside(open, menuRef, closeMenu);

  const currentApp = apps.find((app) => app.id === currentAppId);

  const currentAppName = currentApp ? currentApp.name : 'No aps yet';

  return (
    <div className={styles.menu_wrapper}>
      <button type="button" className={styles.toggler} onClick={toggleMenu}>
        {currentAppName}

        <div className={styles.arrow_wrapper} />
      </button>
      {open && (
        <div className={styles.drop_down} ref={menuRef}>
          {apps && apps.length > 0 ? (
            apps.map((app, i) => {
              return (
                <AppMenuItem
                  key={app.id}
                  app={app}
                  setCurrentApp={handleAppClick}
                  index={i}
                />
              );
            })
          ) : (
            <div className={styles.placeholder}>
              Apps belonging to your business will be here
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AppsMenu;
