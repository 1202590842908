import React from 'react';
import { Report } from '_types';
import { getDotsDate } from 'utils/formatDate';

interface Props {
  report: Report;
  maxRebills: number;
}

const ReportRow: React.FC<Props> = (props: Props) => {
  const { report, maxRebills } = props;
  if (!report) return null;

  const { date, trials, purchases, rebills, payments, installQty } = report;

  const conversion =
    Number.isNaN(payments / purchases) || !Number.isFinite(payments / purchases)
      ? 0
      : Math.round((payments / purchases) * 100);

  const dateString = getDotsDate(date);

  return (
    <tr>
      <td>{dateString}</td>
      <td>{trials}</td>
      <td>{installQty}</td>
      <td>{purchases}</td>
      <td>{payments}</td>
      <td>{conversion}%</td>
      {Array.isArray(rebills)
        ? rebills.map((count, i) => <td key={i.toString()}>{count}</td>)
        : new Array(maxRebills)
            .fill(0)
            .map((el, i) => <td key={i.toString()}>0</td>)}
    </tr>
  );
};

export default ReportRow;
