import React, { Component } from 'react';
import { Product, Price, PageState } from '_types';
import { RouteComponentProps } from 'react-router-dom';
import ProductsForm from './ProductsForm';
import PricesForm from './PricesForm';
import ProductsRow from './ProductsRow';
import styles from '../AppPage/appPage.module.scss';
import Filter from './FilterContainer';
import Pagination from '../Pagination/Pagination';
import SortTrigger from '../Filter/SortTrigger';

interface State {
  products: Array<Product | null>;
  prices: Array<Price | null>;
  error: string;
  subToEditId: string | number;
  isEditing: boolean;
  isEditingPrice: boolean;
}

interface Props extends RouteComponentProps {
  getProducts: Function;
  resetProducts: Function;
  products: Array<Product>;
  pageData: PageState;
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  products: [],
  prices: [],
  error: '',
  subToEditId: '',
  isEditing: false,
  isEditingPrice: false,
};

const INITIAL_PRODUCT = {
  id: '',
  appSubscriptionId: 0,
  appId: '',
  referenceName: '',
  source: '',
  productId: '',
  revenue: 0,
  duration: '',
  durationInDays: 0,
  hasTrial: false,
  appStoreId: '',
  hasOffer: false,
  createdAt: '',
  offerRevenue: 0,
  offerDuration: '',
  offerDurationInDays: 0,
  consumable: false,
  trialDuration: '',
  trialDurationInDays: 0,
  priceId: 0,
  country: '',
  price: 0,
  outdatedAt: 0,
  priceCreatedAt: '',
  priceUpdatedAt: '',
};

const INITIAL_PRICE = {
  id: {
    value: '',
    label: '',
  },
  country: '',
  price: 0,
};

export default class ProductsList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { history, currentAppId, getProducts } = this.props;
    const search = history && history.location && history.location.search;

    const queryParams = new URLSearchParams(search);

    if (currentAppId) {
      getProducts(currentAppId, queryParams);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { history, getProducts, currentAppId, resetProducts } = this.props;
    const search = history && history.location && history.location.search;

    const prevSearch = prevProps.location && prevProps.location.search;
    const prevAppId = prevProps.currentAppId;

    const shouldRequest =
      (prevSearch !== search || prevAppId !== currentAppId) && currentAppId;

    if (shouldRequest) {
      const queryParams = new URLSearchParams(search);

      if (prevAppId !== currentAppId) {
        resetProducts();
      }
      getProducts(currentAppId, queryParams);
    }
  }

  componentWillUnmount(): void {
    const { resetProducts } = this.props;

    resetProducts();
  }

  getProductsSuccess = (data: Product[]): void => {
    const productsToState = data.map((prod) => {
      const {
        id,
        appSubscriptionId,
        appId,
        source,
        referenceName,
        productId,
        revenue,
        duration,
        durationInDays,
        hasTrial,
        appStoreId,
        hasOffer,
        createdAt,
        offerRevenue,
        offerDuration,
        offerDurationInDays,
        consumable,
        trialDuration,
        trialDurationInDays,
        priceId,
        country,
        price,
        outdatedAt,
        priceCreatedAt,
        priceUpdatedAt,
      } = prod;

      return {
        id,
        appSubscriptionId,
        appId,
        source,
        productId,
        revenue,
        duration,
        durationInDays,
        hasTrial,
        appStoreId,
        hasOffer,
        createdAt,
        offerRevenue,
        offerDuration,
        offerDurationInDays,
        consumable,
        referenceName,
        trialDuration,
        trialDurationInDays,
        priceId,
        country,
        price,
        outdatedAt,
        priceCreatedAt,
        priceUpdatedAt,
      };
    });

    this.setState({ products: productsToState });
  };

  getPlansFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  handleOpenProductForm = (): void => {
    const tempId = new Date().getTime();
    this.setState(() => ({
      products: [{ ...INITIAL_PRODUCT, tempId }],
      subToEditId: tempId,
      isEditing: true,
    }));

    const { products } = this.props;
    products.push({ ...INITIAL_PRODUCT, tempId });
  };

  handleOpenPriceForm = (): void => {
    const tempId = new Date().getTime();
    this.setState((prevState) => ({
      prices: [{ ...INITIAL_PRICE }, ...prevState.prices],
      subToEditId: tempId,
      isEditing: false,
      isEditingPrice: true,
    }));
  };

  handleEditStart = (id: string | number): void => {
    this.setState({
      subToEditId: id,
      isEditing: true,
      isEditingPrice: false,
    });
  };

  handleEditStop = (): void => {
    this.setState({
      isEditing: false,
      isEditingPrice: false,
    });
  };

  saveProductSuccess = (newProduct: Product): void => {
    this.setState((prevState) => {
      const newProducts = prevState.products.map((prod) => {
        const match =
          (prod &&
            prod.appSubscriptionId &&
            prod.appSubscriptionId === newProduct.appSubscriptionId) ||
          (prod && prod.tempId && prod.tempId === newProduct.tempId);
        if (match) {
          return { ...newProduct };
        }

        return prod;
      });

      return { products: newProducts, isEditing: false, isEditingPrice: false };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSavePrice = (prices: Price): void => {
    const { requestApi, products, currentAppId } = this.props;
    const { id, country, price } = prices;

    const elem = products.filter((product: Product) => {
      return product.id === id.value;
    });

    const {
      appSubscriptionId,
      source,
      referenceName,
      productId,
      revenue,
      duration,
      durationInDays,
      hasTrial,
      appStoreId,
      hasOffer,
      createdAt,
      offerRevenue,
      offerDuration,
      offerDurationInDays,
      consumable,
      trialDuration,
      trialDurationInDays,
    } = elem[0];

    const body: Record<string, any> = {
      id: appSubscriptionId,
      source,
      app_id: currentAppId,
      reference_name: referenceName,
      product_id: productId,
      revenue: parseFloat(String(revenue)),
      duration,
      duration_in_days: parseInt(String(durationInDays), 10),
      has_trial: hasTrial,
      app_store_id: appStoreId,
      has_offer: hasOffer,
      created_at: createdAt,
      offer_revenue: parseFloat(String(offerRevenue)),
      offer_duration: offerDuration,
      offer_duration_in_days: parseInt(String(offerDurationInDays), 10),
      consumable,
      trial_duration: trialDuration,
      trial_duration_in_days: parseInt(String(trialDurationInDays), 10),
      prices: [
        {
          price: parseFloat(String(price)),
          country,
        },
      ],
    };

    requestApi({
      url: `/v1/app-subscriptions`,
      method: 'POST',
      body,
      onSuccess: (data: Record<string, any>) => {
        const createdId = data && data.id;

        if (createdId) {
          this.saveProductSuccess({
            ...elem[0],
            appSubscriptionId: data.id,
            id: Date.now().toString(16),
          });
        }
      },
      onFailure: this.handleError,
    });
  };

  handleSaveProduct = (prod: Product): void => {
    const { requestApi, currentAppId } = this.props;

    const {
      id,
      appSubscriptionId,
      source,
      referenceName,
      productId,
      revenue,
      duration,
      durationInDays,
      hasTrial,
      appStoreId,
      hasOffer,
      createdAt,
      offerRevenue,
      offerDuration,
      offerDurationInDays,
      consumable,
      trialDuration,
      trialDurationInDays,
      priceId,
      country,
      price,
      outdatedAt,
      priceCreatedAt,
      priceUpdatedAt,
    } = prod;

    const body: Record<string, any> = {
      id: appSubscriptionId,
      app_id: currentAppId,
      source,
      reference_name: referenceName,
      product_id: productId,
      revenue: parseFloat(String(revenue)),
      duration,
      duration_in_days: parseInt(String(durationInDays), 10),
      has_trial: hasTrial,
      app_store_id: appStoreId,
      has_offer: hasOffer,
      created_at: createdAt,
      offer_revenue: parseFloat(String(offerRevenue)),
      offer_duration: offerDuration,
      offer_duration_in_days: parseInt(String(offerDurationInDays), 10),
      consumable,
      trial_duration: trialDuration,
      trial_duration_in_days: parseInt(String(trialDurationInDays), 10),
      priceId,
      outdatedAt,
      priceCreatedAt,
      priceUpdatedAt,
      prices: [
        {
          price: parseFloat(String(price)),
          country,
        },
      ],
    };

    if (id) {
      requestApi({
        url: `/v1/app-subscriptions/${appSubscriptionId}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveProductSuccess(prod),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/app-subscriptions`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveProductSuccess({ ...prod, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deleteProductSuccess = (id: number): void => {
    this.setState((prevState) => ({
      products: prevState.products.filter(
        (prod) => prod && prod.appSubscriptionId !== id && prod.tempId !== id
      ),
    }));
  };

  handleDeleteProduct = ({
    id,
    tempId,
  }: {
    id?: number;
    tempId: number;
  }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/app-subsriptions/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deleteProductSuccess(id),
        onFailure: this.handleError,
      });
    } else if (tempId) {
      this.deleteProductSuccess(tempId);
    }
  };

  render(): JSX.Element {
    const { error, subToEditId, isEditing, isEditingPrice } = this.state;
    const { products, pageData, currentAppId } = this.props;

    const { total, perPage } = pageData;

    const planForEdit =
      products &&
      Array.isArray(products) &&
      products.find((product: Product) => {
        const match =
          (product && product.id && product.id === subToEditId) ||
          (product && product.tempId && product.tempId === subToEditId);

        return match;
      });

    const ids = products.map((p) => p.appSubscriptionId);
    const selectValues = products
      .filter(({ appSubscriptionId }: Product, index: number) => {
        return !ids.includes(appSubscriptionId, index + 1);
      })
      .map((product: Product) => {
        return {
          label: `${product.appSubscriptionId}-${product.productId}`,
          value: product.id,
        } as {
          value: string;
          label: string;
        };
      });

    return (
      <div className="content">
        <div className="title_row">
          <h1 className="title">Products</h1>
        </div>

        <div className="title_row">
          <button
            type="button"
            className="btn_create"
            onClick={this.handleOpenProductForm}
          >
            Add product
          </button>

          <div className="title_btn_box">
            <button
              type="button"
              className="btn_create"
              onClick={this.handleOpenPriceForm}
            >
              Add price
            </button>
          </div>
        </div>

        <Filter />

        {error && <div className={styles.error}>{error}</div>}

        <div className="table_scroll_wrapper">
          <table className="real_table_wrapper">
            <thead className="table_head">
              <tr className="table_head">
                <th>Edit</th>
                <th className="bold">
                  <SortTrigger label="ID" sortBy="id" />
                </th>
                <th className="bold">
                  <SortTrigger label="Product Id" sortBy="product_id" />
                </th>
                <th className="bold">
                  <SortTrigger label="Reference name" sortBy="reference_name" />
                </th>
                <th className="bold">Price Id</th>
                <th className="bold">Price</th>
                <th className="bold">Country</th>
                <th className="bold">Outdated</th>
                <th className="bold">Duration</th>
                <th className="bold">Duration in days</th>
                <th className="bold">Trial</th>
                <th className="bold">Trial duration</th>
                <th className="bold">Trial in days</th>
                <th className="bold">Offer</th>
                <th className="bold">Offer revenue</th>
                <th className="bold">Offer duration</th>
                <th className="bold">Offer duration in days</th>
                <th className="bold">Revenue</th>
                <th className="bold">Consumable</th>
              </tr>
            </thead>
            <tbody className="table_content">
              {products && Array.isArray(products) && products.length > 0 ? (
                products.map((prod: Product) => {
                  return (
                    <ProductsRow
                      key={prod.id} // prod.id
                      product={prod}
                      handleStartEdit={this.handleEditStart}
                    />
                  );
                })
              ) : (
                <tr>
                  <td>Products will be here</td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination total={total} perPage={perPage} />
        </div>

        {isEditing && planForEdit && (
          <div className={styles.form_modal}>
            <div className={styles.close_btn_row}>
              <button
                type="button"
                className="close_btn"
                onClick={this.handleEditStop}
              >
                <div className="close_icon" />
              </button>
            </div>
            <ProductsForm
              prod={planForEdit}
              handleSavePlan={this.handleSaveProduct}
              handleDelete={this.handleDeleteProduct}
            />
          </div>
        )}

        {isEditingPrice && (
          <div className={styles.form_modal}>
            <div className={styles.close_btn_row}>
              <button
                type="button"
                className="close_btn"
                onClick={this.handleEditStop}
              >
                <div className="close_icon" />
              </button>
            </div>
            <PricesForm
              appId={currentAppId}
              productArr={selectValues}
              handleSavePrice={this.handleSavePrice}
            />
          </div>
        )}
      </div>
    );
  }
}
