import React from 'react';
import { ModalNames, Action } from '_types';
import FindAppStoreSecretModal from './FindAppStoreSecretModal';
import SuccessPaymentModal from './SuccessPaymentModal';
import CancelPaymentModal from './CancelPaymentModal';
import AppSelectorModal from './AppSelectorModal';

import './modal_transition.scss';

interface Props {
  currentModal: string | null;
  isOpen: boolean;
  openModal: (name: ModalNames) => Action;
  closeModal: () => Action;
}

const ModalDispatcher: React.FC<Props> = (props: Props) => {
  const { currentModal, ...rest } = props;

  switch (currentModal) {
    case ModalNames.FIND_APP_STORE_SECRET:
      return <FindAppStoreSecretModal {...rest} />;

    case ModalNames.SUCCESS_PAYMENT:
      return <SuccessPaymentModal {...rest} />;

    case ModalNames.CANCEL_PAYMENT:
      return <CancelPaymentModal {...rest} />;

    case ModalNames.APP_SELECTOR:
      return <AppSelectorModal {...rest} />;

    default:
      return null;
  }
};

export default ModalDispatcher;
