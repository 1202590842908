import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import Select, { ValueType, ActionMeta } from 'react-select';

import { useFormState } from 'utils/customHooks';
import { validateName } from 'utils/validation';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { App, ModalNames, OptionType } from '_types';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/Eye.svg';
import UploadIcon from 'icons/Upload';
import selectStyles from 'styles/selectStyles';
import config from 'env';
import styles from '../appPage.module.scss';

interface State {
  id: string;
  type: OptionType | string;
  name: string;
  bundleId: string;
  appStoreAppId: string;
  appStoreSharedSecret: string;
  success: string;
  sdkToken: string;
  googlePlayPackageName: string;
  androidCredentialJson: string;
  fileName: string;
  topic: string;
  turnOnCapi: boolean;
  turnOnCapiPaymentsAfterTrialEvents: boolean;
}

const INITIAL_STATE: State = {
  type: { value: 'ios', label: 'IOS' },
  id: '',
  name: '',
  bundleId: '',
  appStoreAppId: '',
  appStoreSharedSecret: '',
  success: '',
  sdkToken: '',
  googlePlayPackageName: '',
  androidCredentialJson: '',
  fileName: '',
  topic: '',
  turnOnCapi: false,
  turnOnCapiPaymentsAfterTrialEvents: false,
};

const platformOptions = [
  { value: 'ios', label: 'IOS' },
  { value: 'android', label: 'Android' },
  { value: 'web', label: 'Web' },
];

interface Props extends RouteComponentProps {
  requestApi: Function;
  currentBusinessId: string;
  setCurrentApp: Function;
  currentApp: App | undefined;
  getUserBusinesses: Function;
  openModal: Function;
}

const AppForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const [error, setError] = useState<string>('');
  const [copySuccess, setCopySuccess] = useState('');
  const [isSecretShow, showSecret] = useState<boolean>(false);

  const { path } = useRouteMatch();
  const editMode = path.includes('settings');
  const { currentApp, openModal } = props;

  const appId = currentApp && currentApp.id;
  const SUBSCRIPTION_LINK = `${config.SUBSCRIPTION_HOST}/v1/webhooks/apple/status-updates?token=${appId}`;

  useEffect(() => {
    if (editMode && currentApp) {
      const {
        id,
        type,
        name,
        bundleId,
        appStoreAppId,
        appStoreSharedSecret,
        sdkToken,
        googlePlayPackageName,
        androidCredentialJson,
        turnOnCapi,
        topic = '',
        turnOnCapiPaymentsAfterTrialEvents,
      } = currentApp;

      const selectedType = platformOptions.find(
        (option) => option.value === type
      );

      dispatch({
        id,
        type: selectedType,
        name,
        bundleId,
        appStoreAppId,
        appStoreSharedSecret,
        sdkToken,
        googlePlayPackageName,
        androidCredentialJson,
        turnOnCapi,
        topic,
        turnOnCapiPaymentsAfterTrialEvents,
      });
      setError('');
    } else if (editMode && !currentApp) {
      dispatch(INITIAL_STATE);
      setError('');
    }
  }, [editMode, currentApp, dispatch]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name } = e?.currentTarget;

    dispatch({ [name]: !formState[name as keyof State] });
  };

  const handleError = (err: any): void => {
    const message = (err && err.message) || 'Something went wrong';
    setError(message);
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget;
    if (!files || files.length === 0) return;

    const file = files[0];
    if (file.type === 'application/json') {
      const reader = new FileReader();
      reader.addEventListener('load', (ev) => {
        const fileContent = ev && ev.target && ev.target.result;

        if (fileContent) {
          dispatch({
            androidCredentialJson: fileContent,
            fileName: file.name,
          });
        }
      });

      reader.readAsBinaryString(file);
    }
  };

  const openAppStoreSecretModal = (): void => {
    openModal(ModalNames.FIND_APP_STORE_SECRET);
  };

  const openGoogleCredentialsModal = (): void => {
    openModal(ModalNames.FIND_GOOGLE_CREDENTIALS_JSON);
  };

  const copyPandaAppIdToClipBoard = (): void => {
    if (formState.sdkToken) {
      copyToClipboard(formState.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No panda app id recieved');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const copySdkTokenToClipBoard = (): void => {
    if (formState.sdkToken) {
      copyToClipboard(formState.sdkToken);
      setCopySuccess('sdkToken');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No SDK token recieved');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const copyLinkToClipBoard = (): void => {
    if (appId) {
      copyToClipboard(SUBSCRIPTION_LINK);
      setCopySuccess('subscriptionLink');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No appId recieved');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const copyTopicClipBoard = (): void => {
    if (formState.topic) {
      copyToClipboard(formState.topic);
      setCopySuccess('topic');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No topic recieved');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const toggleSecretShow = (): void => {
    showSecret((prevState) => !prevState);
  };

  const setTopic = (data: Record<string, any>): void => {
    const { topicRtdn } = data;
    dispatch({ topic: topicRtdn });
  };

  const getTopic = (): void => {
    const { requestApi } = props;

    if (appId) {
      requestApi({
        url: `/v1/google-cloud?app_id=${appId}`,
        method: 'POST',
        onSuccess: setTopic,
        onFailure: handleError,
      });
    }
  };

  const handleSuccessCreate = (data: { id: string }): void => {
    const { history, setCurrentApp, getUserBusinesses } = props;
    const id = data && data.id;

    if (id) {
      const callback = (): void => setCurrentApp(id);
      getUserBusinesses(callback);
      history.push('/');
    } else {
      handleError({ message: "Didn't get app id" });
    }
  };

  const handleSuccessCreateAndroid = (data: { id: string }): void => {
    const { history, setCurrentApp, getUserBusinesses } = props;
    const id = data && data.id;

    if (id) {
      const callback = (): void => setCurrentApp(id);
      getUserBusinesses(callback);
      history.push('/app/settings');
    } else {
      handleError({ message: "Didn't get app id" });
    }
  };

  const handleSuccessEdit = (data: { id: string }): void => {
    const id = data && data.id;
    const { getUserBusinesses } = props;

    if (id) {
      // need to get all user businesses when api will ready
      dispatch({ success: 'App saved successfully' });
      getUserBusinesses();
      setTimeout(() => {
        dispatch({ success: '' });
      }, 2000);
    } else {
      handleError({ message: "Didn't get app id" });
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const {
      type,
      name,
      bundleId,
      appStoreAppId,
      appStoreSharedSecret,
      googlePlayPackageName,
      androidCredentialJson,
      turnOnCapi,
      turnOnCapiPaymentsAfterTrialEvents,
    } = formState;
    const { currentBusinessId } = props;

    const selectedType = type && (type as OptionType).value;

    const validName = validateName(name);

    if (!validName) {
      setError('App name should be from 1 to 30 characters length');

      return;
    }

    const { requestApi } = props;

    switch (selectedType) {
      case 'ios': {
        const allDataExist =
          name &&
          bundleId &&
          appStoreAppId &&
          appStoreSharedSecret &&
          currentBusinessId;

        if (allDataExist) {
          if (editMode && currentApp) {
            requestApi({
              url: `/v1/apps/${currentApp.id}`,
              method: 'PUT',
              body: {
                name,
                bundle_id: bundleId,
                app_store_app_id: appStoreAppId,
                app_store_shared_secret: appStoreSharedSecret,
                business_id: currentBusinessId,
                turn_on_capi: turnOnCapi,
                turn_on_capi_payments_after_trial_events: turnOnCapiPaymentsAfterTrialEvents,
              },
              onSuccess: handleSuccessEdit,
              onFailure: handleError,
            });
          } else if (!editMode) {
            requestApi({
              url: '/v1/apps',
              method: 'POST',
              body: {
                type: selectedType,
                name,
                bundle_id: bundleId,
                app_store_app_id: appStoreAppId,
                app_store_shared_secret: appStoreSharedSecret,
                business_id: currentBusinessId,
              },
              onSuccess: handleSuccessCreate,
              onFailure: handleError,
            });
          }
        }
        break;
      }

      case 'android': {
        if (editMode && currentApp) {
          const body: Record<string, any> = {
            name,
            google_play_package_name: googlePlayPackageName,
            business_id: currentBusinessId,
            turn_on_capi: turnOnCapi,
            turn_on_capi_payments_after_trial_events: turnOnCapiPaymentsAfterTrialEvents,
          };

          if (androidCredentialJson) {
            body.android_credential_json = androidCredentialJson;
          }

          requestApi({
            url: `/v1/apps/${currentApp.id}`,
            method: 'PUT',
            body,
            onSuccess: handleSuccessEdit,
            onFailure: handleError,
          });
        } else if (!editMode) {
          const allDataExist =
            name &&
            googlePlayPackageName &&
            androidCredentialJson &&
            currentBusinessId;

          if (allDataExist) {
            requestApi({
              url: '/v1/apps',
              method: 'POST',
              body: {
                type: selectedType,
                name,
                google_play_package_name: googlePlayPackageName,
                android_credential_json: androidCredentialJson,
                business_id: currentBusinessId,
              },
              onSuccess: handleSuccessCreateAndroid,
              onFailure: handleError,
            });
          }
        }
        break;
      }

      case 'web': {
        if (editMode && currentApp) {
          const body: Record<string, any> = {
            name,
            turn_on_capi: turnOnCapi,
            turn_on_capi_payments_after_trial_events: turnOnCapiPaymentsAfterTrialEvents,
          };

          requestApi({
            url: `/v1/apps/${currentApp.id}`,
            method: 'PUT',
            body,
            onSuccess: handleSuccessEdit,
            onFailure: handleError,
          });
        } else if (!editMode) {
          const allDataExist = name && name.trim().length > 0;

          if (allDataExist) {
            requestApi({
              url: '/v1/apps',
              method: 'POST',
              body: {
                type: selectedType,
                name,
                business_id: currentBusinessId,
              },
              onSuccess: handleSuccessCreateAndroid,
              onFailure: handleError,
            });
          }
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  const selectedOS = formState.type && (formState.type as OptionType).value;

  const renderAppIdField = (): JSX.Element => (
    <label htmlFor="pandaAppID" className={formStyles.row}>
      <div className="label">Panda App Id</div>
      <div className="input_w_icon_wrapper">
        <input
          type="text"
          name="pandaAppID"
          className="input_w_icon"
          value={formState.id}
          readOnly
        />
        <button
          className="input_icn_btn"
          type="button"
          onClick={copyPandaAppIdToClipBoard}
        >
          <CopyIcon className="input_icon" />
        </button>
        {copySuccess === 'pandaAppID' && (
          <div className={styles.tooltip}>Copied</div>
        )}
      </div>
    </label>
  );

  const renderSDKTokenInput = (): JSX.Element => (
    <label htmlFor="sdkToken" className={formStyles.row}>
      <div className="label">SDK token</div>
      <div className="input_w_icon_wrapper">
        <input
          type="text"
          name="sdkToken"
          className="input_w_icon"
          value={formState.sdkToken}
          readOnly
        />
        <button
          className="input_icn_btn"
          type="button"
          onClick={copySdkTokenToClipBoard}
        >
          <CopyIcon className="input_icon" />
        </button>
        {copySuccess === 'sdkToken' && (
          <div className={styles.tooltip}>Copied</div>
        )}
      </div>
    </label>
  );

  return (
    <form
      className={editMode ? 'form_wrapper_left' : 'form_wrapper'}
      onSubmit={handleSubmit}
    >
      {editMode ? null : (
        <>
          <div className={formStyles.text_center}>
            <h1 className="sub_title">App Creation</h1>
          </div>
          <div className={formStyles.row}>
            <div className="label_row">
              <div>OS</div>
              <div className={formStyles.small_text}>Required</div>
            </div>

            <Select
              options={platformOptions}
              styles={selectStyles}
              name="type"
              value={formState.type as OptionType}
              onChange={handleSelect}
              isSearchable={false}
            />
          </div>
        </>
      )}

      <label htmlFor="name" className={formStyles.row}>
        <div className="label_row">
          <div>App name</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="name"
          className="input"
          value={formState.name}
          onChange={handleInputChange}
        />
        <div className={formStyles.description}>
          App name is used to identify the app through the platform
        </div>
      </label>

      {editMode && renderAppIdField()}

      {selectedOS === 'ios' && (
        <>
          <label htmlFor="bundleId" className={formStyles.row}>
            <div className="label_row">
              <div>Bundle ID</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="bundleId"
              className="input"
              value={formState.bundleId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="appStoreAppId" className={formStyles.row}>
            <div className="label_row">
              <div>App Store App ID</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="appStoreAppId"
              className="input"
              value={formState.appStoreAppId}
              onChange={handleInputChange}
            />
            <div className={formStyles.description}>
              It can be found in{' '}
              <a
                href="https://appstoreconnect.apple.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                App Store Connect
              </a>
            </div>
          </label>

          <label htmlFor="appStoreSharedSecret" className={formStyles.row}>
            <div className="label_row">
              <div>App Store shared secret</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <div className="input_w_icon_wrapper">
              <input
                type="text"
                autoComplete="off"
                name="appStoreSharedSecret"
                className={`input_w_icon ${isSecretShow ? '' : 'secret_input'}`}
                value={formState.appStoreSharedSecret}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className={`input_icn_btn ${
                  isSecretShow ? '' : 'open_eye_icon'
                }`}
                onClick={toggleSecretShow}
              >
                <EyeIcon className="input_icon" />
              </button>
            </div>
            <div className={formStyles.description}>
              <button
                type="button"
                onClick={openAppStoreSecretModal}
                className="link"
              >
                Where can i find it?
              </button>
            </div>
          </label>

          {editMode && (
            <>
              {renderSDKTokenInput()}

              <label htmlFor="subscriptionURL" className={formStyles.row}>
                <div className="label">Subscription Status URL</div>
                <div className="input_w_icon_wrapper">
                  <input
                    type="text"
                    name="subscriptionURL"
                    className="input_w_icon"
                    value={SUBSCRIPTION_LINK}
                    readOnly
                  />
                  <button
                    className="input_icn_btn"
                    type="button"
                    onClick={copyLinkToClipBoard}
                  >
                    <CopyIcon className="input_icon" />
                  </button>
                  {copySuccess === 'subscriptionLink' && (
                    <div className={styles.tooltip}>Copied</div>
                  )}
                </div>
                <div className={formStyles.description}>
                  Use server notifications from the App Store to monitor and
                  respond to users&apos; subscription status changes.
                  <br />
                  It should be set in{' '}
                  <a
                    href="https://appstoreconnect.apple.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    App Store Connect
                  </a>
                </div>
              </label>
            </>
          )}
        </>
      )}

      {selectedOS === 'android' && (
        <>
          <label htmlFor="googlePlayPackageName" className={formStyles.row}>
            <div className="label_row">
              <div>Google play package name</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="googlePlayPackageName"
              className="input"
              value={formState.googlePlayPackageName}
              onChange={handleInputChange}
            />
          </label>

          <div className={formStyles.row}>
            <div className="label">Android credential Json</div>
            <div className={formStyles.file_row}>
              <label htmlFor="logo" className={formStyles.file_btn}>
                <UploadIcon className={formStyles.upload_icon} />
                <div className={formStyles.upload_text}>Upload</div>
                <input
                  type="file"
                  name="androidCredentialJson"
                  className={formStyles.file_input}
                  onChange={handleFileChange}
                />
              </label>

              <div className={formStyles.file_name}>
                {formState.fileName ? formState.fileName : 'No file chosen'}
              </div>
            </div>
            <div className={formStyles.description}>
              <button
                type="button"
                onClick={openGoogleCredentialsModal}
                className="link"
              >
                Where can i find it?
              </button>
            </div>
          </div>
          {editMode && (
            <>
              <label htmlFor="topic" className={formStyles.row}>
                <div className="label_row">
                  <div>Topic</div>
                  {!formState.topic && (
                    <button type="button" className="link" onClick={getTopic}>
                      Get topic
                    </button>
                  )}
                </div>
                <div className="input_w_icon_wrapper">
                  <input
                    type="text"
                    name="topic"
                    className="input_w_icon"
                    value={formState.topic}
                    readOnly
                  />
                  <button
                    className="input_icn_btn"
                    type="button"
                    onClick={copyTopicClipBoard}
                  >
                    <CopyIcon className="input_icon" />
                  </button>
                  {copySuccess === 'topic' && (
                    <div className={styles.tooltip}>Copied</div>
                  )}
                </div>
              </label>
              {renderSDKTokenInput()}
            </>
          )}
        </>
      )}

      {selectedOS === 'web' && editMode && renderSDKTokenInput()}

      {editMode && (
        <>
          <label htmlFor="turnOnCapi" className={formStyles.row}>
            <div className="label_row">
              Turn On CAPI Requests
              <div className="input_w_icon_wrapper">
                <input
                  type="checkbox"
                  name="turnOnCapi"
                  className="input_w_icon"
                  checked={formState.turnOnCapi || false}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </label>
          <label
            htmlFor="turnOnCapiPaymentsAfterTrialEvents"
            className={formStyles.row}
          >
            <div className="label_row">
              Turn On CAPI Requests After Trial
              <div className="input_w_icon_wrapper">
                <input
                  type="checkbox"
                  name="turnOnCapiPaymentsAfterTrialEvents"
                  className="input_w_icon"
                  checked={
                    formState.turnOnCapiPaymentsAfterTrialEvents || false
                  }
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </label>
        </>
      )}

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>

        {formState.success && <div>{formState.success}</div>}
      </div>
    </form>
  );
};

export default AppForm;
