const customStyles = {
  control: (provided: object) => ({
    ...provided,
    backgroundColor: '#F5F5FB',
    border: 'none',
    minHeight: 40,
    borderRadius: 3,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: object) => ({
    ...provided,
    color: '#000',
  }),
};

export default customStyles;
