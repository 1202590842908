import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Report } from '_types';
import env from 'env';

export const getReports = (appId: string, params?: URLSearchParams) => (
  dispatch: Dispatch
) => {
  const stringParams = params ? `&${params.toString()}` : '';

  const getReportsSuccess = (reports: Report[]): void => {
    if (Array.isArray(reports)) {
      dispatch({
        type: ACTION_TYPES.SET_APP_REPORTS,
        payload: reports,
      });
    }
  };

  const getReportsFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_REPORTS,
      payload: [],
    });
  };

  dispatch(
    requestApi({
      url: `/v1/rebill-report?app_id=${appId}&${env.DEFAULT_REBILLS_FILTER}${stringParams}`, // remove default params when ui will be ready
      method: 'GET',
      onSuccess: getReportsSuccess,
      onFailure: getReportsFailed,
    })
  );
};

export const resetReports = (): Action => ({
  type: ACTION_TYPES.SET_APP_REPORTS,
  payload: [],
});
