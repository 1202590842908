import React from 'react';
import { Link } from 'react-router-dom';

import { Business } from '_types';

interface Props {
  business: Business;
}

const BusinessRow: React.FC<Props> = (props: Props) => {
  const { business } = props;
  if (!business) return null;

  const { logo, title, userStatus, id } = business;

  return (
    <div className="table_row table_item">
      <div className="table_cell_60">
        <div className="table_row">
          <div
            className="row_logo"
            style={logo ? { backgroundImage: `url(${logo})` } : {}}
          />
          <div className="bold">{title}</div>
          <div className="user_role">{userStatus}</div>
        </div>
      </div>
      <div className="table_cell_40">
        <Link
          className="btn_light_grn btn_margin"
          to={`/account/business/${id}`}
        >
          Settings
        </Link>
        <button className="btn_light_grn" type="button">
          Leave
        </button>
      </div>
    </div>
  );
};

export default BusinessRow;
