import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Feedback } from '_types';

export const getFeedbacks = (appId: string) => (dispatch: Dispatch) => {
  const getFeedbackSuccess = (feedbacks: Feedback[] = []): void => {
    if (Array.isArray(feedbacks)) {
      dispatch({
        type: ACTION_TYPES.SET_APP_FEEDBACKS,
        payload: feedbacks,
      });
    }
  };

  const getFeedbacksFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_FEEDBACKS,
      payload: [],
    });
  };

  dispatch(
    requestApi({
      url: `/v1/feedback/answers?app_id=${appId}`,
      method: 'GET',
      onSuccess: getFeedbackSuccess,
      onFailure: getFeedbacksFailed, // change on handling error
    })
  );
};

export const resetFeedbacks = (): Action => ({
  type: ACTION_TYPES.SET_APP_FEEDBACKS,
  payload: [],
});

export const getSurveys = (appId: string) => (dispatch: Dispatch): void => {
  const getSurveysSuccess = (feedbacks: Feedback[] = []): void => {
    if (Array.isArray(feedbacks)) {
      dispatch({
        type: ACTION_TYPES.SET_APP_SURVEYS,
        payload: feedbacks,
      });
    }
  };

  const getSurveysFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_SURVEYS,
      payload: [],
    });
  };

  dispatch(
    requestApi({
      url: `/v1/survey/answers?app_id=${appId}`,
      method: 'GET',
      onSuccess: getSurveysSuccess,
      onFailure: getSurveysFailed, // change on handling error
    })
  );
};

export const resetSurveys = (): Action => ({
  type: ACTION_TYPES.SET_APP_SURVEYS,
  payload: [],
});
