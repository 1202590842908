import ACTION_TYPES from '_redux/actionTypes';
import { Action, Member } from '_types';

const teamReducer = (state: Member[] = [], action: Action): Member[] => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_MEMBERS:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export default teamReducer;
