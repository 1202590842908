import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { AppUser, PageState } from '_types';
import { getUsers, resetUsers } from './usersActions';
import AppUsersList from './AppUsersList';

interface State {
  users: Array<AppUser>;
  pageData: PageState;
  currentAppId: string;
}

const mSTP = (state: RootState): State => {
  const currentAppId = state && state.session && state.session.currentAppId;

  return {
    currentAppId,
    users: state.appUsers.users,
    pageData: state.appUsers.pages,
  };
};

const mDTP = { getUsers, resetUsers };

export default connect(mSTP, mDTP)(AppUsersList);
