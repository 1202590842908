import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import Filter from './PurchaseFilters';

interface State {
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  currentAppId: state && state.session && state.session.currentAppId,
});

export default connect(mSTP)(Filter);
