import * as staging from './staging';
import * as production from './production';

interface Configs {
  [key: string]: any;
}

const configs: Configs = {
  staging,
  production,
};

const env = process.env.REACT_APP_ENV || 'staging';

const config = configs[env];
// const config = configs.production;

export default config;
