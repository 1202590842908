import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Logo from 'icons/Logo';
import { validateEmail, validatePassword } from 'utils/validation';
import { useFormState } from 'utils/customHooks';
import { ReactComponent as EyeIcon } from 'assets/svg/Eye.svg';
import formStyles from 'styles/forms.module.scss';

import styles from '../login.module.scss';

const INITIAL_STATE = {
  email: { value: '', isValid: true },
  password: { value: '', isValid: true },
  confirmPassword: { value: '', isValid: true },
};

type TState = {
  from?: string;
};

interface Props extends RouteComponentProps<{}, any, TState> {
  requestRegister: Function;
}

const RegisterForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const [error, setError] = useState<string>('');
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    let isValid;

    switch (name) {
      case 'email':
        isValid = validateEmail(value);
        break;

      case 'password': {
        isValid = validatePassword(value);
        break;
      }

      default:
        isValid = true;
        break;
    }

    dispatch({ [name]: { value, isValid } });
    setError('');
  };

  const togglePasswordShow = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { name } = e.currentTarget && e.currentTarget.dataset;
    if (!name) {
      return;
    }

    switch (name) {
      case 'password':
        setPasswordShown((prevState) => !prevState);
        break;

      case 'confirmPassword':
        setConfirmPasswordShown((prevState) => !prevState);
        break;

      default:
        break;
    }
  };

  const validateForm = (): boolean => {
    const emailValid =
      formState.email &&
      formState.email.value &&
      formState.email.value.trim() !== '' &&
      formState.email.isValid;

    if (!emailValid) {
      setError('Invalid email');
      return false;
    }

    const passwordValue = formState.password && formState.password.value;
    const validPassword =
      passwordValue.trim() !== '' &&
      formState.password &&
      formState.password.isValid;

    if (!validPassword) {
      setError('Password should be at least 6 characters');
      return false;
    }

    const confirmPasswordValue =
      formState.confirmPassword && formState.confirmPassword.value;
    const passwordsEquals = passwordValue === confirmPasswordValue;

    if (!passwordsEquals) {
      setError('Passwords not equals');
      return false;
    }

    return true;
  };

  const handleError = (err: any): void => {
    const message =
      err && err.message && err.message !== '[object Object]'
        ? err.message
        : 'Something went wrong';
    setError(message);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    if (validateForm()) {
      const email = formState.email.value;
      const password = formState.password.value;
      const { history, location, requestRegister } = props;

      try {
        const newUser = await requestRegister({
          email,
          password,
        });

        if (newUser) {
          const from =
            (location && location.state && location.state.from) ||
            '/business/create';

          history.push(from);
        } else {
          handleError({ message: 'No such user or user is not confirmed.' });
        }
      } catch (rej) {
        handleError(rej);
      }
    }
  };

  return (
    <section className="form_background">
      <div className={styles.logo_box}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <form className="form_wrapper" onSubmit={handleSubmit} autoComplete="on">
        <div className={styles.text_center}>
          <h1 className="sub_title">Welcome!</h1>
          <div className={`${styles.row} ${styles.small_text}`}>
            Already have an account?{' '}
            <Link to="/auth/login" className="link">
              Sign in
            </Link>
          </div>
        </div>

        <label htmlFor="email" className={styles.row}>
          <div className="label">Your email</div>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            value={formState.email.value}
            onChange={handleInputChange}
            autoComplete="email"
          />
        </label>

        <label htmlFor="password" className={formStyles.row}>
          <div className="label_row">
            <div>Password</div>
          </div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              autoComplete="new-password"
              name="password"
              id="password"
              className={`input_w_icon ${
                isPasswordShown ? '' : 'secret_input'
              }`}
              value={formState.password.value}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className={`input_icn_btn ${
                isPasswordShown ? '' : 'open_eye_icon'
              }`}
              data-name="password"
              onClick={togglePasswordShow}
            >
              <EyeIcon className="input_icon" />
            </button>
          </div>
        </label>

        <label htmlFor="confirmPassword" className={formStyles.row}>
          <div className="label_row">
            <div>Confirm password</div>
          </div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              autoComplete="new-password"
              name="confirmPassword"
              id="confirmPassword"
              className={`input_w_icon ${
                isConfirmPasswordShown ? '' : 'secret_input'
              }`}
              value={formState.confirmPassword.value}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className={`input_icn_btn ${
                isConfirmPasswordShown ? '' : 'open_eye_icon'
              }`}
              data-name="confirmPassword"
              onClick={togglePasswordShow}
            >
              <EyeIcon className="input_icon" />
            </button>
          </div>
        </label>

        {error && <div className={styles.error_message}>{error}</div>}

        <div className={styles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Sign up
          </button>
        </div>
        <div
          className={`${styles.row} ${styles.small_text} ${styles.text_center}`}
        >
          By signing in you agree with <br />
          <Link to="/terms" className="link">
            Terms
          </Link>{' '}
          and{' '}
          <Link to="/privacy" className="link">
            Privacy Policy
          </Link>
          ?
        </div>
      </form>
    </section>
  );
};

export default RegisterForm;
