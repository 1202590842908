import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Report } from '_types';
import ReportRow from './ReportRow';
import Filter from './FilterContainer';

interface Props extends RouteComponentProps {
  getReports: Function;
  resetReports: Function;
  reports: Array<Report>;
  currentAppId: string;
}

const Reports: React.FC<Props> = (props: Props) => {
  const { getReports, resetReports, reports, currentAppId, history } = props;

  const search = history && history.location && history.location.search;

  useEffect(() => {
    if (currentAppId) {
      const params = new URLSearchParams(search);
      getReports(currentAppId, params);
    }

    return () => {
      resetReports();
    };
  }, [currentAppId, getReports, resetReports, search]);

  const maxRebills = Array.isArray(reports)
    ? reports.reduce((max, report) => {
        const rebills = report && report.rebills;
        return rebills && rebills.length > max ? rebills.length : max;
      }, 0)
    : 0;

  const rebillsHeader = [];
  for (let i = 1; i <= maxRebills; i += 1) {
    rebillsHeader.push(
      <th className="bold" key={i}>
        R{i}
      </th>
    );
  }

  return (
    <>
      <Filter />
      <div className="table_scroll_wrapper">
        <table className="real_table_wrapper">
          <thead className="table_head">
            <tr className="table_head">
              <th className="bold">Date</th>
              <th className="bold">Trials</th>
              <th className="bold">Installs</th>
              <th className="bold">Purchases</th>
              <th className="bold">Payments</th>
              <th className="bold">Conversion</th>
              {rebillsHeader}
            </tr>
          </thead>
          <tbody className="table_content">
            {reports && Array.isArray(reports) && reports.length > 0 ? (
              reports.map((report: Report, i) => {
                return (
                  <ReportRow
                    key={i.toString()}
                    report={report}
                    maxRebills={maxRebills}
                  />
                );
              })
            ) : (
              <tr>
                <td>Reports will be here</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Reports;
