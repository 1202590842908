import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select, { ValueType, ActionMeta } from 'react-select';

import selectStyles from 'styles/selectStyles';
import formStyles from 'styles/forms.module.scss';
import { useFormState } from 'utils/customHooks';
import { validateEmail } from 'utils/validation';
import { Role, OptionType } from '_types';

const INITIAL_STATE = {
  email: '',
  role: { value: Role.DEVELOPER, label: Role.DEVELOPER },
};
interface Props extends RouteComponentProps {
  requestApi: Function;
  currentAppId: string;
}

const roleOptions = [
  { value: Role.DEVELOPER, label: Role.DEVELOPER },
  { value: Role.ADWMIN, label: Role.ADWMIN },
];

const InviteForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const [error, setError] = useState<string>('');

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const handleSuccess = (): void => {
    const { history } = props;
    history.push('/app/settings/team');
  };

  const handleError = (err?: string): void => {
    const errorText = typeof err === 'string' ? err : 'Something went wrong';
    setError(errorText);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const { requestApi, currentAppId } = props;
    const { email, role } = formState;

    if (!validateEmail(email)) {
      setError('Invalid email');
      return;
    }

    const body = {
      email,
      app_id: currentAppId,
      role: role.value,
    };

    requestApi({
      url: `/v1/members`,
      method: 'POST',
      body,
      onSuccess: handleSuccess,
      onFailure: handleError,
    });
  };

  return (
    <div className="content">
      <form className="form_wrapper_static" onSubmit={handleSubmit}>
        <div className={formStyles.text_center}>
          <h1 className="sub_title">Invite user</h1>
        </div>

        <p className={formStyles.row}>
          Enter the email address you used when you joined and we’ll send you
          instructions to join to this apps.
        </p>

        <label htmlFor="email" className={formStyles.row}>
          <div className="label">Email Address</div>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            value={formState.email}
            onChange={handleInputChange}
            autoComplete="email"
          />
        </label>

        <div className={formStyles.row}>
          <div className="label">Role</div>
          <Select
            options={roleOptions}
            styles={selectStyles}
            name="role"
            value={formState.role as OptionType}
            onChange={handleSelect}
          />
        </div>

        {error && <div className={formStyles.error_message}>{error}</div>}

        <div className={formStyles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default InviteForm;
