import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { Country, CountrySelect, RebillRules } from '_types';
import styles from '../appPage.module.scss';

const INITIAL_STATE = {
  appID: '',
  durationInDays: '',
  greatThanDuration: false,
  lessThanDuration: false,
  durationBeforePay: 0,
  lessDurationInDays: 0,
  isOffer: false,
  isTrial: false,
  countries: [],
  regions: [],
};

interface Props {
  rebillRule: RebillRules;
  countriesArr: Array<Country>;
  handleSaveRebillRules: Function;
  getRegion: Function;
  getSelectedRegion: Function;
  handleDelete: Function;
}

const RebillRulesForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);

  const {
    rebillRule,
    countriesArr,
    getRegion,
    getSelectedRegion,
    handleSaveRebillRules,
    handleDelete,
  } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');
  const [countryRegions, setCountryRegionsSuccess] = useState(
    getRegion(countriesArr, rebillRule.countries)
  );
  useEffect(() => {
    const {
      durationInDays,
      greatThanDuration,
      lessThanDuration,
      durationBeforePay,
      lessDurationInDays,
      isOffer,
      isTrial,
      countries,
      regions,
    } = rebillRule;

    dispatch({
      durationInDays,
      greatThanDuration,
      lessThanDuration,
      durationBeforePay,
      lessDurationInDays,
      isOffer,
      isTrial,
      countries,
      regions,
    });
  }, [rebillRule, dispatch, props]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const copyRebillRuleId = (): void => {
    if (rebillRule.id) {
      copyToClipboard(rebillRule.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No rule id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    handleSaveRebillRules({
      ...formState,
      id: rebillRule.id,
      tempId: rebillRule.tempId,
    });
  };

  const getCountry = (data: Country[]): CountrySelect[] => {
    const countries = data.map((c) => {
      const { id, country } = c;
      return {
        id,
        name: country,
      };
    });

    return countries;
  };

  const getCountrySelectedValue = (
    data: Country[],
    countries: number[]
  ): CountrySelect[] => {
    const selectedCountries = getCountry(data).filter((country) => {
      return countries.includes(country.id);
    });

    return selectedCountries;
  };

  const handleTrialCheckboxChange = (): void => {
    dispatch({ isTrial: !formState.isTrial });
  };

  const handleOfferCheckboxChange = (): void => {
    dispatch({ isOffer: !formState.isOffer });
  };

  const handleGreaterCheckboxChange = (): void => {
    dispatch({ greatThanDuration: !formState.greatThanDuration });
  };

  const handleLessCheckboxChange = (): void => {
    dispatch({ lessThanDuration: !formState.lessThanDuration });
  };

  const deleteRebillRule = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (rebillRule.id) {
      handleDelete({ id: rebillRule.id });
    } else {
      handleDelete({ tempId: rebillRule.id });
    }
  };

  const onSelectCountry = (selectedList: any): void => {
    const ids = selectedList.map((v: any) => {
      return v.id;
    });

    setCountryRegionsSuccess(getRegion(countriesArr, ids));
    dispatch({ countries: ids });
  };

  const onSelectRegion = (selectedList: any): void => {
    const ids = selectedList.map((v: any) => {
      return v.id;
    });

    dispatch({ regions: ids });
  };

  const onRemoveCountry = (selectedList: any): void => {
    const ids = selectedList.map((v: any) => {
      return v.id;
    });

    setCountryRegionsSuccess(getRegion(countriesArr, ids));
    dispatch({ countries: ids });
  };

  const onRemoveRegion = (selectedList: any): void => {
    const ids = selectedList.map((v: any) => {
      return v.id;
    });

    dispatch({ regions: ids });
  };

  return (
    <form className="form_wrapper_left" onSubmit={handleSubmit}>
      {rebillRule.id && (
        <label htmlFor="id" className={formStyles.row}>
          <div className="label">Id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="id"
              className="input_w_icon"
              value={rebillRule.id}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyRebillRuleId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}
      <label htmlFor="durationBeforePay" className={formStyles.row}>
        <div className="label_row">
          <div>Days before pay</div>
        </div>
        <input
          type="text"
          name="durationBeforePay"
          className="input"
          value={formState.durationBeforePay}
          onChange={handleInputChange}
        />
      </label>
      <label htmlFor="durationInDays" className={formStyles.row}>
        <div className="label_row">
          <div>Duration of subscription in days</div>
        </div>
        <input
          type="text"
          name="durationInDays"
          className="input"
          value={formState.durationInDays}
          onChange={handleInputChange}
        />
      </label>
      <label htmlFor="greatThanDuration" className={formStyles.row}>
        <div className="label_row">
          <div>Set gt condition</div>
        </div>
        <input
          type="checkbox"
          name="greatThanDuration"
          className="input_w_icon"
          checked={formState.greatThanDuration || false}
          onChange={handleGreaterCheckboxChange}
        />
      </label>
      <label htmlFor="lessDurationInDays" className={formStyles.row}>
        <div className="label_row">
          <div>
            Max duration of subscription in days (use only with lt condition)
          </div>
        </div>
        <input
          type="text"
          name="lessDurationInDays"
          className="input"
          value={formState.lessDurationInDays}
          onChange={handleInputChange}
        />
      </label>
      <label htmlFor="lessThanDuration" className={formStyles.row}>
        <div className="label_row">
          <div>Set lt condition</div>
        </div>
        <input
          type="checkbox"
          name="lessThanDuration"
          className="input_w_icon"
          checked={formState.lessThanDuration || false}
          onChange={handleLessCheckboxChange}
        />
      </label>
      <label htmlFor="isOffer" className={formStyles.row}>
        <div className="label_row">
          <div>Has offer</div>
        </div>
        <input
          type="checkbox"
          name="isOffer"
          className="input_w_icon"
          checked={formState.isOffer || false}
          onChange={handleOfferCheckboxChange}
        />
      </label>
      <label htmlFor="isTrial" className={formStyles.row}>
        <div className="label_row">
          <div>Has trial</div>
        </div>
        <input
          type="checkbox"
          name="isTrial"
          className="input_w_icon"
          checked={formState.isTrial || false}
          onChange={handleTrialCheckboxChange}
        />
      </label>
      <div className="label_row">
        <div>Countries</div>
      </div>
      <Multiselect
        name="countries"
        options={getCountry(countriesArr)}
        selectedValues={getCountrySelectedValue(
          countriesArr,
          rebillRule.countries
        )}
        onSelect={onSelectCountry}
        onRemove={onRemoveCountry}
        displayValue="name"
      />
      <div className="label_row">
        <div>Regions</div>
      </div>
      <Multiselect
        options={countryRegions}
        selectedValues={getSelectedRegion(countriesArr, rebillRule)}
        onSelect={onSelectRegion}
        onRemove={onRemoveRegion}
        displayValue="name"
      />

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deleteRebillRule}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default RebillRulesForm;
