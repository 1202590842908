import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PieChart, Pie, Cell, Legend } from 'recharts';

import { Feedback, Survey } from '_types';
import groupBy from 'utils/groupBy';
import { RECT_COLORS } from '_config/constants';
import { filter } from 'lodash';
import FeedbackRow from './FeedbackRow';
import Filter from './FilterContainer';
import styles from './feedbacks.module.scss';

interface Props extends RouteComponentProps {
  getFeedbacks: Function;
  resetFeedbacks: Function;
  feedbacks: Array<Feedback>;
  currentAppId: string;
  getSurveys: Function;
  resetSurveys: Function;
  surveys: Array<Survey>;
}

const FeedbackList: React.FC<Props> = (props: Props) => {
  const {
    getFeedbacks,
    resetFeedbacks,
    feedbacks,
    currentAppId,
    getSurveys,
    resetSurveys,
    surveys,
  } = props;

  useEffect(() => {
    if (currentAppId) {
      getFeedbacks(currentAppId);
    }
    return () => {
      resetFeedbacks();
    };
  }, [currentAppId, getFeedbacks, resetFeedbacks]);

  useEffect(() => {
    getSurveys(currentAppId);
    return () => {
      resetSurveys();
    };
  }, [currentAppId, getSurveys, resetSurveys]);

  // need to refactor
  const { location } = props;
  const search = location && location.search;
  let feedbacksData = feedbacks;
  let surveysData = surveys;
  if (search) {
    const query = new URLSearchParams(search);

    const userid = 'user_id';
    const screenid = 'screen_id';
    const userId: any = query.has(userid) ? query.get(userid) : '';
    const screenId: any = query.has(screenid) ? query.get(screenid) : '';
    feedbacksData = feedbacks
      ? filter(feedbacks, (p) => {
          return p.screenId === screenId || p.userId === userId;
        })
      : [];

    surveysData = surveys
      ? filter(surveys, (p) => {
          return p.screenId === screenId || p.userId === userId;
        })
      : [];
  }

  const pieData = surveysData ? groupBy(surveysData, 'answerId') : [];

  return (
    <div className="content">
      <div className="title_row">
        <h1 className="title">Feedbacks</h1>
      </div>

      <Filter />
      {pieData && Array.isArray(pieData) && pieData.length > 0 && (
        <div className={styles.pie_box}>
          <PieChart width={300} height={300}>
            <Pie
              data={pieData}
              dataKey="value"
              outerRadius="70%"
              innerRadius="50%"
            >
              {pieData.map((item, index) => (
                <Cell
                  fill={RECT_COLORS[index % RECT_COLORS.length]}
                  key={item.name}
                />
              ))}
            </Pie>
            <Legend
              payload={pieData.map((item, index) => ({
                id: item.name,
                type: 'square',
                value: `${item.name} (${item.value})`,
                color: RECT_COLORS[index % RECT_COLORS.length],
              }))}
            />
          </PieChart>
        </div>
      )}

      <div className="table_wrapper">
        <div className="table_row table_head">
          <div className="table_cell_20 bold">Screen id</div>
          <div className="table_cell_20 bold">User id</div>
          <div className="table_cell_60 bold">Feedback</div>
        </div>
        <div className="table_content">
          {feedbacksData &&
          Array.isArray(feedbacksData) &&
          feedbacksData.length > 0
            ? feedbacksData.map((feedback: Feedback) => {
                return <FeedbackRow key={feedback.id} feedback={feedback} />;
              })
            : 'Feedbacks will be here'}
        </div>
      </div>
    </div>
  );
};

export default FeedbackList;
