import { Dispatch } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';

interface Loaders {
  [kye: string]: any;
}

const loaders: Loaders = {};

export const startLoading = (label: string) => (dispatch: Dispatch) => {
  clearTimeout(loaders[label]);

  loaders[label] = setTimeout(() => {
    dispatch({ type: ACTION_TYPES.START_LOADING });
    delete loaders[label];
  });
};

export const finishLoading = (label: string) => (dispatch: Dispatch) => {
  if (loaders[label]) {
    clearTimeout(loaders[label]);
  } else {
    dispatch({
      type: ACTION_TYPES.FINISH_LOADING,
    });
  }
};
