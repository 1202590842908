const downloadData = (
  blob: Blob,
  fileName?: string,
  fileExtention?: string
): void => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;

  let name = '';
  if (fileName && fileExtention) {
    name = `${fileName}.${fileExtention}`;
  }
  link.setAttribute('download', name);

  // for IE support
  // if (typeof link.download === 'ubdefined') {
  //   link.setAttribute('target', '_blank');
  // }

  if (document.body) {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  }
};

export default downloadData;
