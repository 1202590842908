import React from 'react';
import { PurchaseWeb } from '_types';
import { getDateWithSeconds } from 'utils/formatDate';
import { Link } from 'react-router-dom';

interface Props {
  purchase: PurchaseWeb;
}

const PurchaseRowWeb: React.FC<Props> = (props: Props) => {
  const { purchase } = props;
  if (!purchase) return null;

  const {
    id,
    userId,
    purchaseDate,
    price,
    status,
    orderStatus,
    orderId,
    isTrialPeriod,
    email,
    transactionEmail,
    currency,
    transactionPurchaseDate,
    cancelCode,
    paymentGate,
  } = purchase;

  const purchaseDateString = purchaseDate
    ? getDateWithSeconds(purchaseDate)
    : '-';

  const transactionDateString = transactionPurchaseDate
    ? getDateWithSeconds(transactionPurchaseDate)
    : '-';

  return (
    <tr>
      <td>{id}</td>
      <td>
        <Link to={`/statistics/users?user_id=${userId}`}>{userId}</Link>
      </td>
      <td>{purchaseDateString}</td>
      <td>{transactionDateString}</td>
      <td>{paymentGate}</td>
      <td>
        {price} {currency}
      </td>
      <td>{orderId}</td>
      <td>{orderStatus}</td>
      <td>{status}</td>
      <td>{isTrialPeriod ? 'yes' : 'no'}</td>
      <td>{transactionEmail}</td>
      <td>{email}</td>
      <td>{cancelCode}</td>
    </tr>
  );
};

export default PurchaseRowWeb;
