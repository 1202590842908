import React from 'react';
import { Switch, Route, RouteComponentProps, NavLink } from 'react-router-dom';
import Reports from 'modules/Reports/ReportsContainer';
import Purchases from 'modules/Purchases/PurchasesContainer';
import AppUsers from 'modules/AppUsers/AppUsersContainer';

const StatisticsPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { match } = props;
  const path = match && match.path;
  const url = match && match.url;

  return (
    <div className="content">
      <h1 className="title">Statistics</h1>
      <div className="settings_nav">
        <NavLink
          to={url}
          exact
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Rebills
        </NavLink>
        <NavLink
          to={`${url}/purchases`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Purchases
        </NavLink>
        <NavLink
          to={`${url}/users`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Users
        </NavLink>
      </div>

      <Switch>
        <Route path={path} exact component={Reports} />
        <Route path={`${path}/purchases`} exact component={Purchases} />
        <Route path={`${path}/users`} exact component={AppUsers} />
      </Switch>
    </div>
  );
};

export default StatisticsPage;
