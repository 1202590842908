import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Screen } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

interface Response {
  screens: Array<Screen>;
  meta: {
    total: number;
    offset: number;
    perPage: number;
  };
}

export const getScreens = (appId: string, params?: URLSearchParams) => (
  dispatch: Dispatch
) => {
  const getScreensSuccess = (data: Response): void => {
    const { screens, meta } = data;
    const total = meta?.total;
    const perPage = meta?.perPage;

    if (Array.isArray(screens)) {
      dispatch({
        type: ACTION_TYPES.SET_APP_SCREENS,
        payload: {
          screens,
          pages: { total, perPage },
        },
      });
    } else {
      dispatch({
        type: ACTION_TYPES.SET_APP_SCREENS,
        payload: {
          screens: [],
          pages: INITIAL_PAGE_STATE,
        },
      });
    }
  };
  const stringParams = params ? `&${params.toString()}` : '';

  dispatch(
    requestApi({
      url: `/v1/screens?app_id=${appId}${stringParams}`,
      method: 'GET',
      onSuccess: getScreensSuccess,
      onFailure: getScreensSuccess, // change on handling error
    })
  );
};

export const resetScreens = (): Action => ({
  type: ACTION_TYPES.SET_APP_SCREENS,
  payload: {
    screens: [],
    pages: INITIAL_PAGE_STATE,
  },
});
