import React from 'react';

type Props = {
  className?: string;
};

const Pencil: React.FC<Props> = ({ className }: Props) => (
  <svg
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 14.2492V17.9998H3.75052L14.817 6.93316L11.0665 3.18262L0 14.2492Z" />
    <path d="M17.7075 2.62786L15.3722 0.292542C14.9821 -0.0975141 14.347 -0.0975141 13.957 0.292542L12.1267 2.12281L15.8772 5.87335L17.7075 4.04309C18.0976 3.65303 18.0976 3.01792 17.7075 2.62786Z" />
  </svg>
);

export default Pencil;
