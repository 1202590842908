import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Purchase, PageState } from '_types';
import { getPurchases, resetPurchases } from './purchasesActions';
import Purchases from './Purchases';

interface State {
  purchases: Array<Purchase>;
  pageData: PageState;
  currentAppId: string;
  appType: string;
}

const mSTP = (state: RootState): State => {
  const currentBusinessId =
    state && state.session && state.session.currentBusinessId;

  const currentAppId = state && state.session && state.session.currentAppId;

  const currentBusiness =
    state &&
    state.businesses &&
    state.businesses.find((business) => business.id === currentBusinessId);

  const apps = currentBusiness && currentBusiness.apps;
  const currentApp = apps && apps.find((app) => app.id === currentAppId);

  const appType = (currentApp && currentApp.type) || '';

  return {
    currentAppId,
    purchases: state.purchasesData.purchases,
    pageData: state.purchasesData.pages,
    appType,
  };
};

const mDTP = { getPurchases, resetPurchases };

export default connect(mSTP, mDTP)(Purchases);
