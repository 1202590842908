import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { requestApi } from 'modules/Api/apiActions';
import FacebookCAPIList from './FacebookCAPIList';

interface State {
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  currentAppId: state && state.session && state.session.currentAppId,
});

const mDTP = { requestApi };

export default connect(mSTP, mDTP)(FacebookCAPIList);
