import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, AppUser } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

interface Response {
  users: Array<AppUser>;
  meta: {
    total: number;
    offset: number;
    perPage: number;
  };
}

export const getUsers = (appId: string, params: URLSearchParams) => (
  dispatch: Dispatch
) => {
  const stringParams = params ? `&${params.toString()}` : '';

  const getUsersSuccess = (data: Response): void => {
    const users = data && data.users;
    const total = data && data.meta && data.meta.total;
    const perPage = data && data.meta && data.meta.perPage;

    if (users) {
      dispatch({
        type: ACTION_TYPES.SET_APP_USERS,
        payload: {
          users,
          pages: { total, perPage },
        },
      });
    } else {
      dispatch({
        type: ACTION_TYPES.SET_APP_USERS,
        payload: {
          users: [],
          pages: INITIAL_PAGE_STATE,
        },
      });
    }
  };

  const getUsersFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_USERS,
      payload: {
        users: [],
        pages: INITIAL_PAGE_STATE,
      },
    });
  };

  dispatch(
    requestApi({
      url: `/v1/users?app_id=${appId}${stringParams}`,
      method: 'GET',
      onSuccess: getUsersSuccess,
      onFailure: getUsersFailed,
    })
  );
};

export const resetUsers = (): Action => ({
  type: ACTION_TYPES.SET_APP_USERS,
  payload: {
    users: [],
    pages: INITIAL_PAGE_STATE,
  },
});
