import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Screen, PageState } from '_types';
import Pagination from 'modules/Pagination/Pagination';
import styles from '../screens.module.scss';
import SortTrigger from '../../Filter/SortTrigger';
import ScreenPreview from './ScreenPreview';

interface Props extends RouteComponentProps {
  getScreens: Function;
  appScreens: Array<Screen>;
  currentAppId: string;
  resetScreens: Function;
  pageData: PageState;
}

const ScreensList: React.FC<Props> = (props: Props) => {
  const {
    getScreens,
    appScreens,
    history,
    currentAppId,
    resetScreens,
    pageData,
  } = props;
  const { total, perPage } = pageData;

  const search = history && history.location && history.location.search;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    getScreens(currentAppId, queryParams);

    return () => {
      resetScreens();
    };
  }, [search, getScreens, currentAppId, resetScreens]);

  if (!currentAppId) {
    return <div className="content">App not selected</div>;
  }

  return (
    <div className="content">
      <div className="title_row">
        <h1 className="title">All screens</h1>
        <div className="title_btn_box">
          <Link to="/screens/create" className="btn_create">
            Build new screen
          </Link>
        </div>
        <div className="title_sort_box">
          <SortTrigger label="Sort by date" sortBy="created_at" />
        </div>
      </div>

      <p className={styles.description}>
        List of all the avaiable screens in the app.{' '}
        {/* <span className="link">Learn more</span> */}
      </p>

      {appScreens && appScreens.length > 0 ? (
        <div className={styles.screens_list}>
          {appScreens.map((screen) => (
            <ScreenPreview
              key={screen.id}
              screen={screen}
              currentAppId={currentAppId}
            />
          ))}
        </div>
      ) : (
        <div>App screens will display here</div>
      )}
      <Pagination total={total} perPage={perPage} />
    </div>
  );
};

export default ScreensList;
