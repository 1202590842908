import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Pencil from 'icons/Pencil';
import Copy from 'icons/Copy';
import { Screen } from '_types';
import copyToClipboard from 'utils/copyToClipboard';
import styles from '../screens.module.scss';

interface Props {
  screen: Screen;
  currentAppId: string;
}

const MESSAGE_VIEW_TIME = 2000;
let timerId: ReturnType<typeof setTimeout>;

const ScreenPreview: React.FC<Props> = (props: Props) => {
  const { screen, currentAppId } = props;

  const [visibleMenu, setVisible] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const handleMouseEnter = (): void => {
    setVisible(true);
  };

  const handleMouseLeave = (): void => {
    setVisible(false);
  };

  const showCopySuccess = (): void => {
    clearTimeout(timerId);
    setCopySuccess(true);
    timerId = setTimeout(() => {
      setCopySuccess(false);
    }, MESSAGE_VIEW_TIME);
  };

  const copyToCB = (link: string): void => {
    if (link) {
      copyToClipboard(link);
      showCopySuccess();
    }
  };

  if (!screen) return null;

  const { name, id, screenHtml, type, default: def } = screen;

  return (
    <div className={styles.preview_wrapper}>
      <h5 className={styles.screen_name}>
        {name} [ {def ? '🔘' : '◽️'} {type}]
      </h5>
      <div className={styles.screen_id}>
        {id}
        <button
          type="button"
          onClick={() => copyToCB(id)}
          className={styles.copy_btn}
        >
          <Copy className={styles.copy_icon} />
        </button>
      </div>
      <div className={styles.screen_id} />
      <div
        className={styles.preview}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {visibleMenu && (
          <div className={styles.hover_menu}>
            <div className={styles.hover_menu_buttons}>
              <Link
                to={`/screens/edit/${id}`}
                className={`btn_white_full_width spaced ${styles.menu_btn}`}
              >
                <Pencil className={styles.btn_icon} /> edit
              </Link>
              <button
                type="button"
                className={`btn_white_full_width spaced ${styles.menu_btn}`}
                onClick={() =>
                  copyToCB(`wordsbooster://panda/onboarding/${id}`)
                }
              >
                <Pencil className={styles.btn_icon} /> copy deeplink
              </button>

              <button
                type="button"
                className={`btn_white_full_width spaced ${styles.menu_btn}`}
                onClick={() =>
                  copyToCB(
                    `https://dl32d1y8jav6c.cloudfront.net/${currentAppId}/${id}.html`
                  )
                }
              >
                <Pencil className={styles.btn_icon} /> copy web url
              </button>
            </div>
          </div>
        )}
        <div className={styles.iframe_wrapper}>
          <iframe
            className={styles.preview_iframe}
            scrolling="no"
            title={name}
            src={`data:text/html;charset=utf-8,${encodeURIComponent(
              screenHtml
            )}`}
          />
        </div>
      </div>
      {copySuccess && (
        <div className={styles.copied_message}>copied to clipboard</div>
      )}
    </div>
  );
};

export default ScreenPreview;
