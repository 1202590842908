import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppUser, PageState } from '_types';
import Pagination from 'modules/Pagination/Pagination';
import SortTrigger from 'modules/Filter/SortTrigger';
import AppUserRow from './AppUserRow';
import AppUsersFilter from './AppUsersFilter';

interface Props extends RouteComponentProps {
  getUsers: Function;
  resetUsers: Function;
  users: Array<AppUser>;
  pageData: PageState;
  currentAppId: string;
}

class AppUsersList extends React.Component<any, Props> {
  componentDidMount(): void {
    const { history, currentAppId, getUsers } = this.props;
    const search = history && history.location && history.location.search;

    const queryParams = new URLSearchParams(search);

    if (currentAppId) {
      getUsers(currentAppId, queryParams);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { history, getUsers, currentAppId, resetUsers } = this.props;
    const search = history && history.location && history.location.search;

    const prevSearch = prevProps.location && prevProps.location.search;
    const prevAppId = prevProps.currentAppId;

    const shouldRequest =
      (prevSearch !== search || prevAppId !== currentAppId) && currentAppId;

    if (shouldRequest) {
      const queryParams = new URLSearchParams(search);

      if (prevAppId !== currentAppId) {
        resetUsers();
      }
      getUsers(currentAppId, queryParams);
    }
  }

  componentWillUnmount(): void {
    const { resetUsers } = this.props;

    resetUsers();
  }

  render(): JSX.Element {
    const { users, pageData } = this.props;

    const { total, perPage } = pageData;

    return (
      <div>
        <AppUsersFilter />
        <div className="table_scroll_wrapper">
          <table className="real_table_wrapper">
            <thead className="table_head">
              <tr className="table_head">
                <th>
                  <SortTrigger label="User Id" sortBy="user_id" />
                </th>
                <th>
                  <SortTrigger label="Custom User Id" sortBy="custom_user_id" />
                </th>
                <th>
                  <SortTrigger label="Appsflyer Id" sortBy="appsflyer_id" />
                </th>
                <th>
                  <SortTrigger label="email" sortBy="email" />
                </th>
                <th>
                  <SortTrigger label="Country" sortBy="country" />
                </th>
                <th>
                  <SortTrigger label="Platform" sortBy="platform" />
                </th>
                <th>
                  <SortTrigger label="OS Version" sortBy="os_version" />
                </th>
                <th>
                  <SortTrigger label="Device Family" sortBy="device_family" />
                </th>
                <th>
                  <SortTrigger label="Device model" sortBy="device_model" />
                </th>
                <th>
                  <SortTrigger label="Device Id" sortBy="device_id" />
                </th>
                <th>
                  <SortTrigger label="App Version" sortBy="app_version" />
                </th>
                <th>
                  <SortTrigger label="IDFA" sortBy="idfa" />
                </th>
                <th>
                  <SortTrigger label="IDFV" sortBy="idfv" />
                </th>
                <th>
                  <SortTrigger label="Ip" sortBy="ip" />
                </th>
                <th>
                  <SortTrigger label="Language" sortBy="language" />
                </th>
                <th>
                  <SortTrigger label="Locale" sortBy="locale" />
                </th>
                <th>
                  <SortTrigger label="Payload" sortBy="payload" />
                </th>
                <th>
                  <SortTrigger
                    label="Start App Version"
                    sortBy="start_app_version"
                  />
                </th>
                <th>
                  <SortTrigger label="Timezone" sortBy="timezone" />
                </th>
                <th>
                  <SortTrigger
                    label="Push Notifications Token"
                    sortBy="push_notifications_token"
                  />
                </th>
              </tr>
            </thead>

            <tbody className="table_content">
              {Array.isArray(users) && users.length > 0 ? (
                users.map((user: AppUser) => {
                  return <AppUserRow key={user.userId} user={user} />;
                })
              ) : (
                <tr>
                  <td>Users will be here</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination total={total} perPage={perPage} />
      </div>
    );
  }
}

export default AppUsersList;
