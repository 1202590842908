import React from 'react';
import { Link, Route } from 'react-router-dom';
import Logo from 'icons/Logo';
import styles from './sidebar.module.scss';
import MainNav from './MainNav';
import AccountNav from './AccountNav';

const mainNavPaths = [
  '/screens',
  '/app/settings',
  '/feedbacks',
  '/statistics',
  '/products',
];

const Sidebar: React.FC = () => (
  <aside className={styles.sidebar}>
    <Link to="/">
      <Logo className={styles.logo} />
    </Link>
    <Route exact path="/" component={MainNav} />
    <Route path={mainNavPaths} component={MainNav} />
    <Route path="/account" component={AccountNav} />
  </aside>
);

export default Sidebar;
