import React, { useEffect, useState, useRef } from 'react';
import Select, { ValueType, ActionMeta } from 'react-select';
import { JsonEditor } from 'modules/JsonEditor/JsonEditor';

import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { BillingPlan, OptionType } from '_types';
import { paymentModeOptions } from '_config/constants';
import selectStyles from 'styles/selectStyles';
import styles from '../appPage.module.scss';

const INITIAL_STATE = {
  amount: 0,
  appId: '',
  billingPeriod: '',
  billingPeriodInDays: 0,
  firstPayment: 0,
  payPalId: '',
  paymentCredentialId: '',
  paymentMode: { value: 'onetime', label: 'onetime' },
  productId: '',
  secondPayment: 0,
  solidId: '',
  stripeId: '',
  subscriptionType: '',
  trialDescription: '',
  purchaseEmailId: '',
  currency: 'USD',
  hasTrial: false,
  durationTrialInDays: 0,
  durationTrial: '',
  purchaseValue: {},
};

interface Props {
  plan: BillingPlan;
  handleSavePlan: Function;
  handleDelete: Function;
}

const BillingPlanForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const { plan, handleSavePlan, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      id,
      amount,
      billingPeriod,
      billingPeriodInDays,
      firstPayment,
      payPalId,
      paymentCredentialId,
      paymentMode,
      productId,
      secondPayment,
      solidId,
      stripeId,
      subscriptionType,
      trialDescription,
      purchaseEmailId,
      currency,
      hasTrial,
      durationTrialInDays = '',
      durationTrial = '',
      purchaseValue,
    } = plan;

    const selectedMode =
      paymentModeOptions.find((option) => option.value === paymentMode) ||
      INITIAL_STATE.paymentMode;

    let pruchaseValueObj = {};
    try {
      pruchaseValueObj = JSON.parse(purchaseValue as string);
    } catch {
      pruchaseValueObj = {};
    }

    dispatch({
      id,
      amount,
      billingPeriod,
      billingPeriodInDays,
      firstPayment,
      payPalId,
      paymentCredentialId,
      paymentMode: selectedMode,
      productId,
      secondPayment,
      solidId,
      stripeId,
      subscriptionType,
      trialDescription,
      purchaseEmailId,
      currency,
      hasTrial,
      durationTrialInDays,
      durationTrial,
      purchaseValue: pruchaseValueObj,
    });
  }, [plan, dispatch]);

  const jsonEditorRef = useRef();

  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current !== undefined &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      jsonEditorRef.current.jsonEditor;
    if (editor && formState.purchaseValue) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      editor.update(formState.purchaseValue);
    }
  }, [jsonEditorRef, formState.purchaseValue]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const handleTrialCheckboxChange = (): void => {
    dispatch({ hasTrial: !formState.hasTrial });
  };

  const copyPlanId = (): void => {
    if (plan.id) {
      copyToClipboard(plan.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No plan id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleJsonChange = (e: string | object): void => {
    dispatch({ purchaseValue: e });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSavePlan({ ...formState, tempId: plan.tempId });
  };

  const deletePlan = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (plan.id) {
      handleDelete({ id: plan.id });
    } else {
      handleDelete({ tempId: plan.tempId });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.flex_container}>
        <div>
          {plan.id && (
            <label htmlFor="id" className={formStyles.row}>
              <div className="label">Plan id</div>
              <div className="input_w_icon_wrapper">
                <input
                  type="text"
                  name="id"
                  className="input_w_icon"
                  value={plan.id}
                  readOnly
                />
                <button
                  className="input_icn_btn"
                  type="button"
                  onClick={copyPlanId}
                >
                  <CopyIcon className="input_icon" />
                </button>
                {copySuccess === 'id' && (
                  <div className={styles.tooltip}>Copied</div>
                )}
              </div>
            </label>
          )}

          <label htmlFor="productId" className={formStyles.row}>
            <div className="label_row">
              <div>Product Id</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="productId"
              className="input"
              value={formState.productId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="amount" className={formStyles.row}>
            <div className="label_row">
              <div>Amount</div>
            </div>
            <input
              type="text"
              name="amount"
              className="input"
              value={formState.amount}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="currency" className={formStyles.row}>
            <div className="label_row">
              <div>Currency</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="currency"
              className="input"
              value={formState.currency}
              onChange={handleInputChange}
            />
          </label>

          <div className={formStyles.row}>
            <div className="label_row">Payment Mode</div>
          </div>
          <Select
            options={paymentModeOptions}
            styles={selectStyles}
            name="paymentMode"
            value={formState.paymentMode}
            isSearchable={false}
            onChange={handleSelect}
          />

          <label htmlFor="solidId" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Id</div>
            </div>
            <input
              type="text"
              name="solidId"
              className="input"
              value={formState.solidId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="billingPeriod" className={formStyles.row}>
            <div className="label_row">
              <div>Billing Period</div>
            </div>
            <input
              type="text"
              name="billingPeriod"
              className="input"
              value={formState.billingPeriod}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="billingPeriodInDays" className={formStyles.row}>
            <div className="label_row">
              <div>Billing Period in days</div>
            </div>
            <input
              type="text"
              name="billingPeriodInDays"
              className="input"
              value={formState.billingPeriodInDays}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="firstPayment" className={formStyles.row}>
            <div className="label_row">
              <div>First Payment</div>
            </div>
            <input
              type="text"
              name="firstPayment"
              className="input"
              value={formState.firstPayment}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="secondPayment" className={formStyles.row}>
            <div className="label_row">
              <div>Second Payment</div>
            </div>
            <input
              type="text"
              name="secondPayment"
              className="input"
              value={formState.secondPayment}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="paymentCredentialId" className={formStyles.row}>
            <div className="label_row">
              <div>Payment Credential Id</div>
              <div className={formStyles.small_text}>Required</div>
            </div>
            <input
              type="text"
              name="paymentCredentialId"
              className="input"
              value={formState.paymentCredentialId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="payPalId" className={formStyles.row}>
            <div className="label_row">
              <div>Paypal Id</div>
            </div>
            <input
              type="text"
              name="payPalId"
              className="input"
              value={formState.payPalId}
              onChange={handleInputChange}
            />
          </label>
        </div>

        <div>
          <label htmlFor="stripeId" className={formStyles.row}>
            <div className="label_row">
              <div>Stripe Id</div>
            </div>
            <input
              type="text"
              name="stripeId"
              className="input"
              value={formState.stripeId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="subscriptionType" className={formStyles.row}>
            <div className="label_row">
              <div>Subscription Type</div>
            </div>
            <input
              type="text"
              name="subscriptionType"
              className="input"
              value={formState.subscriptionType}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="trialDescription" className={formStyles.row}>
            <div className="label_row">
              <div>Trial Description</div>
            </div>
            <input
              type="text"
              name="trialDescription"
              className="input"
              value={formState.trialDescription}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="purchaseEmailId" className={formStyles.row}>
            <div className="label_row">
              <div>Purchase Email Id</div>
            </div>
            <input
              type="text"
              name="purchaseEmailId"
              className="input"
              value={formState.purchaseEmailId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="hasTrial" className={formStyles.row}>
            <div className="label_row">
              <div>Has trial</div>
            </div>
            <input
              type="checkbox"
              name="hasTrial"
              className="input_w_icon"
              checked={formState.hasTrial || false}
              onChange={handleTrialCheckboxChange}
            />
          </label>

          <label htmlFor="durationTrialInDays" className={formStyles.row}>
            <div className="label_row">
              <div>Duration Trial In Days</div>
            </div>
            <input
              type="text"
              name="durationTrialInDays"
              className="input"
              value={formState.durationTrialInDays}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="durationTrial" className={formStyles.row}>
            <div className="label_row">
              <div> Duration Trial</div>
            </div>
            <input
              type="text"
              name="durationTrial"
              className="input"
              value={formState.durationTrial}
              onChange={handleInputChange}
            />
          </label>

          <div className={formStyles.row}>
            <div className="label_row">
              <div>Purchase Value</div>
            </div>
            <JsonEditor
              value={formState.purchaseValue}
              statusBar={false}
              navigationBar={false}
              onChange={handleJsonChange}
              ref={jsonEditorRef}
              mode="text"
            />
            {/* <div id={formState.id} /> */}
          </div>
        </div>
      </div>

      <div className={formStyles.btns_box}>
        {error && <div className={formStyles.error_message}>{error}</div>}

        <div className={formStyles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Save
          </button>
        </div>
        <div className={formStyles.row}>
          <button
            type="button"
            className="btn_white_bordered"
            onClick={deletePlan}
          >
            Delete
          </button>
        </div>
      </div>
    </form>
  );
};

export default BillingPlanForm;
