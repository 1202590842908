import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import AccountSettings from './AccountSettings/AccountSettingsContainer';
import BusinessSettings from './BusinessSettings/BusinessSettingsContainer';

const Account: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { match } = props;
  const path = match && match.path;

  return (
    <div className="content">
      <Switch>
        <Route path={`${path}/settings`} component={AccountSettings} />
        <Route
          path={`${path}/business/:businessId`}
          component={BusinessSettings}
        />
      </Switch>
    </div>
  );
};

export default Account;
