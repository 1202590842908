import React from 'react';
import { AppUser } from '_types';

interface Props {
  user: AppUser;
}

const AppUserRow: React.FC<Props> = (props: Props) => {
  const { user } = props;
  if (!user) return null;

  const {
    appVersion,
    appsflyerId,
    country,
    customUserId,
    deviceFamily,
    deviceId,
    deviceModel,
    email,
    idfa,
    idfv,
    ip,
    language,
    locale,
    osVersion,
    payload,
    platform,
    pushNotificationsToken,
    startAppVersion,
    timezone,
    userId,
  } = user;

  return (
    <tr>
      <td>{userId}</td>
      <td>{customUserId}</td>
      <td>{appsflyerId}</td>
      <td>{email}</td>
      <td>{country}</td>
      <td>{platform}</td>
      <td>{osVersion}</td>
      <td>{deviceFamily}</td>
      <td>{deviceModel}</td>
      <td>{deviceId}</td>
      <td>{appVersion}</td>
      <td>{idfa}</td>
      <td>{idfv}</td>
      <td>{ip}</td>
      <td>{language}</td>
      <td>{locale}</td>
      <td>{payload}</td>
      <td>{startAppVersion}</td>
      <td>{timezone}</td>
      <td>{pushNotificationsToken}</td>
    </tr>
  );
};

export default AppUserRow;
