import React from 'react';
import styles from './filter.module.scss';

interface Props {
  name: string;
  value: string;
  label: string;
  cancelFilter: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const CancelFilter: React.FC<Props> = (props: Props) => {
  const { name, value, label, cancelFilter } = props;

  return (
    <div className={styles.selected_filter}>
      <div className={styles.row}>
        <button
          type="button"
          className={styles.cancel_icon}
          onClick={cancelFilter}
          data-name={name}
          aria-label="cancel"
        />
        {label}={value}
      </div>
    </div>
  );
};

export default CancelFilter;
