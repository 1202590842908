import React from 'react';
import Calendar from 'react-calendar';

import DropDown from 'modules/shared/DropDown';
import { ReactComponent as Arrow } from 'assets/svg/Arrow.svg';
import styles from './filter.module.scss';
import 'styles/calendar.scss';

interface Props {
  value: Array<Date> | null;
  onChange: (date: Date | Date[]) => void;
}

const DateFilter: React.FC<Props> = (props: Props) => {
  const { value, onChange } = props;

  return (
    <DropDown closeOnClickOutside>
      {(open: boolean, toggleOpen: () => void) => (
        <div className={styles.menu_item_wrapper}>
          <button
            type="button"
            className={styles.menu_item_w_arrow}
            onClick={toggleOpen}
          >
            Date Range
          </button>

          {open && (
            <div className={styles.calendar_wrapper}>
              <Calendar
                next2Label={null}
                prev2Label={null}
                nextLabel={<Arrow className="calendar_arrow_right" />}
                prevLabel={<Arrow className="calendar_arrow_left" />}
                value={value}
                onChange={onChange}
                selectRange
              />
            </div>
          )}
        </div>
      )}
    </DropDown>
  );
};

export default DateFilter;
