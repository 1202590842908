import { combineReducers } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';
import { Action } from '_types';

const currentBusinessReducer = (state = '', action: Action): string => {
  switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_BUSINESS:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return '';

    default:
      return state;
  }
};

const currentAppReducer = (state = '', action: Action): string => {
  switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_APP:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return '';

    default:
      return state;
  }
};

export default combineReducers({
  currentBusinessId: currentBusinessReducer,
  currentAppId: currentAppReducer,
});
