import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import { MAX_HTML_FILE_SIZE } from '_config/constants';
import UploadIcon from 'icons/Upload';
import { useFormState } from 'utils/customHooks';
import DropDown from 'modules/shared/DropDown';
import { Screen } from '_types';
import formStyles from 'styles/forms.module.scss';
import EditPreview from './EditPreview';
import styles from '../screens.module.scss';

interface Params {
  id: string;
}

const INITIAL_STATE = {
  name: '',
  screenHtml: '',
  fileName: '',
  type: '',
  defaultScreen: false,
};

interface Props extends RouteComponentProps<Params, any, any> {
  getScreens: Function;
  appScreens: Array<Screen>;
  currentAppId: string;
  resetScreens: Function;
  requestApi: Function;
}

const EditScreen: React.FC<Props> = (props: Props) => {
  const {
    getScreens,
    appScreens,
    currentAppId,
    resetScreens,
    match,
    requestApi,
  } = props;

  const id = match.params && match.params.id;

  const [error, setError] = useState<string>('');
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  // const [screen, setScreen] = useState<Partial<Screen>>({});

  // get screens from api
  useEffect(() => {
    getScreens(currentAppId);

    return () => {
      resetScreens();
    };
  }, [getScreens, currentAppId, resetScreens]);

  // set current screen
  useEffect(() => {
    const currentScreen = appScreens.find((scrn) => scrn.id === id);
    if (currentScreen) {
      const { name, type, default: defaultScreen, screenHtml } = currentScreen;
      dispatch({ name, type, defaultScreen, screenHtml });
    } else {
      dispatch(INITIAL_STATE);
    }
  }, [id, appScreens, dispatch]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const toggleCheckbox = (): void => {
    dispatch({
      defaultScreen: !formState.defaultScreen,
    });
  };

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget;
    if (!files || files.length === 0) return;
    const file = files[0];

    if (file.type !== 'text/html') {
      setError('Not accepted file type');
      return;
    }

    if (file.size > MAX_HTML_FILE_SIZE) {
      setError('Max file size 500 Kb.');
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      const result = evt && evt.target && evt.target.result;
      if (result) {
        dispatch({
          screenHtml: result,
          fileName: file.name,
        });
      }
    };

    setError('');
  };

  const handleSuccess = (): void => {
    const { history } = props;
    history.push('/screens');
  };

  const handleError = (err?: string): void => {
    const errorText = typeof err === 'string' ? err : 'Something went wrong';
    setError(errorText);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const { name, screenHtml, type, defaultScreen } = formState;

    const updatedScreen: Record<string, any> = {
      screen_id: id,
      name,
      type,
      default: defaultScreen,
    };

    if (screenHtml) {
      updatedScreen.screen_html = screenHtml;
    }

    requestApi({
      url: `/v1/screens/${id}`,
      method: 'PUT',
      body: updatedScreen,
      onSuccess: handleSuccess,
      onFailure: handleError,
    });
  };

  if (!currentAppId) {
    return <div className="content">App not selected</div>;
  }

  if (!id) {
    return <div className="content">Screen id not selected</div>;
  }

  return (
    <div className={styles.edit_screen_wrapper}>
      <aside className={styles.side_bar}>
        <div className={`title_row ${styles.side_bar_title_box}`}>
          <Link to="/screens" className="back_btn" />
          <h1 className="title">Edit screen</h1>
        </div>
        <DropDown closeOnClickOutside={false} defaultOpen>
          {(open: boolean, toggleOpen: () => void) => (
            <div>
              <button
                type="button"
                onClick={toggleOpen}
                className={
                  open ? styles.toggler_active : styles.dropdown_toggler
                }
              >
                <span className={styles.dropdown_arrow} />
                <span className={styles.toggler_text}>General</span>
              </button>

              {open && (
                <form
                  onSubmit={handleSubmit}
                  className={styles.side_bar_content}
                >
                  <label htmlFor="name" className={formStyles.row}>
                    <div className="label_row">
                      <div>Screen name</div>
                      <div className={formStyles.small_text}>Required</div>
                    </div>
                    <input
                      type="text"
                      name="name"
                      className="input"
                      value={formState.name}
                      onChange={handleInputChange}
                    />
                  </label>

                  <label htmlFor="type" className={formStyles.row}>
                    <div className="label">Type</div>
                    <input
                      type="text"
                      name="type"
                      className="input"
                      value={formState.type}
                      onChange={handleInputChange}
                      placeholder="sales, promo, survey, feedback"
                    />
                  </label>

                  <label htmlFor="default" className={formStyles.row}>
                    <div className="label">Use as default screen</div>
                    <input
                      type="checkbox"
                      name="defaultScreen"
                      checked={formState.defaultScreen}
                      onChange={toggleCheckbox}
                    />
                  </label>

                  <div className={formStyles.row}>
                    <div className="label">HTML file</div>
                    <div className={formStyles.file_row}>
                      <label htmlFor="logo" className={formStyles.file_btn}>
                        <UploadIcon className={formStyles.upload_icon} />
                        <div className={formStyles.upload_text}>Upload</div>
                        <input
                          type="file"
                          name="logo"
                          className={formStyles.file_input}
                          onChange={handleFileChange}
                        />
                      </label>

                      <div className={formStyles.file_name}>
                        {formState.fileName
                          ? formState.fileName
                          : 'No file chosen'}
                      </div>
                    </div>
                  </div>

                  {error && (
                    <div className={formStyles.error_message}>{error}</div>
                  )}

                  <div className={formStyles.submit_box}>
                    <button type="submit" className="btn_grn_full_width">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
        </DropDown>
      </aside>
      <div className={styles.edit_screen_content}>
        <EditPreview screenHtml={formState.screenHtml} />
      </div>
    </div>
  );
};

export default EditScreen;
