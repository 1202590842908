import { connect } from 'react-redux';
import { setCurrentApp } from 'modules/Session/sessionActions';
import { closeModal } from 'modules/Modal/modalActions';
import { RootState } from '_redux/store';
import { App } from '_types';
import { getBuisinessApps } from './appsSelector';
import AppsMenu from './AppsMenu';

interface State {
  apps: Array<App>;
  currentAppId: string;
}

const mSTP = (state: RootState): State => {
  const apps: App[] = getBuisinessApps(state);

  const { session } = state;
  const { currentAppId } = session;

  return {
    apps,
    currentAppId,
  };
};

const mDTP = { setCurrentApp, closeModal };

export default connect(mSTP, mDTP)(AppsMenu);
