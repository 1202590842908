import DropDown from 'modules/shared/DropDown';
import React from 'react';
import { BillingPlan } from '_types';
import styles from './billing_plan.module.scss';

interface Props {
  billingPlan: BillingPlan;
  handleStartEdit: Function;
  handleClonePlan: Function;
  handleTransferPlan: Function;
}

const BillingPlanRow: React.FC<Props> = (props: Props) => {
  const {
    billingPlan,
    handleStartEdit,
    handleClonePlan,
    handleTransferPlan,
  } = props;
  if (!billingPlan) return null;

  const {
    id,
    amount,
    billingPeriod,
    billingPeriodInDays,
    firstPayment,
    payPalId,
    paymentCredentialId,
    paymentMode,
    productId,
    secondPayment,
    solidId,
    stripeId,
    subscriptionType,
    trialDescription,
    purchaseEmailId,
    currency,
    hasTrial,
    durationTrialInDays,
    durationTrial,
    purchaseValue,
  } = billingPlan;

  const handleClickEdit = (): void => {
    handleStartEdit(id);
  };

  const handleClickClone = (): void => {
    const clonePlan = { ...billingPlan };
    delete clonePlan.id;

    handleClonePlan(clonePlan);
  };

  const handleClickTransferClone = (): void => {
    const clonePlan = { ...billingPlan };
    delete clonePlan.id;

    handleTransferPlan(clonePlan);
  };

  return (
    <tr>
      <td className="over_flow_visible sticky_col">
        <DropDown closeOnClickOutside>
          {(open: boolean, toggleOpen: () => void, closeMenu: () => void) => (
            <div className={styles.menu_wrapper}>
              <button
                className="plus_btn"
                type="button"
                onClick={toggleOpen}
                aria-label="open"
              />
              {open && (
                <ul className={styles.menu}>
                  <li className={styles.menu_item}>
                    <button
                      className="btn_grn_small_full_width"
                      type="button"
                      onClick={() => {
                        handleClickEdit();
                        closeMenu();
                      }}
                    >
                      Edit
                    </button>
                  </li>
                  <li className={styles.menu_item}>
                    <button
                      className="btn_grn_small_full_width"
                      type="button"
                      onClick={() => {
                        handleClickClone();
                        closeMenu();
                      }}
                    >
                      Clone
                    </button>
                  </li>
                  <li className={styles.menu_item}>
                    <button
                      className="btn_grn_small_full_width"
                      type="button"
                      onClick={() => {
                        handleClickTransferClone();
                        closeMenu();
                      }}
                    >
                      Clone to other app
                    </button>
                  </li>
                </ul>
              )}
            </div>
          )}
        </DropDown>
      </td>
      <td>{id}</td>
      <td>{productId}</td>
      <td>{amount}</td>
      <td>{currency}</td>
      <td>{paymentMode}</td>
      <td>{solidId}</td>
      <td>{billingPeriod}</td>
      <td>{billingPeriodInDays}</td>
      <td>{firstPayment}</td>
      <td>{secondPayment}</td>
      <td>{paymentCredentialId}</td>
      <td>{payPalId}</td>
      <td>{stripeId}</td>
      <td>{subscriptionType}</td>
      <td>{trialDescription}</td>
      <td>{purchaseEmailId}</td>
      <td>{hasTrial ? 'yes' : 'no'}</td>
      <td>{durationTrialInDays}</td>
      <td>{durationTrial}</td>
      <td>{purchaseValue}</td>
    </tr>
  );
};

export default BillingPlanRow;
