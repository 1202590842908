import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './sidebar.module.scss';

const MainNav: React.FC = () => (
  <nav>
    <ul>
      <li>
        <NavLink
          to="/"
          exact
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Dashboard
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/statistics"
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Statistics
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/screens?sort_by=created_at&sort_type=desc"
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Screens
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/feedbacks"
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Feedbacks
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/products"
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Products
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/app/settings"
          className={styles.navlink}
          activeClassName={styles.navlink_active}
        >
          Settings
        </NavLink>
      </li>
    </ul>
  </nav>
);

export default MainNav;
