import { Dispatch } from 'redux';
import { Action, App, Business } from '_types';
import ACTION_TYPES from '_redux/actionTypes';
import history from 'utils/history';

export const setCurrentApp = (appId: string): Action => ({
  type: ACTION_TYPES.SET_CURRENT_APP,
  payload: appId,
});

export const setCurrentBusiness = (businessId: string) => (
  dispatch: Dispatch,
  getState: Function
): void => {
  dispatch({
    type: ACTION_TYPES.SET_CURRENT_BUSINESS,
    payload: businessId,
  });
  const { businesses } = getState();
  const selectedBusiness = businesses.find(
    (business: Business) => business.id === businessId
  );
  const firstAppId =
    selectedBusiness &&
    selectedBusiness.apps &&
    selectedBusiness.apps[0] &&
    selectedBusiness.apps[0].id;
  if (firstAppId) {
    dispatch(setCurrentApp(firstAppId));
  } else {
    dispatch(setCurrentApp(''));
  }
};

export const defineCurrentBusinessAndApp = () => (
  dispatch: Dispatch,
  getState: Function
) => {
  const state = getState();

  const { session, businesses } = state;
  const { currentAppId, currentBusinessId } = session;

  if (businesses && businesses.length > 0) {
    if (currentAppId) {
      const appBusiness = businesses.find((business: Business) => {
        const businessApps = business.apps;
        return (
          businessApps &&
          businessApps.some((app: App) => app.id === currentAppId)
        );
      });
      if (!appBusiness) {
        const defaultBusiness = businesses[0];
        setCurrentBusiness(defaultBusiness.id)(dispatch, getState);
      } else if (appBusiness && appBusiness.id !== currentBusinessId) {
        dispatch({
          type: ACTION_TYPES.SET_CURRENT_BUSINESS,
          payload: appBusiness.id,
        });
      }
    } else if (!currentBusinessId) {
      const defaultBusiness = businesses[0];
      setCurrentBusiness(defaultBusiness.id)(dispatch, getState);
    }
    if (currentBusinessId && !currentAppId) {
      setCurrentBusiness(currentBusinessId)(dispatch, getState);
    }
  } else {
    dispatch({
      type: ACTION_TYPES.SET_CURRENT_BUSINESS,
      payload: '',
    });

    dispatch(setCurrentApp(''));

    history.push('/business/create');
  }
};
