import React from 'react';
import { FacebookCAPI } from '_types';

interface Props {
  facebookCAPI: FacebookCAPI;
  handleStartEdit: Function;
}

const FacebookCAPIRow: React.FC<Props> = (props: Props) => {
  const { facebookCAPI, handleStartEdit } = props;
  if (!facebookCAPI) return null;

  const {
    id,
    testEventCode,
    apiKey,
    pixelId,
    sourceUrl,
    prelandAppId,
  } = facebookCAPI;

  const handleClick = (): void => {
    handleStartEdit(id);
  };

  return (
    <tr>
      <td>
        <button className="btn_grn_small" type="button" onClick={handleClick}>
          Edit
        </button>
      </td>
      <td>{id}</td>
      <td>{testEventCode}</td>
      <td>{apiKey}</td>
      <td>{pixelId}</td>
      <td>{sourceUrl}</td>
      <td>{prelandAppId}</td>
    </tr>
  );
};

export default FacebookCAPIRow;
