import { RootState } from '_redux/store';
import { App } from '_types';

// eslint-disable-next-line import/prefer-default-export
export const getBuisinessApps = (state: RootState): App[] => {
  let apps: App[] = [];

  const { session, businesses } = state;
  const { currentAppId } = session;

  if (businesses && businesses.length > 0) {
    const currentBusiness = businesses.find((business) => {
      const businessApps = business.apps;
      return (
        businessApps && businessApps.some((app) => app.id === currentAppId)
      );
    });
    if (currentBusiness && Array.isArray(currentBusiness.apps)) {
      apps = currentBusiness.apps;
    }
  }

  return apps;
};
