import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterForm from 'modules/login/RegisterForm/RegisterFormContainer';
import LoginForm from 'modules/login/LoginForm/LoginFormContainer';
import ForgotPasswordForm from 'modules/login/ForgotPassword/ForgotPasswordFormContainer';
import ResetPasswordForm from 'modules/login/ResetPassword/ResetPasswordContainer';
import Cabinet from 'modules/Cabinet/CabinetContainer';
import ProtectedRoute from 'modules/shared/ProtectedRoute';
import LoaderView from 'modules/Loader/Loader';
import ModalDispatcher from 'modules/Modal/ModalContainer';

interface Props {
  authenticated: boolean;
}

const App: React.FC<Props> = (props: Props) => {
  const { authenticated } = props;

  return (
    <>
      <Switch>
        <Route path="/auth/signup" component={RegisterForm} />
        <Route path="/auth/login" component={LoginForm} />
        <Route path="/auth/forgot_password" component={ForgotPasswordForm} />
        <Route path="/auth/reset_password" component={ResetPasswordForm} />
        <ProtectedRoute
          path="/"
          authenticated={authenticated}
          component={Cabinet}
        />
        <Route render={() => <div>Not found</div>} />
      </Switch>
      <LoaderView />
      <ModalDispatcher />
    </>
  );
};

export default App;
