import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Business } from '_types';
import { RootState } from '_redux/store';
import { openModal } from 'modules/Modal/modalActions';
import BusinessSettings from './BusinessSettings';

interface Params {
  businessId: string;
}

interface Props extends RouteComponentProps<Params, any, any> {
  openModal: Function;
}

interface State {
  editableBusiness: Business | undefined;
}

const mSTP = (state: RootState, ownProps: Props): State => {
  const match = ownProps && ownProps.match;
  const businessId = match.params && match.params.businessId;

  const { businesses } = state;

  const editableBusiness = businesses.find(
    (business) => business.id === businessId
  );

  return {
    editableBusiness,
  };
};

const mDTP = { openModal };

export default connect(mSTP, mDTP)(BusinessSettings);
