import React, { useEffect, useState } from 'react';
import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { Product } from '_types';
import styles from '../AppPage/appPage.module.scss';

const INITIAL_STATE = {
  id: '',
  appSubscriptionId: 0,
  appId: '',
  referenceName: '',
  source: '',
  productId: '',
  revenue: 0,
  duration: '',
  durationInDays: 0,
  hasTrial: false,
  appStoreId: '',
  hasOffer: false,
  createdAt: '',
  offerRevenue: 0,
  offerDuration: '',
  offerDurationInDays: 0,
  consumable: false,
  trialDuration: '',
  trialDurationInDays: 0,
  priceId: 0,
  country: '',
  price: 0,
  outdatedAt: 0,
  priceCreatedAt: '',
  priceUpdatedAt: '',
};

interface Props {
  prod: Product;
  handleSavePlan: Function;
  handleDelete: Function;
}

const ProductsForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const { prod, handleSavePlan, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      id,
      appSubscriptionId,
      appId,
      source,
      price,
      productId,
      revenue,
      duration,
      durationInDays,
      hasTrial,
      appStoreId,
      hasOffer,
      createdAt,
      offerRevenue,
      offerDuration,
      offerDurationInDays,
      consumable,
      outdatedAt,
      country,
      trialDuration,
      trialDurationInDays,
    } = prod;

    dispatch({
      id,
      appSubscriptionId,
      appId,
      source,
      price,
      productId,
      revenue,
      duration,
      durationInDays,
      hasTrial,
      appStoreId,
      hasOffer,
      createdAt,
      offerRevenue,
      offerDuration,
      offerDurationInDays,
      consumable,
      outdatedAt,
      country,
      trialDuration,
      trialDurationInDays,
    });
  }, [prod, dispatch]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleTrialCheckboxChange = (): void => {
    dispatch({ hasTrial: !formState.hasTrial });
  };

  const handleOfferCheckboxChange = (): void => {
    dispatch({ hasOffer: !formState.hasOffer });
  };

  const handleConsumableCheckboxChange = (): void => {
    dispatch({ consumable: !formState.consumable });
  };

  const copyProductId = (): void => {
    if (prod.id) {
      copyToClipboard(prod.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No plan id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSavePlan({ ...formState, tempId: prod.tempId });
  };

  const deleteProduct = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (prod.id) {
      handleDelete({ id: prod.id });
    } else {
      handleDelete({ tempId: prod.tempId });
    }
  };

  return (
    <form className="form_wrapper_wide" onSubmit={handleSubmit}>
      {prod.appSubscriptionId && (
        <label htmlFor="appSubscriptionId" className={formStyles.row}>
          <div className="label">App Product id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="appSubscriptionId"
              className="input_w_icon"
              value={prod.appSubscriptionId}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyProductId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}

      <label htmlFor="productId" className={formStyles.row}>
        <div className="label_row">
          <div>Product Id</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="productId"
          className="input"
          value={formState.productId}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="referenceName" className={formStyles.row}>
        <div className="label_row">
          <div>Reference name</div>
        </div>
        <input
          type="text"
          name="referenceName"
          className="input"
          value={formState.referenceName}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="price" className={formStyles.row}>
        <div className="label_row">
          <div>Price</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="price"
          className="input"
          value={formState.price}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="country" className={formStyles.row}>
        <div className="label_row">
          <div>Country</div>
        </div>
        <input
          type="text"
          name="country"
          className="input"
          value={formState.country}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="duration" className={formStyles.row}>
        <div className="label_row">
          <div>Duration</div>
        </div>
        <input
          type="text"
          name="duration"
          className="input"
          value={formState.duration}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="Duration in days" className={formStyles.row}>
        <div className="label_row">
          <div>Duration in days</div>
        </div>
        <input
          type="text"
          name="durationInDays"
          className="input"
          value={formState.durationInDays}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="hasTrial" className={formStyles.row}>
        <div className="label_row">
          <div>Has trial</div>
        </div>
        <input
          type="checkbox"
          name="hasTrial"
          className="input_w_icon"
          checked={formState.hasTrial || false}
          onChange={handleTrialCheckboxChange}
        />
      </label>

      <label htmlFor="trialDuration" className={formStyles.row}>
        <div className="label_row">
          <div>Trial Duration</div>
        </div>
        <input
          type="text"
          name="trialDuration"
          className="input"
          value={formState.trialDuration}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="trialDurationInDays" className={formStyles.row}>
        <div className="label_row">
          <div>Trial Duration in Days</div>
        </div>
        <input
          type="text"
          name="trialDurationInDays"
          className="input"
          value={formState.trialDurationInDays}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="hasOffer" className={formStyles.row}>
        <div className="label_row">
          <div>Has offer</div>
        </div>
        <input
          type="checkbox"
          name="hasOffer"
          className="input_w_icon"
          checked={formState.hasOffer || false}
          onChange={handleOfferCheckboxChange}
        />
      </label>

      <label htmlFor="offerRevenue" className={formStyles.row}>
        <div className="label_row">
          <div>Offer revenue</div>
        </div>
        <input
          type="text"
          name="offerRevenue"
          className="input"
          value={formState.offerRevenue}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="offerDuration" className={formStyles.row}>
        <div className="label_row">
          <div>Offer duration</div>
        </div>
        <input
          type="text"
          name="offerDuration"
          className="input"
          value={formState.offerDuration}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="offerDurationInDays" className={formStyles.row}>
        <div className="label_row">
          <div>Offer duration in days</div>
        </div>
        <input
          type="text"
          name="offerDurationInDays"
          className="input"
          value={formState.offerDurationInDays}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="consumable" className={formStyles.row}>
        <div className="label_row">
          <div>Consumable</div>
        </div>
        <input
          type="checkbox"
          name="consumable"
          className="input_w_icon"
          checked={formState.consumable || false}
          onChange={handleConsumableCheckboxChange}
        />
      </label>

      <label htmlFor="revenue" className={formStyles.row}>
        <div className="label_row">
          <div>Revenue</div>
        </div>
        <input
          type="text"
          name="trialDurationInDays"
          className="input"
          value={formState.revenue}
          onChange={handleInputChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deleteProduct}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default ProductsForm;
