import React, { useReducer, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select, { ValueType, ActionMeta } from 'react-select';
import UploadIcon from 'icons/Upload';
import {
  MAX_IMAGE_FILE_SIZE,
  ACCEPTED_IMG_TYPES,
  MAX_IMG_WIDTH,
} from '_config/constants';
import { validateName } from 'utils/validation';
import getImgDimentions from 'utils/getImgDimentions';

import timeZones from '_config/timeZones.json';
import selectStyles from 'styles/selectStyles';
import { OptionType } from '_types';
import formStyles from 'styles/forms.module.scss';

interface State {
  title: string;
  timeZone: OptionType | string;
  logoName: string;
  logoUrl: string;
}

const INITIAL_STATE: State = {
  title: '',
  timeZone: '',
  logoName: '',
  logoUrl: '',
};

const stateReducer = (state: State, action: object): State => ({
  ...state,
  ...action,
});

interface Props extends RouteComponentProps {
  requestApi: Function;
  setCurrentBusiness: Function;
  getUserBusinesses: Function;
}

interface Zones {
  [key: string]: string;
}

const tempZones: Zones = { ...timeZones };

const zonesOptions = Object.keys(tempZones).map((zone) => ({
  value: zone,
  label: tempZones[zone],
}));

const { timeZone: userTimeZone } = Intl.DateTimeFormat().resolvedOptions();
const preselectedTimeZone = zonesOptions.find(
  (zone) => zone.value === userTimeZone
);

if (preselectedTimeZone) {
  INITIAL_STATE.timeZone = preselectedTimeZone;
}

const BusinessForm: React.FC<Props> = (props: Props) => {
  const { requestApi } = props;

  const [formState, dispatch] = useReducer(stateReducer, INITIAL_STATE);
  const [error, setError] = useState<string>('');

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleError = (err: any): void => {
    const message = (err && err.message) || 'Something went wrong';
    setError(message);
  };

  const handleSuccess = (data: { id: string }): void => {
    const { history, setCurrentBusiness, getUserBusinesses } = props;
    const id = data && data.id;
    if (id) {
      const callback = (): void => setCurrentBusiness(id);
      getUserBusinesses(callback);
      history.push('/apps/create');
    } else {
      handleError({ message: "Didn't get business id" });
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const { title, timeZone, logoUrl } = formState;

    const validName = validateName(title);
    if (!validName) {
      setError('Business name should be from 1 to 30 characters length');
      return;
    }

    const { value: timeZoneValue } = timeZone as OptionType;

    if (title.length && timeZone && timeZoneValue) {
      requestApi({
        url: '/v1/businesses',
        method: 'POST',
        body: {
          title,
          time_zone: timeZoneValue,
          logo: logoUrl,
        },
        onSuccess: handleSuccess,
        onFailure: handleError,
      });
    }
  };

  const handleFileChange = async (
    e: React.FormEvent<HTMLInputElement>
  ): Promise<any> => {
    const { files } = e.currentTarget;
    if (!files || files.length === 0) return;
    const file = files[0];
    if (!ACCEPTED_IMG_TYPES.includes(file.type)) {
      dispatch({ logoName: '', logoUrl: '' });
      setError('Not accepted file type');
      return;
    }

    const fileDimentions = await getImgDimentions(file);
    const imgWidth = fileDimentions && fileDimentions.width;
    const imgHeight = fileDimentions && fileDimentions.height;

    if (file.size > MAX_IMAGE_FILE_SIZE) {
      dispatch({ logoName: '', logoUrl: '' });
      setError('Max file size 1Mb.');
      return;
    }

    if (imgWidth > MAX_IMG_WIDTH || imgHeight > MAX_IMG_WIDTH) {
      dispatch({ logoName: '', logoUrl: '' });
      setError('File should be 1024x1024 px max.');
      return;
    }

    const handleUploadSuccess = (data: Record<string, any>): void => {
      const href = data && data.href;
      if (href) {
        dispatch({ logoName: file.name, logoUrl: href });
        setError('');
      }
    };

    const handleUploadFailed = (): void => {
      dispatch({ logoName: '', logoUrl: '' });
      setError('Failed to upload file');
    };

    // load file to S3
    const formData = new FormData();
    formData.append('file', file);

    requestApi({
      url: '/v1/businesses/logo',
      method: 'POST',
      withFile: true,
      body: formData,
      onSuccess: handleUploadSuccess,
      onFailure: handleUploadFailed,
    });
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  return (
    <section className={`form_background ${formStyles.background}`}>
      <div className={formStyles.content}>
        <form className="form_wrapper" onSubmit={handleSubmit}>
          <div className={formStyles.text_center}>
            <h1 className="sub_title">Business Creation</h1>
          </div>

          <label htmlFor="title" className={formStyles.row}>
            <div className="label">Company name</div>
            <input
              type="text"
              name="title"
              className="input"
              value={formState.title}
              onChange={handleInputChange}
            />
          </label>

          <div className={formStyles.row}>
            <div className="label">Company logo</div>
            <div className={formStyles.file_row}>
              <label htmlFor="logo" className={formStyles.file_btn}>
                <UploadIcon className={formStyles.upload_icon} />
                <div className={formStyles.upload_text}>Upload</div>
                <input
                  type="file"
                  name="logo"
                  className={formStyles.file_input}
                  onChange={handleFileChange}
                />
              </label>

              <div className={formStyles.file_name}>
                {formState.logoName ? formState.logoName : 'No file chosen'}
              </div>
            </div>
          </div>

          <div className={formStyles.row}>
            <div className="label">Default time zone</div>
            <Select
              options={zonesOptions}
              styles={selectStyles}
              name="timeZone"
              value={formState.timeZone as OptionType}
              onChange={handleSelect}
            />
          </div>

          {error && <div className={formStyles.error_message}>{error}</div>}

          <div className={formStyles.submit_box}>
            <button type="submit" className="btn_grn_full_width">
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default BusinessForm;
