import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import App from './App';

interface State {
  authenticated: boolean;
}

const mSTP = (state: RootState): State => ({
  authenticated: state.authenticated,
});

export default connect(mSTP)(App);
