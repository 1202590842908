import React, { Component } from 'react';

import { Country, RebillRules, RegionSelect } from '_types';
// import Multiselect from 'multiselect-react-dropdown';
import RebillRulesForm from './RebillRulesForm';
import styles from '../appPage.module.scss';
// TouchableOpacity
interface State {
  rebillRules: Array<RebillRules | null>;
  countries: Array<Country | null>;
  regions: Array<RegionSelect | null>;
  error: string;
}

interface Props {
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  rebillRules: [],
  countries: [],
  regions: [],
  error: '',
  isValidFieldCountry: false,
};

const INITIAL_RULE = {
  // id: 0,
  tempId: 0,
  appID: '',
  durationInDays: 0,
  greatThanDuration: false,
  lessThanDuration: false,
  durationBeforePay: 0,
  lessDurationInDays: 0,
  isOffer: false,
  isTrial: false,
  countries: [],
  regions: [],
};

export default class RebillRulesList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/countries`,
      method: 'GET',
      onSuccess: this.getCountriesSuccess,
      onFailure: this.getCountriesFailed,
    });

    setTimeout(() => {
      requestApi({
        url: `/v1/rebill?app_id=${currentAppId}`,
        method: 'GET',
        onSuccess: this.getRebillRulesSuccess,
        onFailure: this.getRebillRulesFailed,
      });
    }, 500);
  }

  componentDidUpdate(prevProps: Props): void {
    const { requestApi, currentAppId } = this.props;
    const { currentAppId: prevAppId } = prevProps;

    if (prevAppId !== currentAppId) {
      requestApi({
        url: `/v1/rebill?app_id=${currentAppId}`,
        method: 'GET',
        onSuccess: this.getRebillRulesSuccess,
        onFailure: this.getRebillRulesFailed,
      });
    }
  }

  getRebillRulesSuccess = (data: RebillRules[]): void => {
    const { currentAppId } = this.props;

    const rebillRulesToState = data.map((rebillRules) => {
      const {
        id,
        tempId,
        durationInDays,
        greatThanDuration,
        lessThanDuration,
        durationBeforePay,
        lessDurationInDays,
        isOffer,
        isTrial,
        countries,
        regions,
      } = rebillRules;

      return {
        id,
        tempId,
        appID: currentAppId,
        durationInDays,
        durationBeforePay,
        lessDurationInDays,
        greatThanDuration,
        lessThanDuration,
        isOffer,
        isTrial,
        countries,
        regions,
      };
    });

    this.setState({ rebillRules: rebillRulesToState });
  };

  getRebillRulesFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  getCountriesSuccess = (data: Country[]): void => {
    const coutnriesToState = data.map((countries) => {
      const { id, country, countryCode, region } = countries;

      return {
        id,
        country,
        countryCode,
        region,
      };
    });

    this.setState({ countries: coutnriesToState });
  };

  getCountriesFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  handleOpenRebillRulesForm = (): void => {
    this.setState((prevState) => ({
      rebillRules: [
        { ...INITIAL_RULE, tempId: new Date().getTime() },
        ...prevState.rebillRules,
      ],
    }));
  };

  saveRebillRulesSuccess = (newRebillRule: RebillRules): void => {
    this.setState((prevState) => {
      const newRebillRules = prevState.rebillRules.map((rebillRule) => {
        const match =
          (rebillRule && rebillRule.id && rebillRule.id === newRebillRule.id) ||
          (rebillRule &&
            rebillRule.tempId &&
            rebillRule.tempId === newRebillRule.tempId);
        if (match) {
          return newRebillRule;
        }

        return rebillRule;
      });

      return { rebillRules: newRebillRules };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSaveRebillRules = (rebillRules: RebillRules): void => {
    const { currentAppId, requestApi } = this.props;
    const {
      id,
      durationInDays,
      greatThanDuration,
      lessThanDuration,
      durationBeforePay,
      lessDurationInDays,
      isOffer,
      isTrial,
      countries,
      regions,
    } = rebillRules;

    const durationTrialNum = durationInDays
      ? parseInt(durationInDays.toString(), 10)
      : 0;

    const beforePayNum = durationBeforePay
      ? parseInt(durationBeforePay.toString(), 10)
      : 0;
    const lessDurationInDaysNum = lessDurationInDays
      ? parseInt(lessDurationInDays.toString(), 10)
      : 0;

    const body = {
      id,
      app_id: currentAppId,
      duration_in_days: durationTrialNum,
      great_than_duration: greatThanDuration,
      less_than_duration: lessThanDuration,
      less_duration_in_days: lessDurationInDaysNum,
      duration_before_pay: beforePayNum,
      before_pay: beforePayNum,
      is_offer: isOffer,
      is_trial: isTrial,
      countries,
      regions,
    };

    if (id) {
      requestApi({
        url: `/v1/rebill/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveRebillRulesSuccess(rebillRules),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/rebill`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveRebillRulesSuccess({ ...rebillRules, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  getRegion = (data: Country[], countries: number[]): RegionSelect[] => {
    const selectedCountry = data
      .map((c) => {
        const { id, country } = c;
        return {
          id,
          name: country,
        };
      })
      .filter((country) => {
        return countries.includes(country.id);
      });

    const val = data.filter((c: Country) => {
      return selectedCountry.some((el) => {
        return el.id === c.id;
      });
    });

    const regions = val
      .map((c) => {
        return c.region;
      })
      .flat(1)
      .map(
        (r): RegionSelect => {
          const { id, regionName } = r;
          return {
            id,
            name: regionName,
          };
        }
      );

    return regions;
  };

  getSelectedRegion = (
    data: Country[],
    rebillRules: RebillRules
  ): RegionSelect[] => {
    return this.getRegion(data, rebillRules.countries).filter((region) => {
      return rebillRules.regions.includes(region.id);
    });
  };

  deleteRebillRuleSuccess = (id: number): void => {
    this.setState((prevState) => ({
      rebillRules: prevState.rebillRules.filter(
        (rebillRule) =>
          rebillRule && rebillRule.id !== id && rebillRule.tempId !== id
      ),
    }));
  };

  handleDeleteRebillRule = ({
    id,
    tempId,
  }: {
    id?: number;
    tempId: number;
  }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/rebill/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deleteRebillRuleSuccess(id),
        onFailure: this.handleError,
      });
    } else if (tempId) {
      this.deleteRebillRuleSuccess(tempId);
    }
  };

  render(): JSX.Element {
    const { rebillRules, countries, error } = this.state;

    return (
      <div>
        <div className="title_row sub_row">
          <h2 className="bold label">Rebill rules</h2>
          <div className="title_btn_box">
            <button
              type="button"
              className="btn_create"
              onClick={this.handleOpenRebillRulesForm}
            >
              Add rebill rule
            </button>
          </div>
        </div>

        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.forms_container}>
          {rebillRules.map(
            (rebillRule: RebillRules) =>
              rebillRules && (
                <div
                  className={styles.form_wrapper}
                  key={rebillRule.id || rebillRule.tempId}
                >
                  <RebillRulesForm
                    rebillRule={rebillRule}
                    handleSaveRebillRules={this.handleSaveRebillRules}
                    handleDelete={this.handleDeleteRebillRule}
                    getRegion={this.getRegion}
                    getSelectedRegion={this.getSelectedRegion}
                    countriesArr={countries}
                  />
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}
