import { combineReducers } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Purchase, PageState } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

const purchasesReducer = (
  state: Array<Purchase> = [],
  action: Action
): Array<Purchase> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_PURCHASE:
      return action.payload && action.payload.purchases;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

const pageReducer = (
  pageState = INITIAL_PAGE_STATE,
  action: Action
): PageState => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_PURCHASE:
      return action.payload && action.payload.pages;

    case ACTION_TYPES.SIGN_OUT:
      return INITIAL_PAGE_STATE;

    default:
      return pageState;
  }
};

export default combineReducers({
  purchases: purchasesReducer,
  pages: pageReducer,
});
