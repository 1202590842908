import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { RouteComponentProps } from 'react-router-dom';
import config from 'env';
import { ReactComponent as ManImg } from 'assets/svg/Man.svg';
import { ReactComponent as GirlImg } from 'assets/svg/Girl_w_dog.svg';
import { ReactComponent as PeopleImg } from 'assets/svg/People.svg';
import { ModalNames, Business } from '_types';
import styles from './business_settings.module.scss';

const stripePromise = loadStripe(config.STRIPE_KEY);

interface Params {
  businessId: string;
}

interface Props extends RouteComponentProps<Params, any, any> {
  openModal: Function;
  editableBusiness: Business | undefined;
}

const BusinessSettings: React.FC<Props> = (props: Props) => {
  const { location, match, history, openModal, editableBusiness } = props;
  const businessId = match.params && match.params.businessId;

  const orderDetails = editableBusiness && editableBusiness.order;

  const defaultPlan = orderDetails && orderDetails.isDefaultPlan;

  const selectedPlan = orderDetails && orderDetails.paymentPlanId;

  // open modal after payment
  useEffect(() => {
    const search = location && location.search;
    const queryParams = new URLSearchParams(search);
    const state = queryParams.get('state');

    if (state === 'success_payment' || state === 'cancel_payment') {
      const modalToOpen =
        state === 'success_payment'
          ? ModalNames.SUCCESS_PAYMENT
          : ModalNames.CANCEL_PAYMENT;

      openModal(modalToOpen);
      queryParams.delete('state');
      const cleanQuery = queryParams.toString();

      history.push(`${location.pathname}?${cleanQuery}`);
    }
  });

  const handleOpenCheckout = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<any> => {
    const { price } = e.currentTarget && e.currentTarget.dataset;

    const stripe = await stripePromise;

    if (!stripe || !price) return;
    const DOMAIN = window.location.origin;
    const successUrl = `${DOMAIN}${location.pathname}?state=success_payment`;
    const cancelUrl = `${DOMAIN}${location.pathname}?state=cancel_payment`;

    const { error } = await stripe.redirectToCheckout({
      mode: 'subscription',
      lineItems: [{ price, quantity: 1 }],
      successUrl,
      cancelUrl,
      clientReferenceId: businessId,
    });

    if (error) {
      // show error message for user
    }
  };

  return (
    <div>
      <h1 className="title">Simple affordable pricing</h1>
      <p className={styles.description}>
        Pay as you grow. <span className="link">Learn more</span>
      </p>
      <div className={styles.plans_row}>
        <div className={styles.plan_wrapper}>
          <div className={styles.plan_header}>
            <div>
              <h5 className={styles.plan_title}>Free</h5>
              <div className={styles.plan_price}>
                <sup>$</sup>0<span>/mo</span>
              </div>
            </div>
            <ManImg className={styles.man_img} />
          </div>
          <div className={styles.plan_content}>
            <ul className={styles.plan_features}>
              <li>
                <span className={styles.checkmark} />
                Includes $10,000 MRR
              </li>
              <li>
                <span className={styles.checkmark} />
                edit texts in standard screens
              </li>
              <li>
                <span className={styles.checkmark} />
                enable default rules for screens displaying
              </li>
            </ul>
          </div>

          <div className={styles.btn_box}>
            {selectedPlan === config.PRICE_FREE || defaultPlan ? (
              <div className={styles.activated}>Activated</div>
            ) : null}
            {!defaultPlan && !selectedPlan ? (
              <button
                type="button"
                className="btn_white_bordered"
                data-price={config.PRICE_FREE}
                onClick={handleOpenCheckout}
              >
                Get started
              </button>
            ) : null}
          </div>
        </div>

        <div className={styles.plan_wrapper}>
          <div className={styles.plan_header}>
            <div>
              <h5 className={styles.plan_title}>Analyze</h5>
              <div className={styles.plan_price}>
                <sup>$</sup>199<span>/mo</span>
              </div>
            </div>
            <GirlImg className={styles.girl_img} />
          </div>
          <div className={styles.plan_content}>
            <ul className={styles.plan_features}>
              <li>
                <span className={styles.checkmark} />
                Includes $20,000 MRR
              </li>
              <li>
                <span className={styles.checkmark} />
                edit texts in standard screens
              </li>
              <li>
                <span className={styles.checkmark} />
                enable default rules for screens displaying
              </li>
            </ul>
            <div className={styles.divider} />
            <ul className={styles.plan_features}>
              <li>
                <span className={styles.checkmark} />
                upload 1 custom screen
              </li>
              <li>
                <span className={styles.checkmark} />
                add 1 custom rule for screens displaying
              </li>
            </ul>
          </div>

          <div className={styles.btn_box}>
            {selectedPlan === config.PRICE_ANALYZE ? (
              <div className={styles.activated}>Activated</div>
            ) : null}

            {!selectedPlan ? (
              <button
                type="button"
                className="btn_grn_full_width"
                data-price={config.PRICE_ANALYZE}
                onClick={handleOpenCheckout}
              >
                Choose this Plan
              </button>
            ) : null}
          </div>
        </div>

        <div className={styles.plan_wrapper}>
          <div className={styles.plan_header}>
            <div>
              <h5 className={styles.plan_title}>Grow</h5>
              <div className={styles.plan_price}>
                <sup>$</sup>499<span>/mo</span>
              </div>
            </div>
            <PeopleImg className={styles.people_img} />
          </div>
          <div className={styles.plan_content}>
            <ul className={styles.plan_features}>
              <li>
                <span className={styles.checkmark} />
                Includes $100,000 MRR
              </li>
              <li>
                <span className={styles.checkmark} />
                edit texts in standard screens
              </li>
              <li>
                <span className={styles.checkmark} />
                enable default rules for screens displaying
              </li>
            </ul>
            <div className={styles.divider} />
            <ul className={styles.plan_features}>
              <li>
                <span className={styles.checkmark} />
                upload unlimited custom screens
              </li>
              <li>
                <span className={styles.checkmark} />
                add unlimited custom rules for screens displaying
              </li>
            </ul>
          </div>

          <div className={styles.btn_box}>
            {selectedPlan === config.PRICE_GROW ? (
              <div className={styles.activated}>Activated</div>
            ) : null}

            {!selectedPlan ? (
              <button
                type="button"
                className="btn_grn_full_width"
                data-price={config.PRICE_GROW}
                onClick={handleOpenCheckout}
              >
                Choose this Plan
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSettings;
