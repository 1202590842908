import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Logo from 'icons/Logo';
import { useFormState } from 'utils/customHooks';
import styles from '../login.module.scss';

interface Props extends RouteComponentProps {
  requestForgotPassword: Function;
}

const INITIAL_STATE = {
  email: '',
  codeSent: false,
  error: '',
};

const ForgotPasswordForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value, codeSent: false });
  };

  const handleError = (err: string): void => {
    const errText = typeof err === 'string' ? err : 'Something went wrong';

    dispatch({ error: errText });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    const { email } = formState;
    const { requestForgotPassword, history } = props;
    dispatch({ error: '' });

    if (email.trim().length) {
      try {
        const data = await requestForgotPassword(email);
        if (data) {
          // dispatch({ codeSent: true });
          history.push({ pathname: '/auth/reset_password', state: { email } });
        }
      } catch (rej) {
        const err = typeof rej === 'string' ? rej : rej.message;
        handleError(err);
      }
    } else {
      dispatch({ error: 'Field shoul not be empty' });
    }
  };

  return (
    <section className="form_background">
      <div className={styles.logo_box}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <form className="form_wrapper" onSubmit={handleSubmit} autoComplete="on">
        <h1 className={`sub_title ${styles.text_center}`}>Forgot Password?</h1>
        <div className={`${styles.row} ${styles.small_text}`}>
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.
        </div>

        <label htmlFor="email" className={styles.row}>
          <div className="label">Email Address</div>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            value={formState.email}
            onChange={handleInputChange}
            autoComplete="email"
          />
        </label>

        {formState.error && (
          <div className={styles.error_message}>{formState.error}</div>
        )}

        {/* {formState.codeSent && (
          <div className={`${styles.row} ${styles.small_text}`}>
            Link for reset password has been sent to {formState.email} email
          </div>
        )} */}

        <div className={styles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Send
          </button>
        </div>

        <div
          className={`${styles.flex_row} ${styles.small_text} ${styles.text_center}`}
        >
          <Link to="/auth/login" className="link">
            Sign in
          </Link>
          <div>
            Don&apos;t have account?{' '}
            <Link to="/auth/signup" className="link">
              Sign up
            </Link>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ForgotPasswordForm;
