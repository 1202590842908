import { connect } from 'react-redux';
import { requestSignOut } from 'modules/login/loginActions';
import { setCurrentBusiness } from 'modules/Session/sessionActions';
import { RootState } from '_redux/store';
import UserMenu from './UserMenu';

interface State {
  businesses: Array<any>;
  currentBusinessId: string;
}

const mSTP = (state: RootState): State => ({
  businesses: state.businesses,
  currentBusinessId: state.session && state.session.currentBusinessId,
});

const mDTP = { requestSignOut, setCurrentBusiness };

export default connect(mSTP, mDTP)(UserMenu);
