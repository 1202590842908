import { ModalNames, Action } from '_types';
import ACTION_TYPES from '_redux/actionTypes';

export const openModal = (modalName: ModalNames): Action => ({
  type: ACTION_TYPES.OPEN_MODAL,
  payload: modalName,
});

export const closeModal = (): Action => ({
  type: ACTION_TYPES.CLOSE_MODAL,
});
