import React from 'react';
import { PurchaseAndroid } from '_types';
import { getDateWithSeconds } from 'utils/formatDate';
import { Link } from 'react-router-dom';

interface Props {
  purchase: PurchaseAndroid;
}

const PurchRowAndroid: React.FC<Props> = (props: Props) => {
  const { purchase } = props;
  if (!purchase) return null;

  const {
    statusId,
    isTrialPeriod,
    userId,
    customUserId,
    paymentState,
    price,
    priceCurrencyCode,
    productId,
    index,
    orderId,
    purchaseDate,
    expiresDate,
    createdAt,
    test,
    quantity,
    advId,
    userCancellationTime,
    cancelReason,
    appsflyerId,
  } = purchase;
  const expiresDateString = expiresDate ? getDateWithSeconds(expiresDate) : '-';
  const purchaseDateString = purchaseDate
    ? getDateWithSeconds(purchaseDate)
    : '-';
  const createdAtString = createdAt ? getDateWithSeconds(createdAt) : '-';

  const cancelDateString = userCancellationTime
    ? getDateWithSeconds(userCancellationTime)
    : '-';

  return (
    <tr>
      <td>{statusId}</td>
      <td>{isTrialPeriod ? 'yes' : 'no'}</td>
      <td>
        <Link to={`/statistics/users?user_id=${userId}`}>{userId}</Link>
      </td>
      <td>
        <Link to={`/statistics/users?custom_user_id=${customUserId}`}>
          {customUserId}
        </Link>
      </td>
      <td>{appsflyerId}</td>
      <td>{paymentState}</td>
      <td>
        {price} {priceCurrencyCode}
      </td>
      <td>{productId}</td>
      <td>{index}</td>
      <td>{orderId}</td>
      <td>{purchaseDateString}</td>
      <td>{expiresDateString}</td>
      <td>{createdAtString}</td>
      <td>{test ? 'yes' : 'no'}</td>
      <td>{quantity}</td>
      <td>{advId}</td>
      <td>{cancelDateString}</td>
      <td>{cancelReason}</td>
    </tr>
  );
};

export default PurchRowAndroid;
