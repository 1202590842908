const groupBy = (
  items: Array<any>,
  key: string
): Array<{ value: number; name: string }> => {
  const grouped: Record<string, any> = {};
  items.forEach((item) => {
    const group = item[key];
    const collection = grouped[group];
    if (!collection) {
      grouped[group] = [item];
    } else {
      collection.push(item);
    }
  });

  const result = Object.entries(grouped).map(
    ([groupName, groupValue]: any) => ({
      name: groupName,
      value: groupValue.length,
    })
  );

  return result;
};

export default groupBy;
