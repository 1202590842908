import React from 'react';
import { Link } from 'react-router-dom';
import { Feedback } from '_types';

interface Props {
  feedback: Feedback;
}

const FeedbackRow: React.FC<Props> = (props: Props) => {
  const { feedback } = props;
  if (!feedback) return null;

  const { screenId, userId, answer } = feedback;

  return (
    <div className="table_row table_item">
      <div className="table_cell_20">
        <Link to={`/screens/edit/${screenId}`}>{screenId}</Link>
      </div>
      <div className="table_cell_20">
        <Link to={`statistics/users?user_id=${userId}`}>{userId}</Link>
      </div>
      <div className="table_cell_60">{answer}</div>
    </div>
  );
};

export default FeedbackRow;
