import { combineReducers } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';
import { Action } from '_types';

const initialState = {
  isOpen: false,
  currentModal: null,
};

export const modalOpenReducer = (
  state = initialState.isOpen,
  action: Action
): boolean => {
  switch (action.type) {
    case ACTION_TYPES.CLOSE_MODAL:
      return false;

    case ACTION_TYPES.OPEN_MODAL:
      return true;

    default:
      return state;
  }
};

export const currentModalReducer = (
  state = initialState.currentModal,
  action: Action
): string | null => {
  switch (action.type) {
    case ACTION_TYPES.OPEN_MODAL:
      return action.payload;

    case ACTION_TYPES.CLOSE_MODAL:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  isOpen: modalOpenReducer,
  currentModal: currentModalReducer,
});
