import { Action } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';

const loaderReducer = (state = 0, action: Action): number => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      if (state < 0) return 1;
      return state + 1;

    case ACTION_TYPES.FINISH_LOADING:
      return state - 1;

    default:
      return state;
  }
};

export default loaderReducer;
