import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import { getStateFromLS, saveStateToLS } from 'utils/localStorage';

import authenticatedReducer from 'modules/login/loginReducer';
import loaderReducer from 'modules/Loader/loaderReducer';
import screensReducer from 'modules/Screens/screensReducer';
import businessesReducer from 'modules/BusinessPage/businessesReducer';
import sessionReducer from 'modules/Session/sessionReducer';
import feedbacksReducer from 'modules/Feedbacks/feedbacksReducer';
import surveysReducer from 'modules/Feedbacks/surveysReducer';
import modalReducer from 'modules/Modal/modalReducer';
import teamReducer from 'modules/Team/teamReducer';
import reportsReducer from 'modules/Reports/reportsReducer';
import purchasesReducer from 'modules/Purchases/purchasesReducer';
import appUsersReducer from 'modules/AppUsers/appUsersReducer';
import productsReducer from 'modules/Products/productsReducer';

import apiMiddleware from './middleware/apiMiddleware';

const enhancer = composeWithDevTools(applyMiddleware(thunk, apiMiddleware));

const rootReducer = combineReducers({
  authenticated: authenticatedReducer,
  isLoading: loaderReducer,
  appScreens: screensReducer,
  products: productsReducer,
  businesses: businessesReducer,
  session: sessionReducer,
  feedbacks: feedbacksReducer,
  surveys: surveysReducer,
  modal: modalReducer,
  team: teamReducer,
  reports: reportsReducer,
  purchasesData: purchasesReducer,
  appUsers: appUsersReducer,
});

const savedAuthenticated = getStateFromLS('authenticated');
const savedSession = getStateFromLS('session');

const preloadedState = {
  authenticated:
    (savedAuthenticated && savedAuthenticated.authenticated) || false,
  session: savedSession || {},
};

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, preloadedState, enhancer);

store.subscribe(
  throttle(() => {
    const { session } = store.getState();
    saveStateToLS(session, 'session');
  }, 1000)
);

export default store;
