export * from './select';

export interface Action {
  type: string;
  payload?: any;
}

export interface ApiConfig {
  url: string;
  method: string;
  body?: object;
  onSuccess?: Function;
  onFailure?: Function;
  onFinish?: Function;
}

export interface Screen {
  name: string;
  id: string;
  screenHtml: string;
  default: boolean;
  type: string;
}

export interface App {
  id: string;
  name: string;
  type: string;
  bundleId?: string;
  appStoreAppId?: string;
  appStoreSharedSecret?: string;
  sdkToken?: string;
  googlePlayPackageName?: string;
  androidCredentialJson?: string;
  topic?: string;
  turnOnCapi?: boolean;
  turnOnCapiPaymentsAfterTrialEvents?: boolean;
}

export interface Business {
  id: string;
  title: string;
  logo?: string;
  timeZone: string;
  apps: Array<App>;
  userStatus: string;
  order: {
    transactionId: string;
    subscription: string;
    isDefaultPlan: boolean;
    paymentPlanId: string;
  };
}

export interface User {
  email: string;
  password: string;
}

export interface Feedback {
  id: string;
  userId: string;
  screenId: string;
  answer: string;
}

export interface Survey {
  id: string;
  userId: string;
  answerId: string;
  screenId: string;
}

export enum ModalNames {
  FIND_APP_STORE_SECRET = 'FIND_APP_STORE_SECRET',
  SUCCESS_PAYMENT = 'SUCCESS_PAYMENT',
  CANCEL_PAYMENT = 'CANCEL_PAYMENT',
  FIND_GOOGLE_CREDENTIALS_JSON = 'FIND_GOOGLE_CREDENTIALS_JSON',
  APP_SELECTOR = 'APP_SELECTOR',
}

export interface Member {
  id: string;
  email: string;
  role: string;
}

export enum Role {
  OWNER = 'owner',
  ADWMIN = 'admin',
  DEVELOPER = 'developer',
}

export interface Report {
  id: string;
  date: string;
  trials: number;
  installQty: number;
  purchases: number;
  payments: number;
  rebills: number[];
}

export interface PurchaseIOS {
  createdAt: string;
  expiresDate: string;
  isInIntroOfferPeriod: boolean;
  isTrialPeriod: boolean;
  isUpgraded: boolean | null;
  originalPurchaseDate: string;
  originalTransactionId: number;
  productId: string;
  purchaseDate: string;
  quantity: number;
  receiptId: string;
  refundDate: string | null;
  refundReason: number | null;
  sandbox: boolean;
  transactionId: number;
  userId: string;
  customUserId: string;
  appsflyerId: string;
}

export interface PurchaseAndroid {
  statusId: string;
  isTrialPeriod: boolean;
  userId: string;
  customUserId: string;
  paymentState: number;
  price: number;
  priceCurrencyCode: string;
  productId: string;
  index: string;
  orderId: string;
  purchaseDate: string;
  expiresDate: string;
  createdAt: string;
  test: boolean;
  quantity: number;
  advId: string;
  userCancellationTime: string | null;
  cancelReason: number | null;
  appsflyerId: string;
}

export interface PurchaseWeb {
  id: string;
  userId: string;
  purchaseDate: string;
  price: number;
  status: string;
  orderStatus: string;
  orderId: string;
  productId: string;
  isTrialPeriod: boolean;
  email: string;
  transactionEmail: string;
  currency: string;
  cancelCode: string;
  paymentGate: string;
  subscriptionCancelMessage: string;
  transactionPurchaseDate: string;
}

export type Purchase = PurchaseIOS | PurchaseAndroid | PurchaseWeb;

export interface PageState {
  total: number;
  perPage: number;
}

export interface PaymentCredentials {
  id?: string;
  wallet: string;
  paymentGateway: string;
  solidConfig?: {
    merchant: string;
    secretKey: string;
    solidGateKey: string;
    solidGateMerchant: string;
    merchantSandbox?: string;
    secretKeySandbox?: string;
    solidGateKeySandbox?: string;
    solidGateMerchantSandbox?: string;
  };
  payPalConfig?: {
    apiBaseUrl: string;
    clientId: string;
    secretKey: string;
  };
}

export interface CredentialsState {
  id?: string;
  tempId?: string | number;
  wallet: string;
  paymentGateway: { value: string; label: string };
  merchant: string;
  secretKey: string;
  solidGateKey?: string;
  solidGateMerchant?: string;
  apiBaseUrl?: string;
  clientId?: string;
  merchantSandbox?: string;
  secretKeySandbox?: string;
  solidGateKeySandbox?: string;
  solidGateMerchantSandbox?: string;
}

export interface BillingPlan {
  id?: string;
  tempId?: string | number;
  amount: number;
  appId: string;
  billingPeriod: string;
  billingPeriodInDays: number;
  firstPayment: number;
  payPalId: string;
  paymentCredentialId: string;
  paymentMode: string | { value: string; label: string };
  productId: string;
  secondPayment: number;
  solidId: string;
  stripeId: string;
  subscriptionType: string;
  trialDescription: string;
  purchaseEmailId: number | string;
  currency: string;
  purchaseValue: object | string;
  hasTrial: boolean;
  durationTrialInDays: number;
  durationTrial: string;
}

export interface EmailCreds {
  clientId: string;
  clientToken: string;
  id?: string;
  tempId?: string | number;
  iterableApiKey: string;
  iterableApiKeySandbox: string;
}

export interface EmailTemplate {
  category?: string;
  letterType: string | { value: string; label: string };
  project: string;
  sendioCredId: number | string;
  templateId: string;
  title: string;
  id?: number;
  tempId?: string | number;
  allowedCountries: string;
}

export interface AppUser {
  appId: string;
  appVersion: string;
  appsflyerId: string;
  country: string;
  customUserId: string;
  deviceFamily: string;
  deviceId: string | number;
  deviceModel: string;
  email: string;
  idfa: string;
  idfv: string;
  ip: string;
  language: string;
  locale: string;
  osVersion: string;
  payload: string;
  platform: string;
  pushNotificationsToken: string;
  startAppVersion: string;
  timezone: string;
  userId: string;
}

export interface Product {
  id: string;
  appSubscriptionId: number;
  tempId?: string | number;
  appId: string;
  source: string;
  referenceName: string;
  productId: string;
  revenue: number;
  duration: string;
  durationInDays: number;
  hasTrial: boolean;
  appStoreId: string;
  hasOffer: boolean;
  createdAt: string;
  offerRevenue: number;
  offerDuration: string;
  offerDurationInDays: number;
  consumable: boolean;
  trialDuration: string;
  trialDurationInDays: number;
  priceId: number;
  country: string;
  price: number;
  outdatedAt: number;
  priceCreatedAt: string;
  priceUpdatedAt: string;
}

export interface SelectProductProperty {
  label: string;
  id: number;
}

export interface Price {
  id: {
    value: string;
    label: string;
  };
  country: string;
  price: number;
}

export interface FacebookCAPI {
  id: number;
  appID: string;
  tempId?: string | number;
  testEventCode: string;
  apiKey: string;
  pixelId: string;
  sourceUrl: string;
  prelandAppId: string;
}

export interface RebillRules {
  id?: number;
  tempId?: string | number;
  appID?: string;
  durationInDays: number;
  greatThanDuration: boolean;
  lessThanDuration: boolean;
  durationBeforePay: number;
  lessDurationInDays: number;
  isOffer: boolean;
  isTrial: boolean;
  countries: number[];
  regions: number[];
}

export interface Country {
  id: number;
  country: string;
  countryCode: string;
  region: Array<Region>;
}

export interface Region {
  id: number;
  region: string;
  regionName: string;
  countryId: number;
}

export interface CountrySelect {
  id: number;
  name: string;
}

export interface RegionSelect {
  id: number;
  name: string;
}
