import React from 'react';
import { useHistory } from 'react-router-dom';
import { Business } from '_types';
import Ceckmark from 'icons/Checkmark';
import { RECT_COLORS } from '_config/constants';
import styles from './userMenu.module.scss';

interface Props {
  business: Business;
  currentBusinessId: string;
  setCurrentBusiness: Function;
  index: number;
}

const BusinessItem: React.FC<Props> = (props: Props) => {
  const { business, currentBusinessId, setCurrentBusiness, index } = props;

  const history = useHistory();

  if (!business) return null;

  const handleClick = (): void => {
    setCurrentBusiness(business.id);
    history.push(history.location.pathname);
  };

  const checked = business.id === currentBusinessId;

  const logStyles = business.logo
    ? { backgroundImage: `url(${business.logo})` }
    : { backgroundColor: RECT_COLORS[index % RECT_COLORS.length] };
  return (
    <button type="button" className={styles.menu_item} onClick={handleClick}>
      <div className={styles.business_logo} style={logStyles} />
      <div>{business.title}</div>
      {checked && (
        <div className={styles.checkmark_wrapper}>
          <Ceckmark className={styles.checkmark} />
        </div>
      )}
    </button>
  );
};

export default BusinessItem;
