import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Member } from '_types';
import { getAppTeam, resetAppTeam, removeMember } from './teamActions';
import TeamPage from './TeamPage';

interface State {
  team: Member[];
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  team: state.team,
  currentAppId: state.session && state.session.currentAppId,
});

const mDTP = { getAppTeam, resetAppTeam, removeMember };

export default connect(mSTP, mDTP)(TeamPage);
