import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useClickOutside } from 'utils/customHooks';
import defaultAvatar from 'assets/images/avatar.jpg';
import { Business } from '_types';
import styles from './userMenu.module.scss';
import BusinessItem from './BusinessItem';

interface Props {
  requestSignOut: () => void;
  businesses: Array<Business>;
  currentBusinessId: string;
  setCurrentBusiness: Function;
}

const UserMenu: React.FC<Props> = (props: Props) => {
  const {
    requestSignOut,
    businesses,
    currentBusinessId,
    setCurrentBusiness,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const menuRef = useRef(null);

  const toggleMenu = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (): void => {
    if (open) setOpen(false);
  };

  const handleBusinessClick = (businessId: string): void => {
    setCurrentBusiness(businessId);
    closeMenu();
  };

  useClickOutside(open, menuRef, closeMenu);

  return (
    <div className={styles.menu_wrapper}>
      <button
        type="button"
        className={styles.avatar}
        aria-label="toggle user menu"
        onClick={toggleMenu}
        style={{ backgroundImage: `url(${defaultAvatar})` }}
      />
      {open && (
        <div className={styles.drop_down} ref={menuRef}>
          {businesses && businesses.length > 0 && (
            <div className={styles.menu_section}>
              {businesses.map((business, i) => (
                <BusinessItem
                  business={business}
                  currentBusinessId={currentBusinessId}
                  key={business.id}
                  setCurrentBusiness={handleBusinessClick}
                  index={i}
                />
              ))}
            </div>
          )}
          <div className={styles.menu_section}>
            <Link
              to="/account/settings"
              className={styles.menu_item}
              onClick={closeMenu}
            >
              Account Settings
            </Link>
          </div>
          <div className={styles.menu_section}>
            <button
              type="button"
              className={styles.menu_item}
              onClick={requestSignOut}
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
