import { useEffect, useReducer } from 'react';

export const useClickOutside = (
  open: boolean,
  ref: React.RefObject<HTMLElement>,
  handler: Function
): void => {
  useEffect(() => {
    const listener = (e: MouseEvent): void => {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      handler();
    };
    if (open) {
      document.addEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);
    }
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler, open]);
};

export const useFormState = <S>(
  initialSate: S
): { formState: S; dispatch: Function } => {
  const stateReducer = (state: S, action: object): S => ({
    ...state,
    ...action,
  });

  const [formState, dispatch] = useReducer(stateReducer, initialSate);

  return { formState, dispatch };
};
