export const getDotsDate = (date: string): string => {
  const dateObj = new Date(date);
  const dateFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
  });

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateFormat.formatToParts(dateObj);

  const dateString = `${day}.${month}.${year}`;

  return dateString;
};

export const getISODashDate = (date: string | Date): string => {
  const dateObj = new Date(date);
  const dateFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateFormat.formatToParts(dateObj);

  const dateString = `${year}-${month}-${day}`;

  return dateString;
};

export const getUTCISODate = (date: string | Date): string => {
  const dateObj = new Date(date);

  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth();
  const day = dateObj.getUTCDate();

  let hours: number | string = dateObj.getUTCHours();
  if (hours < 10) hours = `0${hours}`;

  let minutes: number | string = dateObj.getUTCMinutes();
  if (minutes < 10) minutes = `0${minutes}`;

  let seconds: number | string = dateObj.getUTCSeconds();
  if (seconds < 10) seconds = `0${seconds}`;

  return `${year}-${month + 1}-${day}T${hours}:${minutes}:${seconds}`;
};

export const getDateWithSeconds = (date: string): string => {
  const dateObj = new Date(date);
  const dateFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hours },
    ,
    { value: minutes },
    ,
    { value: seconds },
  ] = dateFormat.formatToParts(dateObj);

  const dateString = `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;

  return dateString;
};
