import React from 'react';
import Modal from 'react-modal';
import { Action } from '_types';
import appStoreImg from 'assets/images/Appstore_secret_manual.jpg';
import appStoreImg2 from 'assets/images/Appstore_secret_manual2.jpg';
import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  closeModal: () => Action;
}

const FindAppStoreSecretModal: React.FC<Props> = (props: Props) => {
  const { isOpen, closeModal } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal_content"
      overlayClassName="modal_overlay"
    >
      <div className={styles.close_btn_row}>
        <button type="button" className="close_btn" onClick={closeModal}>
          <div className="close_icon" />
        </button>
      </div>
      <h4 className="title">How to find app store shared secret?</h4>

      <h5 className={styles.sub_title}>
        Generating an App-Specific Shared Secret
      </h5>
      <ol className={styles.o_list}>
        <li>Log in to App Store Connect</li>
        <li>Navigate to &quot;My Apps&quot; and select your app</li>
        <li>
          Select &quot;Manage&quot; under the &quot;In-App Purchases&quot;
          section from the left side menu
        </li>
        <li>
          Select App-Specific Share Secret from the right side above your in-app
          products.
        </li>
        <li>Generate and copy your shared secret</li>
      </ol>
      <img src={appStoreImg} alt="" className={styles.image} />
      <img src={appStoreImg2} alt="" className={styles.image} />
    </Modal>
  );
};

export default FindAppStoreSecretModal;
