import React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import styles from './sidebar.module.scss';

const AccountNav: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { match } = props;
  const url = match && match.url;

  return (
    <div>
      <h5 className={`sub_title ${styles.nav_title}`}>Profile</h5>
      <nav>
        <ul>
          <li>
            <NavLink
              to={url}
              exact
              className={styles.navlink}
              activeClassName={styles.navlink_active}
            >
              General
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${url}/settings`}
              className={styles.navlink}
              activeClassName={styles.navlink_active}
            >
              Settings
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AccountNav;
