import React, { Component } from 'react';
import { EmailTemplate } from '_types';
import { letterTypeOptions } from '_config/constants';
import EmailTemplateForm from './EmailTemplateForm';
import styles from '../appPage.module.scss';

interface State {
  templates: Array<EmailTemplate | null>;
  error: string;
}

interface Props {
  requestApi: Function;
  sendioCredId: string | number;
}

const INITIAL_STATE = {
  templates: [],
  error: '',
  isValidFieldCountry: false,
};

const INITIAL_TEMPLATE = {
  letterType: { value: 'email_confirm', label: 'Email confirm' },
  project: '',
  sendioCredId: '',
  templateId: '',
  title: '',
  allowedCountries: '',
};

export default class EmailTemplatesList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { requestApi, sendioCredId } = this.props;
    if (sendioCredId) {
      requestApi({
        url: `/v1/sendio/template?id=${sendioCredId}`,
        method: 'GET',
        onSuccess: this.getTemplatesSuccess,
        onFailure: this.getTemplatesFailed,
      });
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { sendioCredId: prevId } = prevProps;
    const { requestApi, sendioCredId } = this.props;
    if (sendioCredId !== prevId) {
      requestApi({
        url: `/v1/sendio/template?id=${sendioCredId}`,
        method: 'GET',
        onSuccess: this.getTemplatesSuccess,
        onFailure: this.getTemplatesFailed,
      });
    }
  }

  getTemplatesSuccess = (data: EmailTemplate[]): void => {
    const templatesToState = data.map((template) => {
      const {
        letterType,
        project,
        sendioCredId,
        templateId,
        title,
        id,
        allowedCountries,
      } = template;

      const selectedType =
        letterTypeOptions.find((option) => option.value === letterType) ||
        INITIAL_TEMPLATE.letterType;

      return {
        letterType: selectedType,
        project,
        sendioCredId,
        templateId,
        title,
        id,
        allowedCountries,
      };
    });

    this.setState({ templates: templatesToState });
  };

  getTemplatesFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  handleOpenTemplateForm = (): void => {
    this.setState((prevState) => ({
      templates: [
        { ...INITIAL_TEMPLATE, tempId: new Date().getTime() },
        ...prevState.templates,
      ],
    }));
  };

  saveTemplateSuccess = (newTemplate: EmailTemplate): void => {
    this.setState((prevState) => {
      const newTemplates = prevState.templates.map((template) => {
        const match =
          (template && template.id && template.id === newTemplate.id) ||
          (template &&
            template.tempId &&
            template.tempId === newTemplate.tempId);
        if (match) {
          return newTemplate;
        }

        return template;
      });

      return { templates: newTemplates };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSaveTemplate = (template: EmailTemplate): void => {
    const { sendioCredId, requestApi } = this.props;
    const {
      letterType,
      project,
      templateId,
      title,
      id,
      allowedCountries,
    } = template;

    const selectedType =
      (letterType as {
        value: string;
        label: string;
      }) &&
      (letterType as {
        value: string;
        label: string;
      }).value;

    const body = {
      letter_type: selectedType,
      project,
      sendio_cred_id: +sendioCredId,
      template_id: templateId,
      title,
      category: '1',
      allowed_countries: allowedCountries,
    };

    if (id) {
      requestApi({
        url: `/v1/sendio/template/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveTemplateSuccess(template),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/sendio/template`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveTemplateSuccess({ ...template, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deleteTemplateSuccess = (id: number): void => {
    this.setState((prevState) => ({
      templates: prevState.templates.filter(
        (template) => template && template.id !== id && template.tempId !== id
      ),
    }));
  };

  handleDeleteTemplate = ({
    id,
    tempId,
  }: {
    id?: number;
    tempId: number;
  }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/sendio/template/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deleteTemplateSuccess(id),
        onFailure: this.handleError,
      });
    } else if (tempId) {
      this.deleteTemplateSuccess(tempId);
    }
  };

  render(): JSX.Element {
    const { templates, error } = this.state;
    const { sendioCredId } = this.props;

    return (
      <div>
        <div className="title_row sub_row">
          <h2 className="bold label">Email templates</h2>
          <div className="title_btn_box">
            <button
              type="button"
              className="btn_create"
              onClick={this.handleOpenTemplateForm}
            >
              Add template
            </button>
          </div>
        </div>

        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.forms_container}>
          {templates.map(
            (template: EmailTemplate) =>
              template && (
                <div
                  className={styles.form_wrapper}
                  key={template.id || template.tempId}
                >
                  <EmailTemplateForm
                    sendioCredId={sendioCredId}
                    template={template}
                    handleSaveTemplate={this.handleSaveTemplate}
                    handleDelete={this.handleDeleteTemplate}
                  />
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}
