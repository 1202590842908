import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { requestApi } from 'modules/Api/apiActions';
import { getBuisinessApps } from 'modules/AppsMenu/appsSelector';
import { setCurrentApp } from 'modules/Session/sessionActions';
import { openModal } from 'modules/Modal/modalActions';

import { App } from '_types';

import BillingPlansList from './BillingPlansList';

interface State {
  currentAppId: string;
  apps: App[];
}

const mSTP = (state: RootState): State => ({
  currentAppId: state && state.session && state.session.currentAppId,
  apps: getBuisinessApps(state),
});

const mDTP = { requestApi, setCurrentApp, openModal };

export default connect(mSTP, mDTP)(BillingPlansList);
