export const MAX_IMAGE_FILE_SIZE: number = 1024 * 1024;
export const MAX_HTML_FILE_SIZE: number = 500 * 1024;

export const ACCEPTED_IMG_TYPES: Array<string> = [
  'image/png',
  'image/jpg',
  'image/jpeg',
];

export const RECT_COLORS = [
  '#4C46B6',
  '#F6D5CE',
  '#ECBA82',
  '#C488C4',
  '#BB9484',
];

export const INITIAL_PAGE_STATE = {
  total: 0,
  perPage: 100,
};

export const MAX_IMG_WIDTH = 1024;

export const paymentModeOptions = [
  { value: 'subscription', label: 'subscription' },
  { value: 'onetime', label: 'onetime' },
];

export const letterTypeOptions = [
  { value: 'email_confirm', label: 'Email confirm' },
  { value: 'purchase_confirm', label: 'Purchase confirm' },
  { value: 'resend_purchases', label: 'Resend purchases' },
  { value: 'recurring_payment', label: 'Recurring payment' },
  { value: 'rebill_notification', label: 'Rebill notification' },
  { value: 'refund_order', label: 'Refund order' },
  { value: 'cancel_subscription', label: 'Cancel subscription' },
];
