import React from 'react';
import { PurchaseIOS } from '_types';
import { getDateWithSeconds } from 'utils/formatDate';
import { Link } from 'react-router-dom';

interface Props {
  purchase: PurchaseIOS;
}

const PurchaseRowIOS: React.FC<Props> = (props: Props) => {
  const { purchase } = props;
  if (!purchase) return null;

  const {
    createdAt,
    expiresDate,
    isInIntroOfferPeriod,
    isTrialPeriod,
    isUpgraded,
    originalPurchaseDate,
    originalTransactionId,
    productId,
    purchaseDate,
    quantity,
    receiptId,
    refundDate,
    refundReason,
    sandbox,
    transactionId,
    userId,
    customUserId,
    appsflyerId,
  } = purchase;
  const expiresDateString = expiresDate ? getDateWithSeconds(expiresDate) : '-';
  const purchaseDateString = purchaseDate
    ? getDateWithSeconds(purchaseDate)
    : '-';
  const createdAtString = createdAt ? getDateWithSeconds(createdAt) : '-';
  const originalDateString = originalPurchaseDate
    ? getDateWithSeconds(originalPurchaseDate)
    : '-';
  const refundDateString = refundDate ? getDateWithSeconds(refundDate) : '-';

  return (
    <tr>
      <td>{receiptId}</td>
      <td>{isTrialPeriod ? 'yes' : 'no'}</td>
      <td>
        <Link to={`/statistics/users?user_id=${userId}`}>{userId}</Link>
      </td>
      <td>
        <Link to={`/statistics/users?custom_user_id=${customUserId}`}>
          {customUserId}
        </Link>
      </td>
      <td>{appsflyerId}</td>
      <td>{productId}</td>
      <td>{transactionId}</td>
      <td>{originalTransactionId}</td>
      <td>{purchaseDateString}</td>
      <td>{originalDateString}</td>
      <td>{expiresDateString}</td>
      <td>{createdAtString}</td>
      <td>{sandbox ? 'yes' : 'no'}</td>
      <td>{isInIntroOfferPeriod ? 'yes' : 'no'}</td>
      <td>{isUpgraded ? 'yes' : 'no'}</td>
      <td>{quantity}</td>
      <td>{refundDateString}</td>
      <td>{refundReason}</td>
    </tr>
  );
};

export default PurchaseRowIOS;
