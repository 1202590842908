import React from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '_types';
import { RECT_COLORS } from '_config/constants';
import styles from './appsMenu.module.scss';

interface Props {
  app: App;
  setCurrentApp: Function;
  index: number;
}

const AppMenuItem: React.FC<Props> = (props: Props) => {
  const { app, setCurrentApp, index } = props;
  const history = useHistory();

  if (!app) return null;

  const handleClick = (): void => {
    setCurrentApp(app.id);
    history.push(history.location.pathname);
  };

  const logStyles = {
    backgroundColor: RECT_COLORS[index % RECT_COLORS.length],
  };

  return (
    <button type="button" className={styles.menu_item} onClick={handleClick}>
      <div className={styles.app_logo} style={logStyles} />
      <div>{app.name}</div>
    </button>
  );
};

export default AppMenuItem;
