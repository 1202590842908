import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Product } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

interface Response {
  products: Array<Product>;
  meta: {
    total: number;
    offset: number;
    perPage: number;
  };
}

export const getProducts = (appId: string, params: URLSearchParams) => (
  dispatch: Dispatch
) => {
  const stringParams = params ? `&${params.toString()}` : '';

  const getProductsSuccess = (data: Response): void => {
    const products = data && data.products;
    const total = data && data.meta && data.meta.total;
    const perPage = data && data.meta && data.meta.perPage;

    if (products) {
      dispatch({
        type: ACTION_TYPES.SET_APP_PRODUCTS,
        payload: {
          products,
          pages: { total, perPage },
        },
      });
    } else {
      dispatch({
        type: ACTION_TYPES.SET_APP_PRODUCTS,
        payload: {
          products: [],
          pages: INITIAL_PAGE_STATE,
        },
      });
    }
  };

  const getProductsFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_PRODUCTS,
      payload: {
        products: [],
        pages: INITIAL_PAGE_STATE,
      },
    });
  };

  dispatch(
    requestApi({
      url: `/v1/app-subscriptions?app_id=${appId}&${stringParams}`,
      method: 'GET',
      onSuccess: getProductsSuccess,
      onFailure: getProductsFailed,
    })
  );
};

export const resetProducts = (): Action => ({
  type: ACTION_TYPES.SET_APP_PRODUCTS,
  payload: {
    products: [],
    pages: INITIAL_PAGE_STATE,
  },
});
