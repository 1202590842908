import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '_redux/store';

import styles from './loader.module.scss';

interface Props {
  isLoading: number;
}

const Loader: React.FunctionComponent<Props> = ({ isLoading }: Props) =>
  isLoading > 0 ? (
    <div className={styles.loader_wrapper}>
      <div className={styles.loader}>Loading...</div>
    </div>
  ) : null;

interface State {
  isLoading: number;
}

const mSTP = (state: RootState): State => ({
  isLoading: state.isLoading,
});

export default connect(mSTP)(Loader);
