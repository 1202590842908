import React, { useEffect, useState } from 'react';
import Select, { ValueType, ActionMeta } from 'react-select';

import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { EmailTemplate, OptionType } from '_types';
import { letterTypeOptions } from '_config/constants';
import selectStyles from 'styles/selectStyles';
import styles from '../appPage.module.scss';

const INITIAL_STATE = {
  letterType: { value: 'email_confirm', label: 'Email confirm' },
  project: '',
  templateId: '',
  title: '',
  allowedCountries: '',
};

interface Props {
  sendioCredId: string | number;
  template: EmailTemplate;
  handleSaveTemplate: Function;
  handleDelete: Function;
}

const EmailTemplateForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const { template, handleSaveTemplate, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      category,
      letterType,
      project,
      templateId,
      title,
      id,
      allowedCountries,
    } = template;

    dispatch({
      category,
      letterType,
      project,
      templateId,
      title,
      id,
      allowedCountries,
    });
  }, [template, dispatch, props]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleInputAllowedCountriesChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    const countriesRegExp = new RegExp('^([A-Za-z]{2,3}(,|$))+$', 'g');
    if (countriesRegExp.exec(value) == null && value !== '') {
      setError('Not valid countries');
    } else {
      setError('');
    }

    dispatch({ [name]: value });
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const copyTemplateId = (): void => {
    if (template.id) {
      copyToClipboard(template.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No template id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { sendioCredId } = props;

    handleSaveTemplate({ ...formState, tempId: template.tempId, sendioCredId });
  };

  const deletePlan = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (template.id) {
      handleDelete({ id: template.id });
    } else {
      handleDelete({ tempId: template.tempId });
    }
  };

  return (
    <form className="form_wrapper_left" onSubmit={handleSubmit}>
      {template.id && (
        <label htmlFor="id" className={formStyles.row}>
          <div className="label">Id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="id"
              className="input_w_icon"
              value={template.id}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyTemplateId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}

      <label htmlFor="title" className={formStyles.row}>
        <div className="label_row">
          <div>Title</div>
        </div>
        <input
          type="text"
          name="title"
          className="input"
          value={formState.title}
          onChange={handleInputChange}
        />
      </label>

      <div className={formStyles.row}>
        <div className="label_row">Letter Type</div>
      </div>
      <Select
        options={letterTypeOptions}
        styles={selectStyles}
        name="letterType"
        value={formState.letterType}
        isSearchable={false}
        onChange={handleSelect}
      />

      <label htmlFor="project" className={formStyles.row}>
        <div className="label_row">
          <div>Project</div>
        </div>
        <input
          type="text"
          name="project"
          className="input"
          value={formState.project}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="templateId" className={formStyles.row}>
        <div className="label_row">
          <div>Template Id (sendio)</div>
        </div>
        <input
          type="text"
          name="templateId"
          className="input"
          value={formState.templateId}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="allowedCountries" className={formStyles.row}>
        <div className="label_row">
          <div>Allowed countries (example: US,UA) </div>
        </div>
        <input
          type="text"
          name="allowedCountries"
          className="input"
          value={formState.allowedCountries}
          onChange={handleInputAllowedCountriesChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deletePlan}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default EmailTemplateForm;
