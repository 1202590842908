import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Member, Action } from '_types';

export const resetAppTeam = (): Action => ({
  type: ACTION_TYPES.SET_APP_MEMBERS,
  payload: [],
});

export const getAppTeam = () => (dispatch: Dispatch, getState: Function) => {
  const state = getState();
  const currentAppId = state && state.session && state.session.currentAppId;

  const getTeamSuccess = (data: Member[]): void => {
    if (data && Array.isArray(data)) {
      dispatch({
        type: ACTION_TYPES.SET_APP_MEMBERS,
        payload: data,
      });
    }
  };

  const getTeamFailure = (): void => {
    dispatch(resetAppTeam());
  };

  dispatch(
    requestApi({
      url: `/v1/members?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: getTeamSuccess,
      onFailure: getTeamFailure,
    })
  );
};

export const removeMember = (memberId: string) => (
  dispatch: Dispatch,
  getState: Function
) => {
  const deleteSuccess = (): void => {
    getAppTeam()(dispatch, getState);
  };

  const deleteFailure = (): void => {
    // handle error here
  };

  dispatch(
    requestApi({
      url: `/v1/members/${memberId}`,
      method: 'DELETE',
      onSuccess: deleteSuccess,
      onFailure: deleteFailure,
    })
  );
};
