import { RootState } from '_redux/store';
import { App } from '_types';

export type CurrentApp = App | undefined;

export const getCurrentApp = (state: RootState): CurrentApp => {
  const { session, businesses } = state;
  const currentBusinessId = session && session.currentBusinessId;
  const currentAppId = session && session.currentAppId;

  const currentBusiness = businesses.find(
    (business) => business.id === currentBusinessId
  );
  const currentApp =
    currentBusiness &&
    currentBusiness.apps.find((app) => app.id === currentAppId);

  return currentApp;
};
