import React from 'react';

import { Member } from '_types';
import defaultAvatar from 'assets/images/avatar.jpg';

interface Props {
  member: Member;
  removeMember: Function;
}

const MemberRow: React.FC<Props> = (props: Props) => {
  const { member, removeMember } = props;

  if (!member) return null;

  const { email, role, id } = member;

  const handleRemove = (): void => {
    removeMember(id);
  };

  return (
    <div className="table_row table_item">
      <div className="table_cell_60">
        <div className="table_row">
          <div
            className="row_logo"
            style={{ backgroundImage: `url(${defaultAvatar})` }}
          />
          <div className="bold">{email}</div>
          <div className="user_role">{role}</div>
        </div>
      </div>
      <div className="table_cell_40">
        <button
          type="button"
          className="btn_light_grn btn_margin"
          onClick={handleRemove}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default MemberRow;
