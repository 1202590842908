import React from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import styles from './pagination.module.scss';

interface Props {
  total: number;
  perPage: number;
}

const Pagination: React.FC<Props> = (props: Props) => {
  const { total, perPage } = props;

  const history = useHistory();
  const search = history && history.location && history.location.search;
  const queryParams = new URLSearchParams(search);
  const currentPage = queryParams.has('page')
    ? parseInt(queryParams.get('page') || '', 10)
    : 0;

  const pageCount =
    Number.isNaN(total / perPage) || !Number.isFinite(total / perPage)
      ? 0
      : total / perPage;

  const handlePageClick = (e: { selected: number }): void => {
    const selectedPage = e && e.selected;

    queryParams.set('page', selectedPage.toString());

    history.push(`${history.location.pathname}?${queryParams.toString()}`);
  };

  return (
    <ReactPaginate
      pageRangeDisplayed={10}
      marginPagesDisplayed={2}
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      pageCount={pageCount}
      onPageChange={handlePageClick}
      containerClassName={styles.container}
      pageLinkClassName={styles.page_link}
      pageClassName={styles.page}
      activeClassName={styles.active_page}
      previousLinkClassName={styles.arrow_left}
      nextLinkClassName={styles.arrow_right}
      activeLinkClassName={styles.active_page_link}
      initialPage={currentPage}
    />
  );
};

export default Pagination;
