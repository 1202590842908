import { combineReducers } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Screen, PageState } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

const screensReducer = (
  state: Array<Screen> = [],
  action: Action
): Array<Screen> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_SCREENS:
      return action.payload && action.payload.screens;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

const pageReducer = (
  pageState = INITIAL_PAGE_STATE,
  action: Action
): PageState => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_SCREENS:
      return action.payload && action.payload.pages;

    case ACTION_TYPES.SIGN_OUT:
      return INITIAL_PAGE_STATE;

    default:
      return pageState;
  }
};

export default combineReducers({
  screens: screensReducer,
  pages: pageReducer,
});
