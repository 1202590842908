type LSState =
  | {
      [key: string]: any;
    }
  | undefined;

export const saveStateToLS = (state: object, key: string): void => {
  try {
    localStorage.setItem(key, JSON.stringify(state));
  } catch (error) {
    // catch error here
  }
};

export const getStateFromLS = (key: string): LSState => {
  try {
    const state = localStorage.getItem(key);
    if (!state) return undefined;

    return JSON.parse(state);
  } catch (error) {
    return undefined;
  }
};
