import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Business } from '_types';
import AccountSettings from './AccountSettings';

interface State {
  businesses: Business[];
}

const mSTP = (state: RootState): State => ({
  businesses: state.businesses,
});

export default connect(mSTP)(AccountSettings);
