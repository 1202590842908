import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Feedback, Survey } from '_types';
import {
  getFeedbacks,
  resetFeedbacks,
  getSurveys,
  resetSurveys,
} from './feedbacksActions';
import FeedbacksList from './FeedbacksList';

interface State {
  feedbacks: Array<Feedback>;
  surveys: Array<Survey>;
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  feedbacks: state.feedbacks,
  surveys: state.surveys,
  currentAppId: state && state.session && state.session.currentAppId,
});

const mDTP = { getFeedbacks, resetFeedbacks, getSurveys, resetSurveys };

export default connect(mSTP, mDTP)(FeedbacksList);
