type Res = {
  width: number;
  height: number;
};

const getImgDimentions = (file: File): Promise<Res> => {
  const img = new Image();
  const URL = window.URL || window.webkitURL;

  const objectUrl = URL.createObjectURL(file);

  return new Promise((res, rej) => {
    img.onload = () => {
      res({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = rej;
    img.src = objectUrl;
  });
};

export default getImgDimentions;
