import { Action, Dispatch } from 'redux';
import { Auth } from 'aws-amplify';
import { startLoading, finishLoading } from 'modules/Loader/loaderActions';

import ACTION_TYPES from '_redux/actionTypes';
import { User } from '_types';

export const login = (): Action => ({
  type: ACTION_TYPES.SIGN_IN_SUCCESS,
});

export const signOut = (): Action => ({
  type: ACTION_TYPES.SIGN_OUT,
});

export const requestRegister = (user: User) => async (dispatch: Dispatch) => {
  const email = user && user.email;
  const password = user && user.password;

  try {
    startLoading('REGISTER_LOADING')(dispatch);
    const newUser = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
    });
    if (newUser && newUser.userConfirmed) {
      const loggedUser = await Auth.signIn({
        username: email,
        password,
      });

      if (loggedUser) {
        dispatch(login());
        return loggedUser;
      }

      throw new Error('Failed to login.');
    }

    throw new Error('Failed to register or user is not confirmed.');
  } finally {
    finishLoading('REGISTER_LOADING')(dispatch);
  }
};

export const requestLogin = (user: User) => async (dispatch: Dispatch) => {
  const email = user && user.email;
  const password = user && user.password;

  try {
    startLoading('LOGIN_LOADING')(dispatch);
    const loggedUser = await Auth.signIn({
      username: email,
      password,
    });

    if (loggedUser) {
      dispatch(login());
      return loggedUser;
    }

    throw new Error('No such user or user is not confirmed.');
  } finally {
    finishLoading('LOGIN_LOADING')(dispatch);
  }
};

export const requestSignOut = () => async (dispatch: Dispatch) => {
  startLoading('SIGN_OUT_LOADING')(dispatch);
  await Auth.signOut()
    .then(() => {
      dispatch(signOut());
    })
    .finally(() => {
      finishLoading('SIGN_OUT_LOADING')(dispatch);
    });
};

export const requestForgotPassword = (email: string) => async (
  dispatch: Dispatch
) => {
  try {
    startLoading('LOGIN_LOADING')(dispatch);
    const res = await Auth.forgotPassword(email);

    if (res) {
      return res;
    }

    throw new Error('Failed to request restore password');
  } finally {
    finishLoading('LOGIN_LOADING')(dispatch);
  }
};

export const requestResetPassword = (
  email: string,
  code: string,
  password: string
) => async (dispatch: Dispatch) => {
  try {
    startLoading('LOGIN_LOADING')(dispatch);
    const res = await Auth.forgotPasswordSubmit(email, code, password);

    return res;
  } finally {
    finishLoading('LOGIN_LOADING')(dispatch);
  }
};
