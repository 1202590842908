import React, { useEffect, useState } from 'react';
import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { FacebookCAPI } from '_types';
import styles from '../appPage.module.scss';

const INITIAL_STATE = {
  id: 0,
  appID: '',
  tempId: '',
  testEventCode: '',
  apiKey: '',
  pixelId: '',
  sourceUrl: '',
  prelandAppId: '',
};

interface Props {
  capi: FacebookCAPI;
  handleSaveCAPI: Function;
  handleDelete: Function;
}

const FacebookCAPIForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const { capi, handleSaveCAPI, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      id,
      appID,
      tempId,
      testEventCode,
      apiKey,
      pixelId,
      sourceUrl,
      prelandAppId,
    } = capi;

    dispatch({
      id,
      appID,
      tempId,
      testEventCode,
      apiKey,
      pixelId,
      sourceUrl,
      prelandAppId,
    });
  }, [capi, dispatch]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const copyCAPIId = (): void => {
    if (capi.id) {
      copyToClipboard(capi.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No capi id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSaveCAPI({ ...formState, tempId: capi.tempId });
  };

  const deleteCAPI = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (capi.id) {
      handleDelete({ id: capi.id });
    } else {
      handleDelete({ tempId: capi.tempId });
    }
  };

  return (
    <form className="form_wrapper_wide" onSubmit={handleSubmit}>
      {capi.id && (
        <label htmlFor="id" className={formStyles.row}>
          <div className="label">CAPI config id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="id"
              className="input_w_icon"
              value={capi.id}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyCAPIId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}

      <label htmlFor="testEventCode" className={formStyles.row}>
        <div className="label_row">
          <div>Test Event Code</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="testEventCode"
          className="input"
          value={formState.testEventCode}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="apiKey" className={formStyles.row}>
        <div className="label_row">
          <div>Api Key</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="apiKey"
          className="input"
          value={formState.apiKey}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="pixelId" className={formStyles.row}>
        <div className="label_row">
          <div>Pixel id</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="pixelId"
          className="input"
          value={formState.pixelId}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="sourceUrl" className={formStyles.row}>
        <div className="label_row">
          <div>Source url</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="sourceUrl"
          className="input"
          value={formState.sourceUrl}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="prelandAppId" className={formStyles.row}>
        <div className="label_row">
          <div>Preland app id</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="prelandAppId"
          className="input"
          value={formState.prelandAppId}
          onChange={handleInputChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deleteCAPI}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default FacebookCAPIForm;
