import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { openModal, closeModal } from './modalActions';

import ModalDispatcher from './ModalDispatcher';

interface State {
  isOpen: boolean;
  currentModal: string | null;
}

const mSTP = (state: RootState): State => ({
  isOpen: state.modal && state.modal.isOpen,
  currentModal: state.modal && state.modal.currentModal,
});

const mDTP = { openModal, closeModal };

export default connect(mSTP, mDTP)(ModalDispatcher);
