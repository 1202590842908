import React, { useEffect, useState, useRef } from 'react';
import { useFormState } from 'utils/customHooks';
import formStyles from 'styles/forms.module.scss';
import Select, { ActionMeta, ValueType } from 'react-select';
import selectStyles from '../../styles/selectStyles';
import { OptionType } from '../../_types';

const INITIAL_PRICE_STATE = {
  productId: '',
  country: '',
  price: 0,
};

interface Props {
  productArr: {
    value: string;
    label: string;
  }[];
  appId: string;
  handleSavePrice: Function;
}

const PricesForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_PRICE_STATE);
  const { handleSavePrice, productArr } = props;

  const [error] = useState<string>('');

  const jsonEditorRef = useRef();

  useEffect(() => {}, [jsonEditorRef]);

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSavePrice({ ...formState });
  };

  return (
    <form className="form_wrapper_wide" onSubmit={handleSubmit}>
      <div className={formStyles.row}>
        <div className="label_row">Product id</div>
      </div>
      <Select
        options={productArr}
        styles={selectStyles}
        name="id"
        isSearchable={false}
        onChange={handleSelect}
      />

      <label htmlFor="price" className={formStyles.row}>
        <div className="label_row">
          <div>Price</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="price"
          className="input"
          value={formState.price}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="country" className={formStyles.row}>
        <div className="label_row">
          <div>Country</div>
        </div>
        <input
          type="text"
          name="country"
          className="input"
          value={formState.country}
          onChange={handleInputChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
    </form>
  );
};

export default PricesForm;
