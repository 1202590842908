import ACTION_TYPES from '_redux/actionTypes';
import { ApiConfig, Action } from '_types';

interface ApiAction extends Action {
  payload: ApiConfig;
}
// eslint-disable-next-line import/prefer-default-export
export const requestApi = (config: ApiConfig): ApiAction => ({
  type: ACTION_TYPES.API,
  payload: config,
});
