import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { requestApi } from 'modules/Api/apiActions';
import ProductsList from './ProductsList';
import { PageState, Product } from '../../_types';
import { getProducts, resetProducts } from './productsActions';

interface State {
  products: Array<Product>;
  pageData: PageState;
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  products: state.products.products,
  pageData: state.products.pages,
  currentAppId: state && state.session && state.session.currentAppId,
});

const mDTP = { requestApi, getProducts, resetProducts };

export default connect(mSTP, mDTP)(ProductsList);
