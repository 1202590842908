import React, { Component } from 'react';
import { FacebookCAPI } from '_types';
import FacebookCAPIForm from './FacebookCAPIForm';
import FacebookCAPIRow from './FacebookCAPIRow';
import styles from '../appPage.module.scss';

interface State {
  facebookCAPIs: Array<FacebookCAPI | null>;
  error: string;
  capiToEditId: string | number;
  isEditing: boolean;
}

interface Props {
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  facebookCAPIs: [],
  error: '',
  capiToEditId: 0,
  isEditing: false,
};

const INITIAL_FACEBOOK_CAPI = {
  id: 0,
  appID: '',
  tempId: 0,
  testEventCode: '',
  apiKey: '',
  pixelId: '',
  sourceUrl: '',
  prelandAppId: '',
};

export default class FacebookCAPIList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/facebook/capi?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: this.getCAPISuccess,
      onFailure: this.getCAPIsFailed,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { requestApi, currentAppId } = this.props;
    const { currentAppId: prevAppId } = prevProps;

    if (prevAppId !== currentAppId) {
      requestApi({
        url: `/v1/facebook/capi?app_id=${currentAppId}`,
        method: 'GET',
        onSuccess: this.getCAPISuccess,
        onFailure: this.getCAPIsFailed,
      });
    }
  }

  getCAPISuccess = (data: FacebookCAPI[]): void => {
    const capiToState = data.map((capi: FacebookCAPI) => {
      const {
        id,
        appID,
        testEventCode,
        apiKey,
        pixelId,
        sourceUrl,
        prelandAppId,
      } = capi;

      return {
        id,
        appID,
        testEventCode,
        apiKey,
        pixelId,
        sourceUrl,
        prelandAppId,
      };
    });

    this.setState({ facebookCAPIs: capiToState });
  };

  getCAPIsFailed = (): void => {
    this.setState(INITIAL_STATE);
  };

  handleOpenCAPIForm = (): void => {
    const tempId = new Date().getTime();
    this.setState((prevState) => ({
      facebookCAPIs: [
        { ...INITIAL_FACEBOOK_CAPI, tempId },
        ...prevState.facebookCAPIs,
      ],
      capiToEditId: tempId,
      isEditing: true,
    }));
  };

  handleEditStart = (id: string | number): void => {
    this.setState({
      capiToEditId: id,
      isEditing: true,
    });
  };

  handleEditStop = (): void => {
    this.setState({
      isEditing: false,
    });
  };

  saveCAPISuccess = (newCAPI: FacebookCAPI): void => {
    this.setState((prevState) => {
      const newCAPIs = prevState.facebookCAPIs.map((capi) => {
        const match =
          (capi && capi.id && capi.id === newCAPI.id) ||
          (capi && capi.tempId && capi.tempId === newCAPI.tempId);
        if (match) {
          return { ...newCAPI };
        }

        return newCAPI;
      });

      return { facebookCAPIs: newCAPIs, isEditing: false };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSaveCAPI = (capi: FacebookCAPI): void => {
    const { currentAppId, requestApi } = this.props;
    const {
      id,
      testEventCode,
      apiKey,
      pixelId,
      sourceUrl,
      prelandAppId,
    } = capi;

    const body = {
      id,
      app_id: currentAppId,
      test_event_code: testEventCode,
      api_key: apiKey,
      pixel_id: pixelId,
      source_url: sourceUrl,
      preland_app_id: prelandAppId,
    };

    if (id) {
      requestApi({
        url: `/v1/facebook/capi/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.saveCAPISuccess(capi),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/facebook/capi`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.saveCAPISuccess({ ...capi, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deleteCAPISuccess = (id: number): void => {
    this.setState((prevState) => ({
      facebookCAPIs: prevState.facebookCAPIs.filter(
        (capi) => capi && capi.id !== id && capi.tempId !== id
      ),
    }));
  };

  handleDeleteCAPI = ({
    id,
    tempId,
  }: {
    id?: number;
    tempId: number;
  }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/facebook/capi/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deleteCAPISuccess(id),
        onFailure: this.handleError,
      });
    } else if (tempId) {
      this.deleteCAPISuccess(tempId);
    }
  };

  render(): JSX.Element {
    const { facebookCAPIs, error, capiToEditId, isEditing } = this.state;

    const capiForEdit =
      facebookCAPIs &&
      facebookCAPIs.find((capi: FacebookCAPI) => {
        const match =
          (capi && capi.id && capi.id === capiToEditId) ||
          (capi && capi.tempId && capi.tempId === capiToEditId);

        return match;
      });

    return (
      <div>
        <div className="title_row">
          <button
            type="button"
            className="btn_create"
            onClick={this.handleOpenCAPIForm}
          >
            Add capi config
          </button>
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className="table_scroll_wrapper">
          <table className="real_table_wrapper">
            <thead className="table_head">
              <tr className="table_head">
                <th>Edit</th>
                <th className="bold">Facebook CAPI id</th>
                <th className="bold">Test event code</th>
                <th className="bold">Api key</th>
                <th className="bold">Pixel id</th>
                <th className="bold">Source url</th>
                <th className="bold">Preland app id</th>
              </tr>
            </thead>
            <tbody className="table_content">
              {facebookCAPIs &&
              Array.isArray(facebookCAPIs) &&
              facebookCAPIs.length > 0 ? (
                facebookCAPIs.map((capi: FacebookCAPI) => {
                  return (
                    <FacebookCAPIRow
                      key={capi.id || capi.tempId}
                      facebookCAPI={capi}
                      handleStartEdit={this.handleEditStart}
                    />
                  );
                })
              ) : (
                <tr>
                  <td>CAPI configs will be here</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isEditing && capiForEdit && (
          <div className={styles.form_modal}>
            <div className={styles.close_btn_row}>
              <button
                type="button"
                className="close_btn"
                onClick={this.handleEditStop}
              >
                <div className="close_icon" />
              </button>
            </div>
            <FacebookCAPIForm
              capi={capiForEdit}
              handleSaveCAPI={this.handleSaveCAPI}
              handleDelete={this.handleDeleteCAPI}
            />
          </div>
        )}
      </div>
    );
  }
}
