import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DropDown from 'modules/shared/DropDown';
import InputFilter from 'modules/Filter/InputFilter';
import styles from 'modules/Filter/filter.module.scss';
import CancelFilter from 'modules/Filter/CancleFilter';

const DELAY = 300;

const INITIAL_STATE = {
  filters: {
    receipt_id: '',
    user_id: '',
    custom_user_id: '',
    transaction_id: '',
    status_id: '',
    order_id: '',
  },
};

type State = {
  filters: Record<string, any>;
};

interface Props extends RouteComponentProps {
  appType: string;
}

class PurchaseFilter extends Component<Props, State> {
  state = { ...INITIAL_STATE };

  timerId: any;

  componentDidMount(): void {
    this.setFilterValues();
  }

  componentDidUpdate(prevProps: Props): void {
    const { location } = this.props;

    const prevSearch = prevProps.location && prevProps.location.search;
    const currentSearch = location && location.search;

    if (prevSearch !== currentSearch) {
      this.setFilterValues();
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(this.applyFilters, DELAY);
      }
    );
  };

  applyFilters = (): void => {
    const { history } = this.props;
    const { filters } = this.state;
    const prevSearch = history.location.search;
    const query = new URLSearchParams(prevSearch);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        query.set(key, value);
      } else {
        query.delete(key);
      }
    });
    query.delete('page');
    history.push(`${history.location.pathname}?${query.toString()}`);
  };

  setFilterValues = (): void => {
    const { location } = this.props;
    const { filters } = this.state;
    const search = location && location.search;

    this.setState(INITIAL_STATE);

    if (search) {
      const query = new URLSearchParams(search);

      Object.keys(filters).forEach((key) => {
        if (query.has(key)) {
          this.setState((prevState) => {
            const value: any = query.get(key);

            return {
              ...prevState,
              filters: {
                ...prevState.filters,
                [key]: value,
              },
            };
          });
        } else {
          query.delete(key);
        }
      });
    }
  };

  cancelFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const { name } = e.currentTarget.dataset;
    if (!name) return;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: '',
        },
      }),
      this.applyFilters
    );
  };

  render(): React.ReactNode {
    const { appType } = this.props;
    const { filters } = this.state;

    const isAndroid = appType === 'android';

    return (
      <section className={styles.filters_section}>
        <DropDown closeOnClickOutside>
          {(open: boolean, toggleOpen: () => void) => (
            <div>
              <div className={styles.filters_row}>
                <button
                  type="button"
                  className="btn_create"
                  onClick={toggleOpen}
                >
                  Where
                </button>
                <div className={styles.cancels_box}>
                  {Object.entries(filters).map(([key, value]) => {
                    return value ? (
                      <CancelFilter
                        key={key}
                        name={key}
                        label={key}
                        value={value}
                        cancelFilter={this.cancelFilter}
                      />
                    ) : null;
                  })}
                </div>
              </div>

              {open && isAndroid && (
                <div className={styles.menu}>
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="status_id"
                    label="Status id"
                    value={filters.status_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="user_id"
                    label="User id"
                    value={filters.user_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="custom_user_id"
                    label="Custom user id"
                    value={filters.custom_user_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="order_id"
                    label="Order id"
                    value={filters.order_id}
                  />
                </div>
              )}

              {open && !isAndroid && (
                <div className={styles.menu}>
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="receipt_id"
                    label="Receipt id"
                    value={filters.receipt_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="user_id"
                    label="User id"
                    value={filters.user_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="custom_user_id"
                    label="Custom user id"
                    value={filters.custom_user_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="transaction_id"
                    label="Transaction id"
                    value={filters.transaction_id}
                  />
                </div>
              )}
            </div>
          )}
        </DropDown>
      </section>
    );
  }
}

export default withRouter(PurchaseFilter);
