import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'icons/Logo';
import AppsMenu from 'modules/AppsMenu/AppsMenuContainer';
import UserMenu from 'modules/UserMenu/UserMenuContainer';
import styles from './header.module.scss';

interface Props {
  showLogo?: boolean;
}

const Header: React.FC<Props> = ({ showLogo }: Props) => (
  <header className={styles.header}>
    {showLogo && (
      <div className={styles.col}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
    )}
    <div className={styles.col}>
      <AppsMenu />
    </div>
    <div className={styles.col}>
      <Link to="/apps/create" className={styles.add_app}>
        Add new app
      </Link>
    </div>
    <div className={styles.right_column}>
      <UserMenu />
    </div>
  </header>
);

export default Header;
