import React from 'react';
import formStyles from 'styles/forms.module.scss';
import AppForm from './AppForm/AppFormContainer';

const AppCreatePage: React.FC = () => (
  <section className={`form_background ${formStyles.background}`}>
    <div className={formStyles.content}>
      <AppForm />
    </div>
  </section>
);

export default AppCreatePage;
