import { connect } from 'react-redux';
import { requestApi } from 'modules/Api/apiActions';
import { setCurrentBusiness } from 'modules/Session/sessionActions';
import { getUserBusinesses } from './businessActions';

import BusinessPageForm from './BusinessForm';

const mDTP = { requestApi, setCurrentBusiness, getUserBusinesses };

export default connect(null, mDTP)(BusinessPageForm);
