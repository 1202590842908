import React from 'react';

type Props = {
  className?: string;
};

const Checkmark: React.FC<Props> = ({ className }: Props) => (
  <svg
    className={className}
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.07779 4.47202L0.663574 5.88623L4.00225 9.2249L5.41646 7.81069L5.41165 7.80588L9.88355 3.33398L8.46934 1.91976L3.99744 6.39167L2.07779 4.47202Z"
    />
  </svg>
);

export default Checkmark;
