enum ACTION_TYPES {
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_OUT = 'SIGN_OUT',
  START_LOADING = 'START_LOADING',
  FINISH_LOADING = 'FINISH_LOADING',
  API = 'API',
  SET_APP_SCREENS = 'SET_APP_SCREENS',
  SET_USER_BUSINESSES = 'SETSET_USER_BUSINESSES_APP_SCREENS',
  SET_CURRENT_BUSINESS = 'SET_CURRENT_BUSINESS',
  SET_CURRENT_APP = 'SET_CURRENT_APP',
  SET_APP_FEEDBACKS = 'SET_APP_FEEDBACKS',
  SET_APP_PRODUCTS = 'SET_APP_PRODUCTS',
  SET_APP_SURVEYS = 'SET_APP_SURVEYS',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_APP_MEMBERS = 'SET_APP_MEMBERS',
  SET_APP_REPORTS = 'SET_APP_REPORTS',
  SET_APP_PURCHASE = 'SET_APP_PURCHASE',
  SET_APP_USERS = 'SET_APP_USERS',
}

export default ACTION_TYPES;
