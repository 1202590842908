import { Action } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';

const authenticatedReducer = (state = false, action: Action): boolean => {
  switch (action.type) {
    case ACTION_TYPES.SIGN_IN_SUCCESS:
      return true;

    case ACTION_TYPES.SIGN_OUT:
      return false;

    default:
      return state;
  }
};

export default authenticatedReducer;
