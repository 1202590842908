import React, { useEffect } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import Header from 'modules/Header/Header';
import BusinessPageForm from 'modules/BusinessPage/BusinessFormContainer';
import AppCreatePage from 'modules/AppPage/AppCreatePage';
import AppSettingsPage from 'modules/AppPage/AppSettingsPageContainer';
// import Dashboard from 'modules/Dashboard/Dashboard';
import Sidebar from 'modules/Sidebar/Sidebar';
import ScreensList from 'modules/Screens/ScreensList/ScreensListContainer';
import ScreenForm from 'modules/Screens/ScreenCostructor/ScreenFormContainer';
import Account from 'modules/Account/Account';
import FeedbacksList from 'modules/Feedbacks/FeedbacksListContainer';
import ProductsList from 'modules/Products/ProductContainer';
import EditScreen from 'modules/Screens/EditScreen/EditScreenContainer';
import InviteForm from 'modules/Team/InviteFormContainer';
import Statistics from 'modules/Statistics/StatisticsPage';
import styles from './cabinet.module.scss';

interface Props extends RouteComponentProps {
  getUserBusinesses: Function;
}

const Cabinet: React.FC<Props> = (props: Props) => {
  const { location, match, getUserBusinesses } = props;

  useEffect(() => {
    getUserBusinesses();
  }, [getUserBusinesses]);

  const pathname = location && location.pathname;
  const noSideBarPaths: Array<string> = [
    `${match.path}business/create`,
    `${match.path}apps/create`,
    '/team/invite',
  ];

  const hideSideBar =
    noSideBarPaths.includes(pathname) || pathname.includes('/screens/edit');

  const hideHeader = pathname.includes('/screens/edit');

  return (
    <div className={styles.main_wrapper}>
      {!hideSideBar && <Sidebar />}

      <div className={styles.right_part}>
        {!hideHeader && <Header showLogo={hideSideBar} />}
        <main className={styles.main_content}>
          <Switch>
            <Route path={match.path} exact component={ScreensList} />
            <Route
              path={`${match.path}business/create`}
              component={BusinessPageForm}
            />
            <Route
              path={`${match.path}apps/create`}
              component={AppCreatePage}
            />
            <Route
              path={`${match.path}screens`}
              exact
              component={ScreensList}
            />
            <Route
              path={`${match.path}screens/create`}
              exact
              component={ScreenForm}
            />
            <Route
              path={`${match.path}screens/edit/:id`}
              exact
              component={EditScreen}
            />
            <Route
              path={`${match.path}app/settings`}
              component={AppSettingsPage}
            />
            <Route path={`${match.path}account`} component={Account} />
            <Route path={`${match.path}feedbacks`} component={FeedbacksList} />
            <Route path={`${match.path}products`} component={ProductsList} />
            <Route path={`${match.path}statistics`} component={Statistics} />
            <Route path={`${match.path}team/invite`} component={InviteForm} />
            <Route render={() => <div>Not found</div>} />
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Cabinet;
