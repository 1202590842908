import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { getCurrentApp, CurrentApp } from '_redux/selectors/getCurrentApp';
import AppSettingsPage from './AppSettingsPage';

interface State {
  currentApp: CurrentApp;
}

const mSTP = (state: RootState): State => ({
  currentApp: getCurrentApp(state),
});

export default connect(mSTP)(AppSettingsPage);
