import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Logo from 'icons/Logo';
import { useFormState } from 'utils/customHooks';
import { validatePassword, validateEmail } from 'utils/validation';
import styles from '../login.module.scss';

type RouteState = {
  email?: string;
};

interface Props extends RouteComponentProps<{}, any, RouteState> {
  requestForgotPassword: Function;
  requestResetPassword: Function;
  requestLogin: Function;
}

const INITIAL_STATE = {
  email: { value: '', isValid: true },
  code: { value: '', isValid: true },
  password: { value: '', isValid: true },
  confirmPassword: { value: '', isValid: true },
  error: '',
};

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const { location } = props;

  const { formState, dispatch } = useFormState(INITIAL_STATE);

  useEffect(() => {
    const search = location && location.search;
    const queryParams = new URLSearchParams(search);
    const code = queryParams.get('code');
    const email =
      queryParams.get('email') ||
      (location && location.state && location.state.email);

    if (code) dispatch({ code: { value: code, isValid: true } });
    if (email) {
      dispatch({
        email: {
          value: email,
          isValid: validateEmail(email),
        },
      });
    }
  }, [location, dispatch]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    let isValid;

    switch (name) {
      case 'email':
        isValid = validateEmail(value);
        break;

      case 'password': {
        isValid = validatePassword(value);
        break;
      }

      default:
        isValid = true;
        break;
    }

    dispatch({ [name]: { value, isValid } });
  };

  const handleError = (err: string): void => {
    const errText = typeof err === 'string' ? err : 'Something went wrong';

    dispatch({ error: errText });
  };

  const validateForm = (): boolean => {
    const emailValid =
      formState.email &&
      formState.email.value &&
      formState.email.value.trim() !== '' &&
      formState.email.isValid;

    if (!emailValid) {
      handleError('Invalid email');
      return false;
    }

    const verificationValid =
      formState.code &&
      formState.code.value &&
      formState.code.value.trim() !== '';

    if (!verificationValid) {
      handleError('Verification code should not be empty');
      return false;
    }

    const passwordValue = formState.password && formState.password.value;
    const validPassword =
      passwordValue.trim() !== '' &&
      formState.password &&
      formState.password.isValid;

    if (!validPassword) {
      handleError('Password should be at least 6 characters');
      return false;
    }

    const confirmPasswordValue =
      formState.confirmPassword && formState.confirmPassword.value;
    const passwordsEquals = passwordValue === confirmPasswordValue;

    if (!passwordsEquals) {
      handleError('Passwords not equals');
      return false;
    }

    return true;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!validateForm()) return;

    const { password, code, email } = formState;
    const { requestResetPassword, requestLogin, history } = props;

    requestResetPassword(email.value, code.value, password.value)
      .then(() => {
        requestLogin({ email: email.value, password: password.value }).then(
          (loggedUser: object) => {
            if (loggedUser) {
              history.push('/');
            } else {
              handleError('Failed to login');
            }
          }
        );
      })
      .catch((rej: any) => {
        const err = typeof rej === 'string' ? rej : rej.message;
        handleError(err);
      });
  };

  return (
    <section className="form_background">
      <div className={styles.logo_box}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <form className="form_wrapper" onSubmit={handleSubmit} autoComplete="on">
        <h1 className={`sub_title ${styles.text_center}`}>Reset Password</h1>
        <div className={`${styles.row} ${styles.small_text}`}>
          We sent verification code to the email you have provided.
        </div>

        <label htmlFor="email" className={styles.row}>
          <div className="label">Email Address</div>
          <input
            type="email"
            name="email"
            className="input"
            value={formState.email.value}
            onChange={handleInputChange}
          />
        </label>

        <label htmlFor="code" className={styles.row}>
          <div className="label">Verification code</div>
          <input
            type="text"
            name="code"
            className="input"
            value={formState.code.value}
            onChange={handleInputChange}
          />
        </label>

        <label htmlFor="password" className={styles.row}>
          <div className="label">Password</div>
          <input
            type="password"
            name="password"
            id="password"
            className="input"
            value={formState.password.value}
            onChange={handleInputChange}
            autoComplete="new-password"
          />
        </label>

        <label htmlFor="confirmPassword" className={styles.row}>
          <div className="label">Confirm password</div>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className="input"
            value={formState.confirmPassword.value}
            onChange={handleInputChange}
            autoComplete="new-password"
          />
        </label>

        {formState.error && (
          <div className={styles.error_message}>{formState.error}</div>
        )}

        <div className={styles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Send
          </button>
        </div>
      </form>
    </section>
  );
};

export default ResetPasswordForm;
