export const cognitoConfig = {
  mandatorySignIn: true,
  userPoolId: 'us-east-1_wkAagFnuw',
  userPoolWebClientId: '6cl92ag0ulj1ofulgtkvni4fgn',
};

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? ''
    : 'https://app-api.panda.boosters.company';

export const STRIPE_KEY = 'pk_live_EzuCQKCQpqzBxf0yHNRo8E5M00o6j7S1yO';

export const PRICE_FREE = 'price_1HEzAMHr2FqFhmMdm6bO8bcS';
export const PRICE_ANALYZE = 'price_1HEzAMHr2FqFhmMdpKXjYZBd';
export const PRICE_GROW = 'price_1HEzAMHr2FqFhmMdxHMXwdiN';

export const SUBSCRIPTION_HOST = 'https://api.panda.boosters.company';

export const DEFAULT_REBILLS_FILTER = 'is_production=true';
