import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Report } from '_types';
import { getReports, resetReports } from './reportsActions';
import Reports from './Reports';

interface State {
  reports: Array<Report>;
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  currentAppId: state && state.session && state.session.currentAppId,
  reports: state.reports,
});

const mDTP = { getReports, resetReports };

export default connect(mSTP, mDTP)(Reports);
