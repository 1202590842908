import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DropDown from 'modules/shared/DropDown';
import InputFilter from 'modules/Filter/InputFilter';
import DateFilter from 'modules/Filter/DateFilter';
import styles from 'modules/Filter/filter.module.scss';
import CancelFilter from 'modules/Filter/CancleFilter';
import downloadData from 'utils/downloadData';
import { getISODashDate } from 'utils/formatDate';

const DELAY = 300;

const INITIAL_STATE = {
  filters: {
    start_date: null,
    finish_date: null,
    is_production: '',
    screen_id: '',
    user_id: '',
  },
};

type State = {
  filters: Record<string, any>;
};

interface Props extends RouteComponentProps {
  requestApi: Function;
  currentAppId: string;
}

class FeedbacksFilters extends Component<Props, State> {
  state = { ...INITIAL_STATE };

  timerId: any;

  componentDidMount(): void {
    this.setFilterValues();
  }

  componentDidUpdate(prevProps: Props): void {
    const { location } = this.props;

    const prevSearch = prevProps.location && prevProps.location.search;
    const currentSearch = location && location.search;

    if (prevSearch !== currentSearch) {
      this.setFilterValues();
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(this.applyFilters, DELAY);
      }
    );
  };

  applyFilters = (): void => {
    const { history } = this.props;
    const { filters } = this.state;
    const prevSearch = history.location.search;
    const query = new URLSearchParams(prevSearch);
    Object.entries(filters).forEach(([key, value]) => {
      if ((key === 'start_date' || key === 'finish_date') && value) {
        query.set(key, getISODashDate(value));
      } else if (value) {
        query.set(key, value);
      } else {
        query.delete(key);
      }
    });
    history.push(`${history.location.pathname}?${query.toString()}`);
  };

  handleDateSelect = (date: Date | Date[]): void => {
    if (Array.isArray(date)) {
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            start_date: date[0],
            finish_date: date[1],
          },
        }),
        this.applyFilters
      );
    }
  };

  setFilterValues = (): void => {
    const { location } = this.props;
    const { filters } = this.state;
    const search = location && location.search;

    this.setState(INITIAL_STATE);

    if (search) {
      const query = new URLSearchParams(search);

      Object.keys(filters).forEach((key) => {
        if (query.has(key)) {
          this.setState((prevState) => {
            let value: any = query.get(key);
            if (key === 'start_date' && value) {
              value = new Date(value);
              value.setHours(0);
              value.setMinutes(0);
              value.setSeconds(0);
            }

            if (key === 'finish_date' && value) {
              value = new Date(value);
              value.setHours(23);
              value.setMinutes(59);
              value.setSeconds(59);
            }

            return {
              ...prevState,
              filters: {
                ...prevState.filters,
                [key]: value,
              },
            };
          });
        } else {
          query.delete(key);
        }
      });
    }
  };

  cancelFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const { name } = e.currentTarget.dataset;
    if (!name) return;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: '',
        },
      }),
      this.applyFilters
    );
  };

  cancelDateFilter = (): void => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          start_date: null,
          finish_date: null,
        },
      }),
      this.applyFilters
    );
  };

  handleDownLoad = (res: Blob): void => {
    const fileName = `report_${new Date().toISOString()}`;
    const fileExtention = 'csv';
    downloadData(res, fileName, fileExtention);
  };

  handleExport = (): void => {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/feedback/answers?app_id=${currentAppId}`,
      onSuccess: this.handleDownLoad,
      download: true,
    });
  };

  render(): React.ReactNode {
    const { filters } = this.state;

    const { start_date: startDate, finish_date: finishDate } = filters;
    let date: any = startDate || null;
    if (startDate && finishDate) {
      date = [startDate, finishDate];
    }

    return (
      <section className={styles.filters_section}>
        <DropDown closeOnClickOutside>
          {(open: boolean, toggleOpen: () => void) => (
            <div>
              <div className={styles.filters_row}>
                <button
                  type="button"
                  className="btn_create"
                  onClick={toggleOpen}
                >
                  Where
                </button>
                <div className={styles.cancels_box}>
                  {Object.entries(filters).map(([key, value]) => {
                    if (key === 'start_date' || !value) {
                      return null;
                    }

                    if (key === 'finish_date' && value) {
                      return (
                        <CancelFilter
                          key={key}
                          name={key}
                          label="date range"
                          value={`${getISODashDate(
                            startDate || ''
                          )} - ${getISODashDate(finishDate || '')}`}
                          cancelFilter={this.cancelDateFilter}
                        />
                      );
                    }

                    return (
                      <CancelFilter
                        key={key}
                        name={key}
                        label={key}
                        value={value}
                        cancelFilter={this.cancelFilter}
                      />
                    );
                  })}
                </div>
                <button
                  type="button"
                  className="btn_grn_small"
                  onClick={this.handleExport}
                >
                  Export
                </button>
              </div>

              {open && (
                <div className={styles.menu}>
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="screen_id"
                    label="Screen id"
                    value={filters.screen_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="user_id"
                    label="User id"
                    value={filters.user_id}
                  />
                  <DateFilter value={date} onChange={this.handleDateSelect} />
                </div>
              )}
            </div>
          )}
        </DropDown>
      </section>
    );
  }
}

export default withRouter(FeedbacksFilters);
