import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestApi } from 'modules/Api/apiActions';
import { RootState } from '_redux/store';
import { setCurrentApp } from 'modules/Session/sessionActions';
import { getUserBusinesses } from 'modules/BusinessPage/businessActions';
import { openModal } from 'modules/Modal/modalActions';
import { getCurrentApp, CurrentApp } from '_redux/selectors/getCurrentApp';

import AppForm from './AppForm';

interface State {
  currentBusinessId: string;
  currentApp: CurrentApp;
}

const mSTP = (state: RootState): State => {
  const { session } = state;
  const currentBusinessId = session && session.currentBusinessId;

  const currentApp = getCurrentApp(state);

  return { currentBusinessId, currentApp };
};

const mDTP = { requestApi, setCurrentApp, getUserBusinesses, openModal };

export default withRouter(connect(mSTP, mDTP)(AppForm));
