import React from 'react';
import { Link } from 'react-router-dom';
import { Business } from '_types';
import BusinessRow from './BusinessRow';

interface Props {
  businesses: Business[];
}

const AccountSettings: React.FC<Props> = (props: Props) => {
  const { businesses } = props;

  return (
    <>
      <div className="title_row">
        <Link to="/account" className="back_btn" />
        <h1 className="title">Businesses</h1>
        <div className="title_btn_box">
          <Link to="/business/create" className="btn_create">
            New Business
          </Link>
        </div>
      </div>
      <div className="table_wrapper">
        <div className="table_row table_head">
          <div className="table_cell_60 bold">Name</div>
          <div className="table_cell_40 bold">Action</div>
        </div>
        <div className="table_content">
          {businesses && businesses.length > 0
            ? businesses.map((business: Business) => {
                return <BusinessRow key={business.id} business={business} />;
              })
            : 'Your businesses will be here'}
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
