import ACTION_TYPES from '_redux/actionTypes';
import { Action, Survey } from '_types';

const surveysReducer = (
  state: Array<Survey> = [],
  action: Action
): Array<Survey> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_SURVEYS:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export default surveysReducer;
