import ACTION_TYPES from '_redux/actionTypes';
import { Action, Report } from '_types';

const reportsReducer = (
  state: Array<Report> = [],
  action: Action
): Array<Report> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_REPORTS:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export default reportsReducer;
