import React, { useEffect, useState } from 'react';

import { useFormState } from 'utils/customHooks';
import formStyles from 'styles/forms.module.scss';

interface Credentials {
  keyId: string;
  topic: string;
  authKey: string;
  teamId: string;
}

const INITIAL_STATE = {
  keyId: '',
  topic: '',
  authKey: '',
  teamId: '',
};

interface Props {
  currentAppId: string;
  requestApi: Function;
}

const PushCredentialsForm: React.FC<Props> = (props: Props) => {
  const { currentAppId, requestApi } = props;
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const { formState, dispatch } = useFormState(INITIAL_STATE);

  useEffect(() => {
    setLoading(true);

    const handleSuccess = (data: Credentials): void => {
      if (data) {
        const { keyId, topic, authKey, teamId } = data;

        dispatch({
          keyId,
          topic,
          authKey,
          teamId,
        });
        setEditMode(true);
      } else {
        dispatch(INITIAL_STATE);
        setEditMode(false);
      }
      setLoading(false);
    };

    const handleFailed = (): void => {
      setLoading(false);
      dispatch(INITIAL_STATE);
    };

    requestApi({
      url: `/v1/apns-configs?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: handleSuccess,
      onFailure: handleFailed,
    });
  }, [currentAppId, dispatch, requestApi]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleSuccessEdit = (data: Record<string, any>): void => {
    if (data) {
      setSuccess('Credentials saved successfully');
      setTimeout(() => {
        setSuccess('');
      }, 2000);
    } else {
      setError('Failed to save');
    }
  };

  const handleError = (err: any): void => {
    const message = (err && err.message) || 'Something went wrong';
    setError(message);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    setSuccess('');
    const { keyId, topic, authKey, teamId } = formState;

    const allDataExist = keyId && topic && authKey && teamId;

    const body = {
      key_id: keyId,
      topic,
      auth_key: authKey,
      team_id: teamId,
    };

    if (allDataExist) {
      if (editMode) {
        requestApi({
          url: `/v1/apns-configs/${currentAppId}`,
          method: 'PUT',
          body,
          onSuccess: handleSuccessEdit,
          onFailure: handleError,
        });
      } else {
        requestApi({
          url: '/v1/apns-configs',
          method: 'POST',
          body: { ...body, app_id: currentAppId },
          onSuccess: handleSuccessEdit,
          onFailure: handleError,
        });
      }
    } else {
      setError('All fields should be fulfilled');
    }
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <form className="form_wrapper_left" onSubmit={handleSubmit}>
      <label htmlFor="keyId" className={formStyles.row}>
        <div className="label_row">
          <div>Key Id</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="keyId"
          className="input"
          value={formState.keyId}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="topic" className={formStyles.row}>
        <div className="label_row">
          <div>Topic</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="topic"
          className="input"
          value={formState.topic}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="authKey" className={formStyles.row}>
        <div className="label_row">
          <div>Auth Key</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <textarea
          name="authKey"
          className="text_area"
          value={formState.authKey}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="teamId" className={formStyles.row}>
        <div className="label_row">
          <div>Team Id</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="teamId"
          className="input"
          value={formState.teamId}
          onChange={handleInputChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        {success && <div>{success}</div>}
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
    </form>
  );
};

export default PushCredentialsForm;
