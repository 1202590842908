import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DropDown from 'modules/shared/DropDown';
import InputFilter from 'modules/Filter/InputFilter';
import styles from 'modules/Filter/filter.module.scss';
import CancelFilter from 'modules/Filter/CancleFilter';

const DELAY = 300;

const INITIAL_STATE = {
  filters: {
    id: 0,
    app_id: '',
    source: '',
    reference_name: '',
    product_id: '',
    revenue: 0,
    duration: '',
    duration_in_days: 0,
    has_trial: '',
    app_store_id: '',
    has_offer: false,
    created_at: '',
    offer_revenue: 0,
    offer_duration: '',
    offer_duration_in_days: 0,
    consumable: false,
    trial_duration: '',
    trial_duration_in_days: 0,
    price: 0,
    country: '',
  },
};

type State = {
  filters: Record<string, any>;
};

interface Props extends RouteComponentProps {
  requestApi: Function;
  currentAppId: string;
}

class ProductFilters extends Component<Props, State> {
  state = { ...INITIAL_STATE };

  timerId: any;

  componentDidMount(): void {
    this.setFilterValues();
  }

  componentDidUpdate(prevProps: Props): void {
    const { location } = this.props;

    const prevSearch = prevProps.location && prevProps.location.search;
    const currentSearch = location && location.search;

    if (prevSearch !== currentSearch) {
      this.setFilterValues();
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(this.applyFilters, DELAY);
      }
    );
  };

  applyFilters = (): void => {
    const { history } = this.props;
    const { filters } = this.state;
    const prevSearch = history.location.search;
    const query = new URLSearchParams(prevSearch);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });
    history.push(`${history.location.pathname}?${query.toString()}`);
  };

  setFilterValues = (): void => {
    const { location } = this.props;
    const { filters } = this.state;
    const search = location && location.search;

    this.setState(INITIAL_STATE);

    if (search) {
      const query = new URLSearchParams(search);

      Object.keys(filters).forEach((key) => {
        if (query.has(key)) {
          this.setState((prevState) => {
            const value: any = query.get(key);

            return {
              ...prevState,
              filters: {
                ...prevState.filters,
                [key]: value,
              },
            };
          });
        } else {
          query.delete(key);
        }
      });
    }
  };

  cancelFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const { name } = e.currentTarget.dataset;
    if (!name) return;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: '',
        },
      }),
      this.applyFilters
    );
  };

  cancelDateFilter = (): void => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          start_date: null,
          finish_date: null,
        },
      }),
      this.applyFilters
    );
  };

  render(): React.ReactNode {
    const { filters } = this.state;

    return (
      <section className={styles.filters_section}>
        <DropDown closeOnClickOutside>
          {(open: boolean, toggleOpen: () => void) => (
            <div>
              <div className={styles.filters_row}>
                <button
                  type="button"
                  className="btn_create"
                  onClick={toggleOpen}
                >
                  Where
                </button>
                <div className={styles.cancels_box}>
                  {Object.entries(filters).map(([key, value]) => {
                    if (key === 'start_date' || !value) {
                      return null;
                    }

                    return (
                      <CancelFilter
                        key={key}
                        name={key}
                        label={key}
                        value={value.toString()}
                        cancelFilter={this.cancelFilter}
                      />
                    );
                  })}
                </div>
              </div>

              {open && (
                <div className={styles.menu}>
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="id"
                    label="id"
                    value={filters.id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="product_id"
                    label="Product id"
                    value={filters.product_id}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="reference_name"
                    label="Reference name"
                    value={filters.reference_name}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="duration_in_days"
                    label="Duration in days"
                    value={filters.duration_in_days}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="price"
                    label="price"
                    value={filters.price}
                  />
                  <InputFilter
                    onChange={this.handleInputChange}
                    name="country"
                    label="country"
                    value={filters.country}
                  />
                </div>
              )}
            </div>
          )}
        </DropDown>
      </section>
    );
  }
}

export default withRouter(ProductFilters);
