import React, { useEffect, useState } from 'react';

import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import { EmailCreds } from '_types';
import styles from '../appPage.module.scss';

const INITIAL_STATE = {
  clientId: '',
  clientToken: '',
  iterableApiKey: '',
  iterableApiKeySandbox: '',
};

interface Props {
  cred: EmailCreds;
  handleSaveCred: Function;
  handleDelete: Function;
}

const EmailCredsForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const { cred, handleSaveCred, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      id,
      clientId,
      clientToken,
      iterableApiKey,
      iterableApiKeySandbox,
    } = cred;

    dispatch({
      id,
      clientId,
      clientToken,
      iterableApiKey,
      iterableApiKeySandbox,
    });
  }, [cred, dispatch]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;
    dispatch({ [name]: value });
  };

  const copyCredId = (): void => {
    if (cred.id) {
      copyToClipboard(cred.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No cred id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSaveCred({ ...formState, tempId: cred.tempId });
  };

  const deleteCred = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (cred.id) {
      handleDelete({ id: cred.id });
    } else {
      handleDelete({ tempId: cred.tempId });
    }
  };

  return (
    <form className="form_wrapper_left" onSubmit={handleSubmit}>
      {cred.id && (
        <label htmlFor="id" className={formStyles.row}>
          <div className="label">Credentials id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="id"
              className="input_w_icon"
              value={cred.id}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyCredId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}

      <label htmlFor="clientId" className={formStyles.row}>
        <div className="label_row">
          <div>Clinet id</div>
        </div>
        <input
          type="text"
          name="clientId"
          className="input"
          value={formState.clientId}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="clientToken" className={formStyles.row}>
        <div className="label_row">
          <div>Client Token</div>
        </div>
        <input
          type="text"
          name="clientToken"
          className="input"
          value={formState.clientToken}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="iterableApiKey" className={formStyles.row}>
        <div className="label_row">
          <div>Iterable api key</div>
        </div>
        <input
          type="text"
          name="iterableApiKey"
          className="input"
          value={formState.iterableApiKey}
          onChange={handleInputChange}
        />
      </label>

      <label htmlFor="iterableApiKeySandbox" className={formStyles.row}>
        <div className="label_row">
          <div>Iterable api key sandbox</div>
        </div>
        <input
          type="text"
          name="iterableApiKeySandbox"
          className="input"
          value={formState.iterableApiKeySandbox}
          onChange={handleInputChange}
        />
      </label>

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deleteCred}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default EmailCredsForm;
