/* eslint-disable */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import JSONEditor from 'jsoneditor/dist/jsoneditor-minimalist';
import 'jsoneditor/dist/jsoneditor.css';

function classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function defineProperties(target, props) {
  for (let i = 0; i < props.length; i++) {
    const descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ('value' in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function createClass(Constructor, protoProps, staticProps) {
  if (protoProps) defineProperties(Constructor.prototype, protoProps);
  if (staticProps) defineProperties(Constructor, staticProps);
  return Constructor;
}

function defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  const keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    let symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function objectSpread2(target) {
  for (let i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(
          target,
          key,
          Object.getOwnPropertyDescriptor(source, key)
        );
      });
    }
  }

  return target;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError('Super expression must either be null or a function');
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true,
    },
  });
  if (superClass) _setPrototypeOf(subClass, superClass);
}

function _getPrototypeOf(o) {
  _getPrototypeOf = Object.setPrototypeOf
    ? Object.getPrototypeOf
    : function _getPrototypeOf(o) {
        return o.__proto__ || Object.getPrototypeOf(o);
      };
  return _getPrototypeOf(o);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf =
    Object.setPrototypeOf ||
    function _setPrototypeOf(o, p) {
      o.__proto__ = p;
      return o;
    };

  return _setPrototypeOf(o, p);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  const target = {};
  const sourceKeys = Object.keys(source);
  let key;
  let i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  const target = _objectWithoutPropertiesLoose(source, excluded);

  let key;
  let i;

  if (Object.getOwnPropertySymbols) {
    const sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }

  return self;
}

function _possibleConstructorReturn(self, call) {
  if (call && (typeof call === 'object' || typeof call === 'function')) {
    return call;
  }

  return _assertThisInitialized(self);
}

/**
 * @typedef {{
 * tree: string,
 * view: string,
 * form: string,
 * code: string,
 * text: string,
 * allValues: Array<string>
 * }} TJsonEditorModes
 */

const modes = {
  tree: 'tree',
  view: 'view',
  form: 'form',
  code: 'code',
  text: 'text',
};
const values = Object.values(modes);
modes.allValues = values;
/**
 * @type {object}
 * @property {object} [value]
 * @property {string} [mode='tree'] - Set the editor mode.
 * @property {string} [name=undefined] - Initial field name for the root node
 * @property {object} [schema] - Validate the JSON object against a JSON schema.
 * @property {object} [schemaRefs] - Schemas that are referenced using
 * the $ref property
 * @property {Function} [onChange] - Set a callback function
 * triggered when json in the JSONEditor change.
 * Will only be triggered on changes made by the user.
 * @property {Function} [onError] - Set a callback function triggered when an error occurs.
 * Invoked with the error as first argument.
 * The callback is only invoked for errors triggered by a users action,
 * like switching from code mode to tree mode or clicking
 * the Format button whilst the editor doesn't contain valid JSON.
 * @property {Function} [onModeChange] - Set a callback function
 * triggered right after the mode is changed by the user.
 * @property {object} [ace] - Provide a version of the Ace editor.
 * Only applicable when mode is code
 * @property {object} [ajv] - Provide a instance of ajv,
 * the library used for JSON schema validation.
 * @property {string} [theme] - Set the Ace editor theme,
 * uses included 'ace/theme/jsoneditor' by default.
 * @property {boolean} [history=false] - Enables history,
 * adds a button Undo and Redo to the menu of the JSONEditor. Only applicable when
 * mode is 'tree' or 'form'
 * @property {boolean} [navigationBar=true] - Adds navigation bar to the menu
 * the navigation bar visualize the current position on the
 * tree structure as well as allows breadcrumbs navigation.
 * @property {boolean} [statusBar=true] - Adds status bar to the buttom of the editor
 * the status bar shows the cursor position and a count of the selected characters.
 * Only applicable when mode is 'code' or 'text'.
 * @property {boolean} [search=true] - Enables a search box in
 * the upper right corner of the JSONEditor.
 * @property {Array<string>} [allowedModes] - Create a box in the editor menu where
 * the user can switch between the specified modes.
 * @property {(string|PropTypes.elementType)} [tag='div'] - Html element, or react element to render
 * @property {object} [htmlElementProps] - html element custom props
 * @property {Function} [innerRef] - callback to get html element reference
 * @property {boolean} [sortObjectKeys=false] If true, object keys in 'tree',
 * 'view' or 'form' mode list be listed alphabetically instead by their insertion order..
 */

const Editor =
  /* #__PURE__ */
  (function (_Component) {
    _inherits(Editor, _Component);

    function Editor(props) {
      let _this;

      classCallCheck(this, Editor);

      _this = _possibleConstructorReturn(
        this,
        _getPrototypeOf(Editor).call(this, props)
      );
      _this.htmlElementRef = null;
      _this.jsonEditor = null;
      _this.handleChange = _this.handleChange.bind(
        _assertThisInitialized(_this)
      );
      _this.setRef = _this.setRef.bind(_assertThisInitialized(_this));
      _this.collapseAll = _this.collapseAll.bind(_assertThisInitialized(_this));
      _this.expandAll = _this.expandAll.bind(_assertThisInitialized(_this));
      _this.focus = _this.focus.bind(_assertThisInitialized(_this));
      return _this;
    }

    createClass(Editor, [
      {
        key: 'componentDidMount',
        value: function componentDidMount() {
          const _this$props = this.props;
          const { allowedModes } = _this$props;
          const { innerRef } = _this$props;
          const { htmlElementProps } = _this$props;
          const { tag } = _this$props;
          const { onChange } = _this$props;
          const rest = _objectWithoutProperties(_this$props, [
            'allowedModes',
            'innerRef',
            'htmlElementProps',
            'tag',
            'onChange',
          ]);

          this.createEditor(
            objectSpread2({}, rest, {
              modes: allowedModes,
            })
          );
        }, // eslint-disable-next-line react/sort-comp
      },
      {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(_ref) {
          const { allowedModes } = _ref;
          const { schema } = _ref;
          const { name } = _ref;
          const { theme } = _ref;
          const { schemaRefs } = _ref;
          const { innerRef } = _ref;
          const { htmlElementProps } = _ref;
          const { tag } = _ref;
          const { onChange } = _ref;
          const rest = _objectWithoutProperties(_ref, [
            'allowedModes',
            'schema',
            'name',
            'theme',
            'schemaRefs',
            'innerRef',
            'htmlElementProps',
            'tag',
            'onChange',
          ]);

          if (this.jsonEditor) {
            if (theme !== this.props.theme) {
              this.createEditor(
                objectSpread2({}, rest, {
                  theme,
                  modes: allowedModes,
                })
              );
            } else {
              if (
                schema !== this.props.schema ||
                schemaRefs !== this.props.schemaRefs
              ) {
                this.jsonEditor.setSchema(schema, schemaRefs);
              }

              if (name !== this.jsonEditor.getName()) {
                this.jsonEditor.setName(name);
              }
            }
          }
        },
      },
      {
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(_ref2) {
          const { htmlElementProps } = _ref2;
          return htmlElementProps !== this.props.htmlElementProps;
        },
      },
      {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
          if (this.jsonEditor) {
            this.jsonEditor.destroy();
            this.jsonEditor = null;
          }
        },
      },
      {
        key: 'setRef',
        value: function setRef(element) {
          this.htmlElementRef = element;

          if (this.props.innerRef) {
            this.props.innerRef(element);
          }
        },
      },
      {
        key: 'createEditor',
        value: function createEditor(_ref3) {
          const { value } = _ref3;
          const rest = _objectWithoutProperties(_ref3, ['value']);

          if (this.jsonEditor) {
            this.jsonEditor.destroy();
          }

          this.jsonEditor = new JSONEditor(
            this.htmlElementRef,
            objectSpread2(
              {
                onChange: this.handleChange,
              },
              rest
            )
          );
          this.jsonEditor.set(value);
        },
      },
      {
        key: 'handleChange',
        value: function handleChange() {
          if (this.props.onChange) {
            try {
              this.err = null;
              const text = this.jsonEditor.getText();

              if (text === '') {
                this.props.onChange(null);
              }

              const currentJson = this.jsonEditor.get();

              if (this.props.value !== currentJson) {
                this.props.onChange(currentJson);
              }
            } catch (err) {
              this.err = err;
            }
          }
        },
      },
      {
        key: 'collapseAll',
        value: function collapseAll() {
          if (this.jsonEditor) {
            this.jsonEditor.collapseAll();
          }
        },
      },
      {
        key: 'expandAll',
        value: function expandAll() {
          if (this.jsonEditor) {
            this.jsonEditor.expandAll();
          }
        },
      },
      {
        key: 'focus',
        value: function focus() {
          if (this.jsonEditor) {
            this.jsonEditor.focus();
          }
        },
      },
      {
        key: 'render',
        value: function render() {
          const _this$props2 = this.props;
          const { htmlElementProps } = _this$props2;
          const { tag } = _this$props2;
          return React.createElement(
            tag,
            objectSpread2({}, htmlElementProps, {
              ref: this.setRef,
            })
          );
        },
      },
    ]);

    return Editor;
  })(Component);
// Editor.propTypes = {
//   //  jsoneditor props
//   value: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.array,
//     PropTypes.string,
//     PropTypes.bool,
//     PropTypes.number,
//   ]),
//   mode: PropTypes.oneOf(values),
//   name: PropTypes.string,
//   schema: PropTypes.object,
//   schemaRefs: PropTypes.object,
//   sortObjectKeys: PropTypes.bool,
//   onChange: PropTypes.func,
//   onError: PropTypes.func,
//   onModeChange: PropTypes.func,
//   ace: PropTypes.object,
//   ajv: PropTypes.object,
//   theme: PropTypes.string,
//   history: PropTypes.bool,
//   navigationBar: PropTypes.bool,
//   statusBar: PropTypes.bool,
//   search: PropTypes.bool,
//   allowedModes: PropTypes.arrayOf(PropTypes.oneOf(values)),
//   //  custom props
//   tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
//   htmlElementProps: PropTypes.object,
//   innerRef: PropTypes.func,
// };
Editor.defaultProps = {
  tag: 'div',
  mode: modes.tree,
  history: false,
  search: true,
  navigationBar: true,
  statusBar: true,
  sortObjectKeys: false,
};
/**
 * @type TJsonEditorModes
 */

Editor.modes = modes;

export { Editor as JsonEditor };
// # sourceMappingURL=index.js.map
