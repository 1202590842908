import React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  path: string | string[];
  redirectTo?: string;
  authenticated: boolean;
  additionalProps?: object;
  component:
    | React.ComponentClass<any, any>
    | React.FunctionComponent<any>
    | React.ComponentClass;
};

const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const {
    redirectTo = '/auth/login',
    authenticated,
    component,
    additionalProps,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(routerProps) =>
        authenticated ? (
          React.createElement(component, { ...additionalProps, ...routerProps })
        ) : (
          <Redirect
            to={{ pathname: redirectTo, state: { from: routerProps.location } }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
