import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { defineCurrentBusinessAndApp } from 'modules/Session/sessionActions';
import { Business } from '_types';

// eslint-disable-next-line import/prefer-default-export
export const getUserBusinesses = (callback?: Function) => (
  dispatch: Dispatch,
  getState: Function
) => {
  const getBusinessesSuccess = (data: Business[]): void => {
    if (data && Array.isArray(data)) {
      dispatch({
        type: ACTION_TYPES.SET_USER_BUSINESSES,
        payload: data,
      });
      defineCurrentBusinessAndApp()(dispatch, getState);
      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  const getBusinessesFailure = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_USER_BUSINESSES,
      payload: [],
    });
    defineCurrentBusinessAndApp()(dispatch, getState);
  };

  dispatch(
    requestApi({
      url: '/v1/businesses',
      method: 'GET',
      onSuccess: getBusinessesSuccess,
      onFailure: getBusinessesFailure,
    })
  );
};
