import { Dispatch } from 'redux';
import { requestApi } from 'modules/Api/apiActions';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, Purchase } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

interface Response {
  subscriptions: Array<Purchase>;
  meta: {
    total: number;
    offset: number;
    perPage: number;
  };
}

export const getPurchases = (appId: string, params: URLSearchParams) => (
  dispatch: Dispatch
) => {
  const stringParams = params ? `&${params.toString()}` : '';

  const getPurchasesSuccess = (data: Response): void => {
    const purchases = data && data.subscriptions;
    const total = data && data.meta && data.meta.total;
    const perPage = data && data.meta && data.meta.perPage;

    if (purchases) {
      dispatch({
        type: ACTION_TYPES.SET_APP_PURCHASE,
        payload: {
          purchases,
          pages: { total, perPage },
        },
      });
    } else {
      dispatch({
        type: ACTION_TYPES.SET_APP_PURCHASE,
        payload: {
          purchases: [],
          pages: INITIAL_PAGE_STATE,
        },
      });
    }
  };

  const getPurchasesFailed = (): void => {
    dispatch({
      type: ACTION_TYPES.SET_APP_PURCHASE,
      payload: {
        purchases: [],
        pages: INITIAL_PAGE_STATE,
      },
    });
  };

  dispatch(
    requestApi({
      url: `/v1/subscriptions?app_id=${appId}${stringParams}`,
      method: 'GET',
      onSuccess: getPurchasesSuccess,
      onFailure: getPurchasesFailed,
    })
  );
};

export const resetPurchases = (): Action => ({
  type: ACTION_TYPES.SET_APP_PURCHASE,
  payload: {
    purchases: [],
    pages: INITIAL_PAGE_STATE,
  },
});
