import { connect } from 'react-redux';
import { RootState } from '_redux/store';
import { Screen, PageState } from '_types';
import { requestApi } from 'modules/Api/apiActions';
import { getScreens, resetScreens } from '../screensActions';
import EditScreen from './EditScreen';

interface State {
  appScreens: Screen[];
  pageData: PageState;
  currentAppId: string;
}

const mSTP = (state: RootState): State => ({
  appScreens: state.appScreens.screens,
  pageData: state.appScreens.pages,
  currentAppId: state && state.session && state.session.currentAppId,
});

const mDTP = { getScreens, resetScreens, requestApi };

export default connect(mSTP, mDTP)(EditScreen);
