import { combineReducers } from 'redux';
import ACTION_TYPES from '_redux/actionTypes';
import { Action, AppUser, PageState } from '_types';
import { INITIAL_PAGE_STATE } from '_config/constants';

const usersReducer = (
  state: Array<AppUser> = [],
  action: Action
): Array<AppUser> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_USERS:
      return action.payload && action.payload.users;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

const pageReducer = (
  pageState = INITIAL_PAGE_STATE,
  action: Action
): PageState => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_USERS:
      return action.payload && action.payload.pages;

    case ACTION_TYPES.SIGN_OUT:
      return INITIAL_PAGE_STATE;

    default:
      return pageState;
  }
};

export default combineReducers({
  users: usersReducer,
  pages: pageReducer,
});
