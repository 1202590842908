import { Auth, Hub } from 'aws-amplify';
import { saveStateToLS } from 'utils/localStorage';
import { login, signOut } from './loginActions';

export const getCurrentUser = async (): Promise<any> => {
  try {
    const user = Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    return undefined;
  }
};

export const getToken = async (): Promise<string | undefined> => {
  try {
    const session = await Auth.currentSession();
    if (!session) return undefined;

    const idToken = session.getIdToken();
    if (!idToken) return undefined;

    const jwt = idToken.getJwtToken();

    return jwt;
  } catch (error) {
    return undefined;
  }
};

export const amplifyToRedux = (store: any): void => {
  const checkUser = (): void => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          store.dispatch(login());
          saveStateToLS({ authenticated: true }, 'authenticated');
        }
      })
      .catch(() => {
        store.dispatch(signOut());
        saveStateToLS({ authenticated: false }, 'authenticated');
      });
  };

  checkUser();

  Hub.listen('auth', checkUser);
};
