import React from 'react';
import { Switch, Route, RouteComponentProps, NavLink } from 'react-router-dom';

import { CurrentApp } from '_redux/selectors/getCurrentApp';
import AppForm from './AppForm/AppFormContainer';
import TeamPage from '../Team/TeamPageContainer';
import PushCredentialsFrom from './PushCredentialsForm/PushCredentialsFormContainer';
import PaymentCredentialsList from './PaymentCredentialsForm/CredentialsListContainer';
import BillingPlansList from './BillingPlans/BillingPlansListContainer';
import EmailCredsList from './EmailCreds/EmailCredsContainer';
import FacebookCAPIList from './FacebookCAPI/FacebookCAPIContainer';
import RebillRuleList from './RebillRules/RebillRulesContainer';

interface Props extends RouteComponentProps {
  currentApp: CurrentApp;
}

const AppSettingsPage: React.FC<Props> = (props: Props) => {
  const { match, currentApp } = props;
  const path = match && match.path;
  const url = match && match.url;

  const appType = currentApp && currentApp.type;

  return (
    <div className="content">
      <h1 className="title">App Settings</h1>
      <div className="settings_nav">
        <NavLink
          to={url}
          exact
          className="nav_link"
          activeClassName="nav_link_active"
        >
          General
        </NavLink>
        {/* <NavLink
          to={`${url}/products`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Products
        </NavLink> */}
        <NavLink
          to={`${url}/push`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Push
        </NavLink>
        {/* <NavLink
          to={`${url}/localisation`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Localisation
        </NavLink> */}
        <NavLink
          to={`${url}/team`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Team
        </NavLink>
        <NavLink
          to={`${url}/facebook_capi`}
          className="nav_link"
          activeClassName="nav_link_active"
        >
          Facebook CAPI
        </NavLink>
        {appType === 'web' ? (
          <>
            <NavLink
              to={`${url}/credentials`}
              className="nav_link"
              activeClassName="nav_link_active"
            >
              Credentials
            </NavLink>
            <NavLink
              to={`${url}/billing_plans`}
              className="nav_link"
              activeClassName="nav_link_active"
            >
              Billing plans
            </NavLink>
            <NavLink
              to={`${url}/email_creds`}
              className="nav_link"
              activeClassName="nav_link_active"
            >
              Emails cred.
            </NavLink>
            <NavLink
              to={`${url}/rebill_rules`}
              className="nav_link"
              activeClassName="nav_link_active"
            >
              Rebill notification rules
            </NavLink>
          </>
        ) : (
          <>
            <span className="nav_link_disabled">Credentials</span>
            <span className="nav_link_disabled">Billing plans</span>
            <NavLink
              to={`${url}/email_creds`}
              className="nav_link"
              activeClassName="nav_link_active"
            >
              Emails cred.
            </NavLink>
            <span className="nav_link_disabled">Rebill rules</span>
          </>
        )}
      </div>

      <Switch>
        <Route path={path} exact component={AppForm} />
        <Route path={`${path}/team`} exact component={TeamPage} />
        <Route path={`${path}/push`} exact component={PushCredentialsFrom} />
        <Route
          path={`${path}/credentials`}
          exact
          component={PaymentCredentialsList}
        />
        <Route
          path={`${path}/billing_plans`}
          exact
          component={BillingPlansList}
        />
        <Route
          path={`${path}/facebook_capi`}
          exact
          component={FacebookCAPIList}
        />
        <Route path={`${path}/email_creds`} exact component={EmailCredsList} />
        <Route path={`${path}/rebill_rules`} exact component={RebillRuleList} />
      </Switch>
    </div>
  );
};

export default AppSettingsPage;
