import { Dispatch } from 'redux';
import camelCaseKeys from 'camelcase-keys';

import ACTION_TYPES from '_redux/actionTypes';
import { Action } from '_types';
import env from 'env';
import { getToken } from 'modules/login/authServise';
import { startLoading, finishLoading } from 'modules/Loader/loaderActions';

interface Store {
  dispatch: Dispatch;
}

const apiMiddleware = (store: Store) => (next: Function) => async (
  action: Action
) => {
  next(action);
  if (action.type !== ACTION_TYPES.API) return;

  const { dispatch } = store;

  const {
    url,
    body,
    onSuccess,
    onFailure,
    onFinish,
    download,
  } = action.payload;
  const endpoint = `${env.API_HOST}${url}`;

  const requestId = `${url}${new Date().getTime()}`;
  startLoading(requestId)(dispatch);

  const token: string =
    (await getToken().catch(() => {
      // console.log('get token error: ', err); //handle error please
    })) || '';

  if (!token) return;

  const FETCH_CONFIG = {
    headers: {
      Authorization: token,
    },
  };

  const config = { ...FETCH_CONFIG, ...action.payload };
  if (!action.payload.withFile) {
    config.headers['Content-Type'] = 'application/json';
  }

  if (body && !action.payload.withFile) config.body = JSON.stringify(body);

  fetch(endpoint, config)
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }

      if (download) {
        return res.blob();
      }

      return res.status === 204 ? {} : res.json();
    })
    .then((data: any) =>
      download ? data : camelCaseKeys(data, { deep: true })
    )
    .then((data: any) => {
      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
    })
    .catch((err: any) => {
      if (typeof onFailure === 'function') {
        onFailure(err);
      }
    })
    .finally(() => {
      finishLoading(requestId)(dispatch);
      if (typeof onFinish === 'function') {
        onFinish();
      }
    });
};

export default apiMiddleware;
