export const cognitoConfig = {
  mandatorySignIn: true,
  userPoolId: 'us-east-1_bKbXPqb8d',
  userPoolWebClientId: '7p4s2s0qo647amjsoktm9gna7g',
};

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? ''
    : 'https://app-api.panda-stage.boosters.company';

export const STRIPE_KEY = 'pk_test_uCf69WYwu0Tmm0KvJJz0RsED004KZMVCGZ';

export const PRICE_FREE = 'price_1HEz9wHr2FqFhmMd7BRi5MDY';
export const PRICE_ANALYZE = 'price_1HEz8QHr2FqFhmMdaRjdiSgh';
export const PRICE_GROW = 'price_1HEz9wHr2FqFhmMdjaUibKS2';

export const SUBSCRIPTION_HOST = 'https://api.panda-stage.boosters.company';

export const DEFAULT_REBILLS_FILTER = 'is_production=false';
