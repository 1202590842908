import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/svg/Arrow_down.svg';
import styles from './filter.module.scss';

interface Props extends RouteComponentProps {
  sortBy: string;
  label: string;
}

const SortTrigger: React.FC<Props> = (props: Props) => {
  const { sortBy, label, history } = props;
  const search = history && history.location && history.location.search;
  const query = new URLSearchParams(search);

  const prevSortby = query.get('sort_by');
  const prevSortType = query.get('sort_type');

  const handleSort = (): void => {
    let sortType = 'desc';
    if (prevSortby === sortBy && prevSortType === 'desc') {
      sortType = 'asc';
    } else if (prevSortby === sortBy && prevSortType === 'asc') {
      sortType = 'desc';
    }

    query.set('sort_by', sortBy);
    query.set('sort_type', sortType);

    history.push(`${history.location.pathname}?${query.toString()}`);
  };

  return (
    <div
      className={styles.sort_cell}
      data-name="product_id"
      onClick={handleSort}
      onKeyPress={handleSort}
      tabIndex={0}
      role="button"
    >
      {label}

      {prevSortby === sortBy && prevSortType === 'asc' && (
        <div className={styles.arrow_wrapper}>
          <Arrow className={styles.arrow_up} />
        </div>
      )}

      {prevSortby === sortBy && prevSortType === 'desc' && (
        <div className={styles.arrow_wrapper}>
          <Arrow className={styles.arrow_down} />
        </div>
      )}

      {prevSortby !== sortBy && (
        <div className={styles.arrow_wrapper_hidden}>
          <Arrow className={styles.arrow_down} />
        </div>
      )}
    </div>
  );
};

export default withRouter(SortTrigger);
