import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import formStyles from 'styles/forms.module.scss';
import { MAX_HTML_FILE_SIZE } from '_config/constants';
import UploadIcon from 'icons/Upload';
import { useFormState } from 'utils/customHooks';

interface Props extends RouteComponentProps {
  requestApi: Function;
  currentAppId: string;
}

const INITIAL_STATE = {
  name: '',
  screenHtml: '',
  fileName: '',
  type: '',
};

const ScreenForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE);
  const [error, setError] = useState<string>('');

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget;
    if (!files || files.length === 0) return;
    const file = files[0];

    if (file.type !== 'text/html') {
      setError('Not accepted file type');
      return;
    }

    if (file.size > MAX_HTML_FILE_SIZE) {
      setError('Max file size 500 Kb.');
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      const result = evt && evt.target && evt.target.result;
      if (result) {
        dispatch({
          screenHtml: result,
          fileName: file.name,
        });
      }
    };

    setError('');
  };

  const handleSuccess = (): void => {
    const { history } = props;
    history.push('/screens');
  };

  const handleError = (err?: string): void => {
    const errorText = typeof err === 'string' ? err : 'Something went wrong';
    setError(errorText);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    setError('');
    const { requestApi, currentAppId } = props;
    const { name, screenHtml, type } = formState;

    const allDataExist =
      !!currentAppId && name && name.trim().length && screenHtml;

    if (allDataExist) {
      const newScreen = {
        name,
        type,
        app_id: currentAppId,
        screen_html: screenHtml,
      };

      requestApi({
        url: `/v1/screens`,
        method: 'POST',
        body: newScreen,
        onSuccess: handleSuccess,
        onFailure: handleError,
      });
    }
  };

  return (
    <div className="content">
      <form className="form_wrapper_static" onSubmit={handleSubmit}>
        <div className={formStyles.text_center}>
          <h1 className="sub_title">Screen Creation</h1>
        </div>

        <label htmlFor="name" className={formStyles.row}>
          <div className="label">Screen name</div>
          <input
            type="text"
            name="name"
            className="input"
            value={formState.name}
            onChange={handleInputChange}
          />
        </label>

        <label htmlFor="type" className={formStyles.row}>
          <div className="label">Type</div>
          <input
            type="text"
            name="type"
            className="input"
            value={formState.type}
            onChange={handleInputChange}
            placeholder="sales, promo, survey, feedback"
          />
        </label>

        <div className={formStyles.row}>
          <div className="label">HTML file</div>
          <div className={formStyles.file_row}>
            <label htmlFor="logo" className={formStyles.file_btn}>
              <UploadIcon className={formStyles.upload_icon} />
              <div className={formStyles.upload_text}>Upload</div>
              <input
                type="file"
                name="logo"
                className={formStyles.file_input}
                onChange={handleFileChange}
              />
            </label>

            <div className={formStyles.file_name}>
              {formState.fileName ? formState.fileName : 'No file chosen'}
            </div>
          </div>
        </div>
        {error && <div className={formStyles.error_message}>{error}</div>}

        <div className={formStyles.submit_box}>
          <button type="submit" className="btn_grn_full_width">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ScreenForm;
