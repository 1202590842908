export const validateEmail = (email: string): boolean => {
  const re = /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  return re.test(email);
};

export const validatePassword = (password: string): boolean =>
  password.length > 5 && password.length < 73;

const MAX_LENGTH = 30;
export const validateName = (name: string): boolean =>
  !!name && name.trim().length > 0 && name.trim().length <= MAX_LENGTH;
