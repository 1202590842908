import ACTION_TYPES from '_redux/actionTypes';
import { Action, PageState, Product } from '_types';
import { combineReducers } from 'redux';
import { INITIAL_PAGE_STATE } from '../../_config/constants';

const productsReducer = (
  state: Array<Product> = [],
  action: Action
): Array<Product> => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_PRODUCTS:
      return action.payload && action.payload.products;

    case ACTION_TYPES.SIGN_OUT:
      return [];

    default:
      return state;
  }
};

const pageReducer = (
  pageState = INITIAL_PAGE_STATE,
  action: Action
): PageState => {
  switch (action.type) {
    case ACTION_TYPES.SET_APP_PRODUCTS:
      return action.payload;

    case ACTION_TYPES.SIGN_OUT:
      return INITIAL_PAGE_STATE;

    default:
      return pageState;
  }
};

export default combineReducers({
  products: productsReducer,
  pages: pageReducer,
});
