import React, { Component } from 'react';
import { BillingPlan, App, ModalNames } from '_types';
import { toCents, toDollars } from 'utils/priceConvert';
import BillingPlanForm from './BillingPlanForm';
import BillingPlanRow from './BillingPlanRow';
import styles from '../appPage.module.scss';

interface State {
  billingPlans: Array<BillingPlan | null>;
  error: string;
  planToEditId: string | number;
  isEditing: boolean;
  planToClone: BillingPlan | null;
}

interface Props {
  requestApi: Function;
  currentAppId: string;
  apps: App[];
  openModal: Function;
}

const INITIAL_STATE = {
  billingPlans: [],
  error: '',
  planToEditId: '',
  isEditing: false,
  planToClone: null,
};

const INITIAL_PLAN = {
  amount: 0,
  appId: '',
  billingPeriod: '',
  billingPeriodInDays: 0,
  firstPayment: 0,
  payPalId: '',
  paymentCredentialId: '',
  paymentMode: '',
  productId: '',
  secondPayment: 0,
  solidId: '',
  stripeId: '',
  subscriptionType: '',
  trialDescription: '',
  purchaseEmailId: '',
  currency: 'USD',
  hasTrial: false,
  durationTrialInDays: 0,
  durationTrial: '',
  purchaseValue: '',
};

export default class BillingPlansList extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidMount(): void {
    this.getPlans();
  }

  componentDidUpdate(prevProps: Props): void {
    const { currentAppId } = this.props;
    const { currentAppId: prevAppId } = prevProps;

    if (prevAppId !== currentAppId) {
      this.getPlans();
    }
  }

  getPlans = (): void => {
    const { requestApi, currentAppId } = this.props;

    requestApi({
      url: `/v1/payment/plans?app_id=${currentAppId}`,
      method: 'GET',
      onSuccess: this.getPlansSuccess,
      onFailure: this.getPlansFailed,
    });
  };

  getPlansSuccess = (data: BillingPlan[]): void => {
    const plansToState = data.map((plan) => {
      const {
        id,
        amount,
        appId,
        billingPeriod,
        billingPeriodInDays,
        firstPayment,
        payPalId,
        paymentCredentialId,
        paymentMode,
        productId,
        secondPayment,
        solidId,
        stripeId,
        subscriptionType,
        trialDescription,
        purchaseEmailId,
        currency,
        hasTrial,
        durationTrialInDays,
        durationTrial,
        purchaseValue,
      } = plan;

      const amountDollars = toDollars(amount);
      const firstPaymentDollars = toDollars(firstPayment);
      const secondPaymentDollars = toDollars(secondPayment);

      return {
        id,
        amount: amountDollars,
        appId,
        billingPeriod,
        billingPeriodInDays,
        firstPayment: firstPaymentDollars,
        payPalId,
        paymentCredentialId,
        paymentMode,
        productId,
        secondPayment: secondPaymentDollars,
        solidId,
        stripeId,
        subscriptionType,
        trialDescription,
        purchaseEmailId,
        currency,
        hasTrial,
        durationTrialInDays,
        durationTrial,
        purchaseValue,
      };
    });

    this.setState((prevState) => {
      if (prevState.planToClone) {
        return {
          ...prevState,
          billingPlans: [prevState.planToClone, ...plansToState],
          planToClone: null,
          planToEditId: prevState.planToClone.tempId || '',
        };
      }
      return { ...prevState, billingPlans: plansToState };
    });
  };

  getPlansFailed = (): void => {
    this.setState((prevState) => {
      if (prevState.planToClone) {
        return {
          ...prevState,
          billingPlans: [prevState.planToClone],
          planToClone: null,
          planToEditId: prevState.planToClone.tempId || '',
        };
      }

      return {
        ...prevState,
        billingPlans: INITIAL_STATE.billingPlans,
      };
    });
  };

  handleOpenPlanForm = (): void => {
    const tempId = new Date().getTime();
    this.setState((prevState) => ({
      billingPlans: [{ ...INITIAL_PLAN, tempId }, ...prevState.billingPlans],
      planToEditId: tempId,
      isEditing: true,
    }));
  };

  handleClonePlan = (billingPlan: BillingPlan): void => {
    const tempId = new Date().getTime();
    this.setState((prevState) => ({
      billingPlans: [{ ...billingPlan, tempId }, ...prevState.billingPlans],
      planToEditId: tempId,
      isEditing: true,
    }));
  };

  handleTransferPlan = (billingPlan: BillingPlan): void => {
    const { openModal } = this.props;
    const tempId = new Date().getTime();
    this.setState({
      planToClone: { ...billingPlan, tempId, purchaseEmailId: '' }, // do not copy email id while transfer
      planToEditId: tempId,
      isEditing: true,
    });

    openModal(ModalNames.APP_SELECTOR);
  };

  handleEditStart = (id: string | number): void => {
    this.setState({
      planToEditId: id,
      isEditing: true,
    });
  };

  handleEditStop = (): void => {
    this.setState((prevState) => ({
      billingPlans: prevState.billingPlans.filter((plan) => !!plan?.id),
      isEditing: false,
    }));
  };

  savePlanSuccess = (newPlan: BillingPlan): void => {
    this.setState((prevState) => {
      const newPlans = prevState.billingPlans.map((plan) => {
        const match =
          (plan && plan.id && plan.id === newPlan.id) ||
          (plan && plan.tempId && plan.tempId === newPlan.tempId);
        if (match) {
          const paymentMode =
            newPlan &&
            newPlan.paymentMode &&
            typeof newPlan.paymentMode !== 'string'
              ? newPlan.paymentMode.value
              : '';

          const purchaseValue =
            newPlan &&
            newPlan.purchaseValue &&
            JSON.stringify(newPlan.purchaseValue);

          return { ...newPlan, paymentMode, purchaseValue };
        }

        return plan;
      });

      return { billingPlans: newPlans, isEditing: false };
    });
  };

  handleError = (err: Record<string, any>): void => {
    const error = (err && err.message) || 'something went wrong';
    this.setState({ error });
  };

  handleSavePlan = (plan: BillingPlan): void => {
    const { currentAppId, requestApi } = this.props;
    const {
      id,
      amount,
      billingPeriod,
      billingPeriodInDays,
      firstPayment,
      payPalId,
      paymentCredentialId,
      paymentMode,
      productId,
      secondPayment,
      solidId,
      stripeId,
      subscriptionType,
      trialDescription,
      purchaseEmailId,
      currency,
      hasTrial,
      durationTrialInDays,
      durationTrial,
      purchaseValue,
    } = plan;

    const amountCents = toCents(amount);
    const firstPaymentCents = toCents(firstPayment);
    const secondPaymentCents = toCents(secondPayment);
    const durationTrialNum = durationTrialInDays
      ? parseInt(durationTrialInDays.toString(), 10)
      : 0;
    const billingPeriodInDaysNum = billingPeriodInDays
      ? parseInt(billingPeriodInDays.toString(), 10)
      : 0;
    const selectedMode =
      (paymentMode as {
        value: string;
        label: string;
      }) &&
      (paymentMode as {
        value: string;
        label: string;
      }).value;

    const body = {
      amount: amountCents,
      app_id: currentAppId,
      billing_period: billingPeriod,
      billing_period_in_days: billingPeriodInDaysNum,
      first_payment: firstPaymentCents,
      pay_pal_id: payPalId,
      payment_credential_id: paymentCredentialId,
      payment_mode: selectedMode,
      product_id: productId,
      second_payment: secondPaymentCents,
      solid_id: solidId,
      stripe_id: stripeId,
      subscription_type: subscriptionType,
      trial_description: trialDescription,
      purchase_email_id: parseInt(purchaseEmailId as string, 10),
      currency,
      has_trial: hasTrial,
      duration_trial_in_days: durationTrialNum,
      duration_trial: durationTrial,
      purchase_value: JSON.stringify(purchaseValue),
    };

    if (id) {
      requestApi({
        url: `/v1/payment/plans/${id}`,
        method: 'PUT',
        body,
        onSuccess: () => this.savePlanSuccess(plan),
        onFailure: this.handleError,
      });
    } else {
      requestApi({
        url: `/v1/payment/plans`,
        method: 'POST',
        body,
        onSuccess: (data: Record<string, any>) => {
          const createdId = data && data.id;
          if (createdId) {
            this.savePlanSuccess({ ...plan, id: createdId });
          }
        },
        onFailure: this.handleError,
      });
    }
  };

  deletePlanSuccess = (id: string): void => {
    this.setState((prevState) => ({
      billingPlans: prevState.billingPlans.filter(
        (plan) => plan && plan.id !== id && plan.tempId !== id
      ),
    }));
  };

  handleDeletePlan = ({
    id,
    tempId,
  }: {
    id?: string;
    tempId: string;
  }): void => {
    const { requestApi } = this.props;
    if (id) {
      requestApi({
        url: `/v1/payment/plans/${id}`,
        method: 'DELETE',
        onSuccess: () => this.deletePlanSuccess(id),
        onFailure: this.handleError,
      });
    } else if (tempId) {
      this.deletePlanSuccess(tempId);
    }
  };

  render(): JSX.Element {
    const { billingPlans, error, planToEditId, isEditing } = this.state;

    const planForEdit =
      billingPlans &&
      billingPlans.find((plan: BillingPlan) => {
        const match =
          (plan && plan.id && plan.id === planToEditId) ||
          (plan && plan.tempId && plan.tempId === planToEditId);

        return match;
      });

    return (
      <div>
        <div className="title_row">
          <button
            type="button"
            className="btn_create"
            onClick={this.handleOpenPlanForm}
          >
            Add plan
          </button>
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className="table_scroll_wrapper">
          <table className="real_table_wrapper">
            <thead className="table_head">
              <tr className="table_head">
                <th className="sticky_col">Edit</th>
                <th className="bold">Plan id</th>
                <th className="bold">Product Id</th>
                <th className="bold">Amount</th>
                <th className="bold">Currency</th>
                <th className="bold">Payment Mode</th>
                <th className="bold">Solid Id</th>
                <th className="bold">Billing Period</th>
                <th className="bold">Billing Period in days</th>
                <th className="bold">First Payment</th>
                <th className="bold">Second Payment</th>
                <th className="bold">Payment Credential Id</th>
                <th className="bold">Paypal Id</th>
                <th className="bold">Stripe Id</th>
                <th className="bold">Subscription Type</th>
                <th className="bold">Trial Description</th>
                <th className="bold">Purchase Email Id</th>
                <th className="bold">Has Trial</th>
                <th className="bold">Duration Trial In Days</th>
                <th className="bold">Duration Trial</th>
                <th className="bold">Purchase Value</th>
              </tr>
            </thead>
            <tbody className="table_content">
              {billingPlans &&
              Array.isArray(billingPlans) &&
              billingPlans.length > 0 ? (
                billingPlans.map((plan: BillingPlan) => {
                  return (
                    <BillingPlanRow
                      key={plan.id || plan.tempId}
                      billingPlan={plan}
                      handleStartEdit={this.handleEditStart}
                      handleClonePlan={this.handleClonePlan}
                      handleTransferPlan={this.handleTransferPlan}
                    />
                  );
                })
              ) : (
                <tr>
                  <td>Billing plans will be here</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isEditing && planForEdit && (
          <div className={styles.form_modal_wide}>
            <div className={styles.close_btn_row}>
              <button
                type="button"
                className="close_btn"
                onClick={this.handleEditStop}
              >
                <div className="close_icon" />
              </button>
            </div>
            <BillingPlanForm
              plan={planForEdit}
              handleSavePlan={this.handleSavePlan}
              handleDelete={this.handleDeletePlan}
            />
          </div>
        )}
      </div>
    );
  }
}
