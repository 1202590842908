import React from 'react';
import { Product } from '_types';

interface Props {
  product: Product;
  handleStartEdit: Function;
}

const ProductsRow: React.FC<Props> = (props: Props) => {
  const { product, handleStartEdit } = props;
  if (!product) return null;

  const {
    id,
    appSubscriptionId,
    referenceName,
    revenue,
    duration,
    durationInDays,
    hasTrial,
    hasOffer,
    offerRevenue,
    offerDuration,
    offerDurationInDays,
    consumable,
    trialDuration,
    trialDurationInDays,
    productId,
    priceId,
    country,
    price,
    outdatedAt,
  } = product;

  const handleClick = (): void => {
    handleStartEdit(id);
  };

  return (
    <tr>
      <td>
        <button className="btn_grn_small" type="button" onClick={handleClick}>
          Edit
        </button>
      </td>
      <td>{appSubscriptionId}</td>
      <td>{productId}</td>
      <td>{referenceName}</td>
      <td>{priceId}</td>
      <td>{price}</td>
      <td>{country}</td>
      <td>{outdatedAt}</td>
      <td>{duration}</td>
      <td>{durationInDays}</td>
      <td>{hasTrial ? 'yes' : 'no'}</td>
      <td>{trialDuration}</td>
      <td>{trialDurationInDays}</td>
      <td>{hasOffer ? 'yes' : 'no'}</td>
      <td>{offerRevenue}</td>
      <td>{offerDuration}</td>
      <td>{offerDurationInDays}</td>
      <td>{revenue}</td>
      <td>{consumable ? 'yes' : 'no'}</td>
    </tr>
  );
};

export default ProductsRow;
