import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  PurchaseIOS,
  PageState,
  PurchaseAndroid,
  PurchaseWeb,
  Purchase,
} from '_types';
import Pagination from 'modules/Pagination/Pagination';
import SortTrigger from 'modules/Filter/SortTrigger';
import PurchaseRowIOS from './PurchaseRowIOS';
import PurchRowAndroid from './PurchRowAndroid';
import PurchaseRowWeb from './PurchaseRowWeb';
import PurchaseFilters from './FilterContainer';

interface Props extends RouteComponentProps {
  getPurchases: Function;
  resetPurchases: Function;
  purchases: Array<Purchase>;
  pageData: PageState;
  currentAppId: string;
  appType: string;
}

class Purchases extends React.Component<any, Props> {
  componentDidMount(): void {
    const { history, currentAppId, getPurchases } = this.props;
    const search = history && history.location && history.location.search;

    const queryParams = new URLSearchParams(search);

    if (!queryParams.has('sort_by')) {
      queryParams.set('sort_by', 'purchase_date');
      queryParams.set('sort_type', 'desc');

      history.push(`${history.location.pathname}?${queryParams.toString()}`);
    } else if (currentAppId) {
      getPurchases(currentAppId, queryParams);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { history, getPurchases, currentAppId, resetPurchases } = this.props;
    const search = history && history.location && history.location.search;

    const prevSearch = prevProps.location && prevProps.location.search;
    const prevAppId = prevProps.currentAppId;

    const shouldRequest =
      (prevSearch !== search || prevAppId !== currentAppId) && currentAppId;

    if (shouldRequest) {
      const queryParams = new URLSearchParams(search);

      if (prevAppId !== currentAppId) {
        resetPurchases();
      }
      getPurchases(currentAppId, queryParams);
    }
  }

  componentWillUnmount(): void {
    const { resetPurchases } = this.props;

    resetPurchases();
  }

  render(): JSX.Element {
    const { purchases, pageData, appType } = this.props;

    const { total, perPage } = pageData;

    const isAndroid = appType === 'android';
    const isIOS = appType === 'ios';
    const isWeb = appType === 'web';

    return (
      <div>
        <PurchaseFilters appType={appType} />
        {isAndroid && (
          <div className="table_scroll_wrapper">
            <table className="real_table_wrapper">
              <thead className="table_head">
                <tr className="table_head">
                  <th>
                    <SortTrigger label="Status Id" sortBy="status_id" />
                  </th>
                  <th>
                    <SortTrigger label="Trial" sortBy="is_trial_period" />
                  </th>
                  <th>
                    <SortTrigger label="User Id" sortBy="user_id" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Custom User Id"
                      sortBy="custom_user_id"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Appsflyer Id" sortBy="appsflyer_id" />
                  </th>
                  <th>
                    <SortTrigger label="Payment State" sortBy="payment_state" />
                  </th>
                  <th>
                    <SortTrigger label="Price" sortBy="price" />
                  </th>
                  <th>
                    <SortTrigger label="Product Id" sortBy="product_id" />
                  </th>
                  <th>
                    <SortTrigger label="Index" sortBy="index" />
                  </th>
                  <th>
                    <SortTrigger label="Order Id" sortBy="order_id" />
                  </th>
                  <th>
                    <SortTrigger label="Purchase Date" sortBy="purchase_date" />
                  </th>
                  <th>
                    <SortTrigger label="Expiry Date" sortBy="expires_date" />
                  </th>
                  <th>
                    <SortTrigger label="Created At" sortBy="created_at" />
                  </th>
                  <th>
                    <SortTrigger label="Sandbox" sortBy="test" />
                  </th>
                  <th>
                    <SortTrigger label="Quantity" sortBy="quantity" />
                  </th>
                  <th>
                    <SortTrigger label="Adv id" sortBy="adv_id" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Cancellation Date"
                      sortBy="user_cancellation_time"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Cancel Reason" sortBy="cancel_reason" />
                  </th>
                </tr>
              </thead>

              <tbody className="table_content">
                {Array.isArray(purchases) && purchases.length > 0 ? (
                  purchases.map((purchase: PurchaseAndroid) => {
                    return (
                      <PurchRowAndroid
                        key={`${purchase.statusId}_${purchase.index}_${purchase.purchaseDate}_${purchase.createdAt}`}
                        purchase={purchase}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td>Purchases will be here</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {isIOS && (
          <div className="table_scroll_wrapper">
            <table className="real_table_wrapper">
              <thead className="table_head">
                <tr className="table_head">
                  <th>
                    <SortTrigger label="Receipt Id" sortBy="receipt_id" />
                  </th>
                  <th>
                    <SortTrigger label="Trial" sortBy="is_trial_period" />
                  </th>
                  <th>
                    <SortTrigger label="User Id" sortBy="user_id" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Custom User Id"
                      sortBy="custom_user_id"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Appsflyer Id" sortBy="appsflyer_id" />
                  </th>
                  <th>
                    <SortTrigger label="Product Id" sortBy="product_id" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Transaction Id"
                      sortBy="transaction_id"
                    />
                  </th>
                  <th>
                    <SortTrigger
                      label="Original Transaction Id"
                      sortBy="original_transaction_id"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Purchase Date" sortBy="purchase_date" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Original Purchase Date"
                      sortBy="original_purchase_date"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Expiry Date" sortBy="expires_date" />
                  </th>
                  <th>
                    <SortTrigger label="Created At" sortBy="created_at" />
                  </th>
                  <th>
                    <SortTrigger label="Sandbox" sortBy="sandbox" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Intro offer period"
                      sortBy="is_in_intro_offer_period"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Upgraded" sortBy="is_upgraded" />
                  </th>
                  <th>
                    <SortTrigger label="Quantity" sortBy="quantity" />
                  </th>
                  <th>
                    <SortTrigger label="Refund Date" sortBy="refund_date" />
                  </th>
                  <th>
                    <SortTrigger label="Refund Reason" sortBy="refund_reason" />
                  </th>
                </tr>
              </thead>
              <tbody className="table_content">
                {Array.isArray(purchases) && purchases.length > 0 ? (
                  purchases.map((purchase: PurchaseIOS) => {
                    return (
                      <PurchaseRowIOS
                        key={`${purchase.transactionId}_${purchase.receiptId}_${purchase.purchaseDate}_${purchase.createdAt}`}
                        purchase={purchase}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td>Purchases will be here</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {isWeb && (
          <div className="table_scroll_wrapper">
            <table className="real_table_wrapper">
              <thead className="table_head">
                <tr className="table_head">
                  <th>
                    <SortTrigger label="Id" sortBy="id" />
                  </th>
                  <th>
                    <SortTrigger label="User Id" sortBy="user_id" />
                  </th>
                  <th>
                    <SortTrigger label="Purchase Date" sortBy="purchase_date" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Transaction date"
                      sortBy="transaction_purchase_date"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Payment Gate" sortBy="payment_gate" />
                  </th>
                  <th>
                    <SortTrigger label="Price" sortBy="price" />
                  </th>
                  <th>
                    <SortTrigger label="Order Id" sortBy="order_id" />
                  </th>
                  <th>
                    <SortTrigger label="Order Status" sortBy="order_status" />
                  </th>
                  <th>
                    <SortTrigger label="Status" sortBy="status" />
                  </th>
                  <th>
                    <SortTrigger label="Is trial" sortBy="is_trial_period" />
                  </th>
                  <th>
                    <SortTrigger
                      label="Transaction Email"
                      sortBy="transaction_email"
                    />
                  </th>
                  <th>
                    <SortTrigger label="Email" sortBy="email" />
                  </th>
                  <th>
                    <SortTrigger label="Cancel code" sortBy="cancel_code" />
                  </th>
                </tr>
              </thead>
              <tbody className="table_content">
                {Array.isArray(purchases) && purchases.length > 0 ? (
                  purchases.map((purchase: PurchaseWeb) => {
                    return (
                      <PurchaseRowWeb key={purchase.id} purchase={purchase} />
                    );
                  })
                ) : (
                  <tr>
                    <td>Purchases will be here</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination total={total} perPage={perPage} />
      </div>
    );
  }
}

export default Purchases;
